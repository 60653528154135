import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed737f4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headline" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_btn, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCard(null)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Neue Rolle hinzufügen")
          ]),
          _: 1
        }),
        (!_ctx.cardVisible)
          ? (_openBlock(), _createBlock(_component_v_data_table, {
              key: 0,
              headers: _ctx.headers,
              items: _ctx.roles,
              class: "elevation-1"
            }, {
              [`item.actions`]: _withCtx(({ item }) => [
                _createVNode(_component_v_icon, {
                  small: "",
                  class: "mr-2",
                  onClick: ($event: any) => (_ctx.openCard(item ?? null))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-pencil")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1032, ["headers", "items"]))
          : _createCommentVNode("", true),
        (_ctx.cardVisible)
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 1,
              class: "mt-5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.cardTitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tabs, {
                      modelValue: _ctx.activeTab,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTab) = $event)),
                      "background-color": "primary",
                      dark: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tab, { value: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode("Informationen")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_tab, { value: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode("Admin Rechte")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_tab, { value: 2 }, {
                          default: _withCtx(() => [
                            _createTextVNode("Allgemeine Rechte")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_v_window, {
                      modelValue: _ctx.activeTab,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.activeTab) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_window_item, { value: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          label: "Role Name",
                                          modelValue: _ctx.editedRole.name,
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editedRole.name) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          label: "Description",
                                          modelValue: _ctx.editedRole.description,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedRole.description) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          label: "Power",
                                          modelValue: _ctx.editedRole.power,
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editedRole.power) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          label: "Sort Order",
                                          modelValue: _ctx.editedRole.sort_order,
                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editedRole.sort_order) = $event))
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_window_item, { value: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_data_table, {
                                  "group-by": _ctx.groupBy,
                                  headers: _ctx.permissionsHeaders,
                                  items: _ctx.adminPermissions,
                                  class: "elevation-1",
                                  "item-value": "id",
                                  "items-per-page": -1
                                }, {
                                  [`group-header`]: _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
                                    _createElementVNode("tr", null, [
                                      _createElementVNode("td", {
                                        colspan: columns.length
                                      }, [
                                        _createVNode(_component_v_btn, {
                                          icon: isGroupOpen(item) ? '$expand' : '$next',
                                          size: "small",
                                          variant: "text",
                                          onClick: ($event: any) => (toggleGroup(item))
                                        }, null, 8, ["icon", "onClick"]),
                                        _createTextVNode(" " + _toDisplayString(item.value), 1)
                                      ], 8, _hoisted_2)
                                    ])
                                  ]),
                                  [`item.checkbox`]: _withCtx(({ item }) => [
                                    _createVNode(_component_v_checkbox, {
                                      modelValue: _ctx.editedRole.permissions,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editedRole.permissions) = $event)),
                                      label: item.name_alias,
                                      value: item,
                                      multiple: true
                                    }, null, 8, ["modelValue", "label", "value"])
                                  ]),
                                  [`item.description`]: _withCtx(({ item }) => [
                                    _createElementVNode("span", null, _toDisplayString(item.description), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["group-by", "headers", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_window_item, { value: 2 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_container, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_data_table, {
                                  "group-by": _ctx.groupBy,
                                  headers: _ctx.permissionsHeaders,
                                  items: _ctx.generalPermissions,
                                  class: "elevation-1",
                                  "item-value": "id",
                                  "items-per-page": -1
                                }, {
                                  [`group-header`]: _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
                                    _createElementVNode("tr", null, [
                                      _createElementVNode("td", {
                                        colspan: columns.length
                                      }, [
                                        _createVNode(_component_v_btn, {
                                          icon: isGroupOpen(item) ? '$expand' : '$next',
                                          size: "small",
                                          variant: "text",
                                          onClick: ($event: any) => (toggleGroup(item))
                                        }, null, 8, ["icon", "onClick"]),
                                        _createTextVNode(" " + _toDisplayString(item.value), 1)
                                      ], 8, _hoisted_3)
                                    ])
                                  ]),
                                  [`item.checkbox`]: _withCtx(({ item }) => [
                                    _createVNode(_component_v_checkbox, {
                                      modelValue: _ctx.editedRole.permissions,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedRole.permissions) = $event)),
                                      label: item.name_alias,
                                      value: item,
                                      multiple: true
                                    }, null, 8, ["modelValue", "label", "value"])
                                  ]),
                                  [`item.description`]: _withCtx(({ item }) => [
                                    _createElementVNode("span", null, _toDisplayString(item.description), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["group-by", "headers", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.close
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Cancel")
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.save
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Save")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}