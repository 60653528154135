<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-text-field
					v-model="search"
					label="Suche"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="auto">
				<v-btn @click="openFolderDialog" v-if="canEdit">Ordner erstellen</v-btn>
			</v-col>
			<v-col cols="3">
				<v-file-input
					v-model="uploadedFiles"
					label="Dateien auswählen"
					:accept="allowedFileTypes"
					v-if="canEdit"
				></v-file-input>
			</v-col>
			<v-col cols="auto">
				<v-btn @click="uploadFiles" :disabled="!uploadedFiles.length" v-if="canEdit"
					>Hochladen</v-btn
				>
			</v-col>
		</v-row>

		<v-divider class="my-4"></v-divider>

		<!-- Aktueller Ordner -->
		<v-row>
			<v-col cols="12">
				<v-alert border="left" icon="mdi-folder-outline" prominent>
					{{ currentFolderName || "Root-Verzeichnis" }}
				</v-alert>
			</v-col>
		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-row>
			<!-- Back Button -->
			<v-col cols="12" v-if="currentFolderId">
				<v-btn @click="goBack">Zurück</v-btn>
			</v-col>

			<!-- Folders -->
			<template v-if="filteredFolders.length">
				<template v-for="folder in filteredFolders" :key="folder.id">
					<v-col cols="12" sm="2" md="2" lg="2">
						<v-card
							class="folder-card"
							@dblclick="navigateToFolder(folder.id)"
						>
							<v-card-text
								class="d-flex flex-column align-center justify-center"
							>
								<v-icon size="80" class="folder-icon">mdi-folder</v-icon>
								<div class="truncate">{{ folder.name }}</div>
							</v-card-text>
						</v-card>
					</v-col>
				</template>
			</template>

			<!-- Files -->
            <template v-for="file in filteredFiles" :key="file.id">
    <v-col cols="12" sm="2" md="2" lg="2">
        <v-card class="file-card">
            <div class="file-card-content">
                <v-img
                    v-if="isImage(file.path)"
                    :src="file.path"
                    class="file-image"
                ></v-img>
                <v-icon v-else size="80" class="file-icon">mdi-file</v-icon>
            </div>
            <v-card-title class="truncate">{{ file.name }}</v-card-title>
            <v-card-subtitle>Typ: {{ getFileType(file.name) }}</v-card-subtitle>
            <v-card-subtitle>{{ formatFileSize(file.size) }}</v-card-subtitle>
            <v-card-actions>
                <v-btn icon @click="downloadFile(file.path)">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn icon @click="copyLink(file.path)">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon color="error" @click="deleteFile(file.id)" v-if="canDelete">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

		</v-row>

		<v-dialog v-model="showFolderDialog" max-width="500">
			<v-card>
				<v-card-title>Neuen Ordner erstellen</v-card-title>
				<v-card-text>
					<v-text-field
						label="Ordnername"
						v-model="newFolderName"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showFolderDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn @click="createFolder">Erstellen</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import api from "@/api";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "FileManager",
	setup() {
		const search = ref("");
		const folders = ref([]);
		const files = ref([]);
		const uploadedFiles = ref([]);
		const showFolderDialog = ref(false);
		const newFolderName = ref("");
		const currentFolderId = ref(null);
		const folderHistory = ref([]);

		const allowedFileTypes = ref(
			"image/*,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/vnd.adobe.photoshop"
		);

		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const currentFolderName = ref<string | null>(null);

		const fetchFilesAndFolders = async () => {
			try {
				const response = await api.post(
					"filemanager/?action=getFilesAndFolders",
					{
						folderId: currentFolderId.value,
					}
				);
				folders.value = response.data.folders;
				files.value = response.data.files;
				currentFolderName.value =
					response.data.currentFolderName || "Root";
			} catch (error) {
				console.error("Fehler beim Abrufen der Daten:", error);
			}
		};

		const filteredFiles = computed(() => {
			return files.value.filter((file) =>
				file.name.toLowerCase().includes(search.value.toLowerCase())
			);
		});

		const filteredFolders = computed(() => {
			return folders.value.filter((folder) =>
				folder.name.toLowerCase().includes(search.value.toLowerCase())
			);
		});

		const navigateToFolder = (folderId) => {
			folderHistory.value.push(currentFolderId.value);
			currentFolderId.value = folderId;
			fetchFilesAndFolders();
		};

		const goBack = () => {
			currentFolderId.value = folderHistory.value.pop();
			fetchFilesAndFolders();
		};

		const uploadFiles = async () => {
			if (!uploadedFiles.value.length) return;
			const formData = new FormData();
			uploadedFiles.value.forEach((file) => {
				formData.append("files[]", file);
			});
			formData.append("folderId", currentFolderId.value);

			try {
				await api.post("filemanager/?action=uploadFiles", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				});
				uploadedFiles.value = [];
				fetchFilesAndFolders();
			} catch (error) {
				console.error("Fehler beim Hochladen der Dateien:", error);
			}
		};

		const createFolder = async () => {
			try {
				await api.post("filemanager/?action=createFolder", {
					name: newFolderName.value,
					parentId: currentFolderId.value,
				});
				showFolderDialog.value = false;
				newFolderName.value = "";
				fetchFilesAndFolders();
			} catch (error) {
				console.error("Fehler beim Erstellen des Ordners:", error);
			}
		};

		const downloadFile = (path) => {
			window.open(path, "_blank");
		};

		const copyLink = (path) => {
			navigator.clipboard.writeText(path).then(() => {
				alert("Link wurde kopiert!");
			});
		};

		const deleteFile = async (id) => {
			try {
				await api.post("filemanager/?action=deleteFile", { id });
				fetchFilesAndFolders();
			} catch (error) {
				console.error("Fehler beim Löschen der Datei:", error);
			}
		};

		const formatFileSize = (size) => {
			if (size < 1024) return `${size} B`;
			if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
			if (size < 1024 * 1024 * 1024)
				return `${(size / (1024 * 1024)).toFixed(1)} MB`;
			return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
		};

		const isImage = (path) => {
			return /\.(jpg|jpeg|png|gif|bmp)$/i.test(path);
		};

		const openFolderDialog = () => {
			showFolderDialog.value = true;
		};

		const getFileType = (fileName: string): string => {
			const extension =
				fileName.split(".").pop()?.toLowerCase() || "Unbekannt";
			const typeMap: { [key: string]: string } = {
				pdf: "PDF",
				jpg: "JPG",
				jpeg: "JPEG",
				png: "PNG",
				gif: "GIF",
				bmp: "BMP",
				doc: "Word-Dokument",
				docx: "Word-Dokument",
				xls: "Excel-Dokument",
				xlsx: "Excel-Dokument",
				txt: "Textdatei",
				zip: "ZIP-Archiv",
				rar: "RAR-Archiv",
				psd: "Photoshop",
			};
			return typeMap[extension] || extension.toUpperCase();
		};

		onMounted(fetchFilesAndFolders);

		return {
			search,
			folders,
			files,
			uploadedFiles,
			showFolderDialog,
			newFolderName,
			createFolder,
			uploadFiles,
			downloadFile,
			copyLink,
			deleteFile,
			navigateToFolder,
			filteredFiles,
			filteredFolders,
			formatFileSize,
			isImage,
			allowedFileTypes,
			openFolderDialog,
			goBack,
			currentFolderId,
			getFileType,
			currentFolderName,
			canEdit,
			canDelete
		};
	},
});
</script>

<style scoped>
.v-card {
	cursor: pointer;
	border-radius: 12px;
}
.folder-card {
	transition: background-color 0.3s;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.folder-card:hover {
	background-color: #282828;
}
.file-card {
	border-radius: 12px;
}
.folder-icon {
	color: var(--primary);
}
.file-card {
    border-radius: 12px;
    height: 300px; /* Einheitliche Höhe für alle Karten */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.file-card-content {
    height: 150px; /* Einheitliche Höhe für den Inhalt */
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-image {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}

.file-icon {
    color: var(--primary);
}
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
</style>