import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2eb4482"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_VBtn = _resolveComponent("VBtn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_AuthorityAddCompanyFile = _resolveComponent("AuthorityAddCompanyFile")!
  const _component_AuthorityEditCompanyFile = _resolveComponent("AuthorityEditCompanyFile")!
  const _component_AuthorityViewCompanyFile = _resolveComponent("AuthorityViewCompanyFile")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.isSite('fire').value)
        ? (_openBlock(), _createBlock(_component_v_text_field, {
            key: 0,
            "append-inner-icon": "mdi-content-save",
            density: "compact",
            label: "Feuerlöschernummer",
            variant: "solo",
            "hide-details": "",
            modelValue: _ctx.extinguisherNr,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.extinguisherNr) = $event)),
            style: {"width":"300px"},
            class: "float-left",
            "onClick:appendInner": _ctx.updateFireExtinguisherNr
          }, null, 8, ["modelValue", "onClick:appendInner"]))
        : _createCommentVNode("", true),
      (!_ctx.newCompanyDialog && !_ctx.editCompanyDialog && !_ctx.viewCompanyDialog && _ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 1,
            onClick: _ctx.openNewCompanyDialog,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Neue Firma")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.newCompanyDialog && !_ctx.editCompanyDialog && !_ctx.viewCompanyDialog)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 2,
            headers: _ctx.companyHeaders,
            items: _ctx.filteredCompanies,
            class: "elevation-1 mt-12",
            search: _ctx.search,
            "items-per-page": 100,
            "group-by": _ctx.groupBy
          }, _createSlots({
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Firmenverwaltung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_select, {
                    class: "mx-4 mt-3",
                    modelValue: _ctx.filters.expiryFilter,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.expiryFilter) = $event)),
                    items: _ctx.expiryFilterOptions,
                    label: "Filter nach Ablaufdatum",
                    variant: "underlined",
                    clearable: ""
                  }, null, 8, ["modelValue", "items"]),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openEditCompanyDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDeleteCompanyDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.viewCompanyDetails(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-eye")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            [`item.last_fire_protection_inspection`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(item.last_fire_protection_inspection)), 1)
            ]),
            [`item.fire_protection_inspection_valid_until`]: _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(item.fire_protection_inspection_valid_until)), 1)
            ]),
            _: 2
          }, [
            (!_ctx.search)
              ? {
                  name: "group-header",
                  fn: _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", {
                        colspan: columns.length
                      }, [
                        _createVNode(_component_VBtn, {
                          icon: isGroupOpen(item) ? '$expand' : '$next',
                          size: "small",
                          variant: "text",
                          onClick: ($event: any) => (toggleGroup(item))
                        }, null, 8, ["icon", "onClick"]),
                        _createTextVNode(" " + _toDisplayString(item.value), 1)
                      ], 8, _hoisted_1)
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["headers", "items", "search", "group-by"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AuthorityAddCompanyFile, {
        modelValue: _ctx.newCompanyDialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCompanyDialog) = $event)),
        newCompanyDialog: _ctx.newCompanyDialog,
        onCompanyAdded: _ctx.fetchCompanies
      }, null, 8, ["modelValue", "newCompanyDialog", "onCompanyAdded"]),
      _createVNode(_component_AuthorityEditCompanyFile, {
        modelValue: _ctx.editCompanyDialog,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editCompanyDialog) = $event)),
        editCompanyDialog: _ctx.editCompanyDialog,
        companyToEdit: _ctx.editedCompany,
        onCompanyUpdated: _ctx.fetchCompanies
      }, null, 8, ["modelValue", "editCompanyDialog", "companyToEdit", "onCompanyUpdated"]),
      _createVNode(_component_AuthorityViewCompanyFile, {
        modelValue: _ctx.viewCompanyDialog,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.viewCompanyDialog) = $event)),
        viewCompanyDialog: _ctx.viewCompanyDialog,
        companyToView: _ctx.selectedCompany
      }, null, 8, ["modelValue", "viewCompanyDialog", "companyToView"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}