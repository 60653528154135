<template>
  <v-container>
    <v-data-table :headers="codeHeaders" :items="codes" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Codes verwalten</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="openNewCodeDialog" v-if="canEdit">Neuen Code</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="small" class="me-2" @click="openEditCodeDialog(item)" v-if="canEdit">mdi-pencil</v-icon>
        <v-icon size="small" class="me-2" @click="openDeleteCodeDialog(item)" v-if="canDelete">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- New Code Dialog -->
    <v-dialog v-model="newCodeDialog" max-width="60%">
      <v-card>
        <v-card-title>Neuer Code</v-card-title>
        <v-card-text>
          <v-form ref="newCodeForm">
            <v-text-field label="Code" v-model="newCode.code" required :rules="[requiredRule]"></v-text-field>
            <v-textarea label="Beschreibung" v-model="newCode.description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newCodeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="addNewCode" :disabled="!isFormValid(newCode)">Hinzufügen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Code Dialog -->
    <v-dialog v-model="editCodeDialog" max-width="60%">
      <v-card>
        <v-card-title>Code bearbeiten</v-card-title>
        <v-card-text>
          <v-form ref="editCodeForm">
            <v-text-field label="Code" v-model="editedCode.code" required :rules="[requiredRule]"></v-text-field>
            <v-textarea label="Beschreibung" v-model="editedCode.description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editCodeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="updateCode">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Code Dialog -->
    <v-dialog v-model="deleteCodeDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">Code löschen</v-card-title>
        <v-card-text>
          Möchten Sie den Code "{{ selectedCode?.code }}" wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteCodeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteCode(selectedCode)">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { ReportCode } from '@/types/Report';
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const errorSnackbar = ref<any>({ visible: false, message: '' });
    const codeHeaders = [
      { title: 'Code', align: 'start', sortable: false, key: 'code' },
      { title: 'Beschreibung', align: 'start', sortable: false, key: 'description' },
      { title: 'Aktionen', key: 'actions', sortable: false }
    ];

    const route = useRoute();

    const canEdit = computed(() => route.meta.canEdit);
    const canDelete = computed(() => route.meta.canDelete);

    const codes = ref<ReportCode[]>([]);
    const newCodeDialog = ref(false);
    const editCodeDialog = ref(false);
    const deleteCodeDialog = ref(false);
    const selectedCode = ref<ReportCode | null>(null);

    const newCode = ref({
      code: '',
      description: ''
    });

    const editedCode = ref<ReportCode>({
      id: 0,
      code: '',
      description: ''
    });

    const snackbar = ref({
      value: false,
      color: '',
      message: ''
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchCodes = async () => {
      try {
        const response = await api.get('report/?action=getCodes');
        codes.value = response.data.map((code: ReportCode) => ({
          ...code,
          description: code.description || ''
        }));
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const addNewCode = async () => {
      try {
        await api.post('report/?action=addCode', newCode.value);
        await fetchCodes();
        newCodeDialog.value = false;
        newCode.value = { code: '', description: '' }; // Reset
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openEditCodeDialog = (code: ReportCode) => {
      editedCode.value = { ...code };
      editCodeDialog.value = true;
    };

    const updateCode = async () => {
      try {
        await api.post('report/?action=editCode', editedCode.value);
        await fetchCodes();
        editCodeDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openDeleteCodeDialog = (code: ReportCode) => {
      selectedCode.value = code;
      deleteCodeDialog.value = true;
    };

    const deleteCode = async (code: ReportCode) => {
      try {
        await api.post('report/?action=deleteCode', { id: code.id });
        await fetchCodes();
        deleteCodeDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    function isFormValid(form: { code: string; description: string }) {
      return form.code !== '';
    }

    const openNewCodeDialog = () => {
      newCode.value = { code: '', description: '' };
      newCodeDialog.value = true;
    };

    onMounted(async () => {
      await fetchCodes();
    });

    return {
      codeHeaders,
      codes,
      newCodeDialog,
      newCode,
      addNewCode,
      requiredRule,
      editCodeDialog,
      editedCode,
      updateCode,
      deleteCodeDialog,
      selectedCode,
      deleteCode,
      openEditCodeDialog,
      openDeleteCodeDialog,
      isFormValid,
      errorSnackbar,
      snackbar,
      openNewCodeDialog,
      canEdit,
      canDelete
    };
  }
});
</script>

<style scoped>
</style>
