<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-data-table
        :headers="headers"
        :items="vehicles"
        class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Fahrzeugverwaltung</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn @click="newVehicleDialog = true" v-if="canEdit">Neues Fahrzeug</v-btn>
                </v-toolbar>
            </template>
            <!-- @ts-ignore -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    size="small"
                    class="me-2"
                    @click="openEditVehicleDialog(item)"
                    v-if="canEdit"
                >
                    mdi-pencil
                </v-icon>
                <v-icon v-if="item.active == 1"
                    size="small"
                    @click="openDeactivateDialog(item)"
                >
                    mdi-close-circle
                </v-icon>
                <v-icon v-else
                    size="small"
                    @click="openActivateDialog(item)"
                >
                    mdi-check-circle
                </v-icon>
                <v-icon v-if="item.damage == 1"
                    size="small"
                    class="me-2"
                    @click="openRemoveDamageDialog(item)"
                >
                    mdi-engine-off
                </v-icon>
                <v-icon v-else
                    size="small"
                    class="me-2"
                    @click="openReportDamageDialog(item)"
                >
                    mdi-engine
                </v-icon>
            </template>
            <template v-slot:[`item.title`]="{ item }">
                <span :class="{ 'text-red': item.damage == 1 }">{{ item.title }}</span>
            </template>
        </v-data-table>

        <v-dialog v-model="deactivateDialog" max-width="400" persistent>
            <v-card>
            <v-card-title class="headline">Fahrzeug deaktivieren</v-card-title>
            <v-card-text>
                Möchtest du das Fahrzeug "{{ selectedVehicle.title }}" wirklich deaktivieren?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deactivateDialog = false">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deactivateVehicle(selectedVehicle)">Deaktivieren</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="activateDialog" max-width="400" persistent>
            <v-card>
            <v-card-title class="headline">Fahrzeug aktivieren</v-card-title>
            <v-card-text>
                Möchtest du das Fahrzeug "{{ selectedVehicle.title }}" wirklich aktivieren?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="activateDialog = false">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="activateVehicle(selectedVehicle)">Aktivieren</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newVehicleDialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="headline">Neues Fahrzeug</v-card-title>
                <v-card-text>
                    <v-form ref="newVehicleForm">
                        <v-text-field label="Titel" v-model="newVehicle.title" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Nummernschild" v-model="newVehicle.numberplate" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Rang" v-model="newVehicle.rank"></v-text-field>
                        <v-text-field type="number" label="Sort Order" v-model="newVehicle.sort_order"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="newVehicleDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="addNewVehicle" :disabled="!isFormValid()">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editVehicleDialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="headline">Fahrzeug bearbeiten</v-card-title>
                <v-card-text>
                    <v-form ref="editVehicleForm">
                        <v-text-field label="Titel" v-model="editedVehicle.title" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Nummernschild" v-model="editedVehicle.numberplate" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Rang" v-model="editedVehicle.rank"></v-text-field>
                        <v-text-field type="number" label="Sort Order" v-model="editedVehicle.sort_order"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editVehicleDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="updateVehicle">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="reportDamageDialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="headline">Schaden eintragen</v-card-title>
                <v-card-text>
                    <v-form ref="reportDamageForm">
                        <v-text-field label="Meldender Beamter" v-model="damageOfficer" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Schaden Beschreibung" v-model="damageDescription" required :rules="[requiredRule]"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="reportDamageDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="reportDamage(selectedVehicle)">Eintragen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="removeDamageDialog" max-width="400" persistent>
            <v-card>
                <v-card-title class="headline">Schaden entfernen</v-card-title>
                <v-card-text>
                    Möchtest du den Schaden am Fahrzeug "{{ selectedVehicle.title }}" wirklich entfernen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="removeDamageDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="removeDamage(selectedVehicle)">Entfernen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { Vehicle } from '@/types/Vehicle';
import { useRoute } from "vue-router";

export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const headers = [
            { title: 'S/O', align: 'start', sortable: false, key: 'sort_order' },
            { title: 'Titel', align: 'start', sortable: false, key: 'title' },
            { title: 'Kennzeichen', align: 'start', sortable: false, key: 'numberplate' },
            { title: 'Rang', align: 'start', sortable: false, key: 'rank' },
            { title: 'Melder', align: 'start', sortable: false, key: 'damage_officer' },
            { title: 'Schaden', align: 'start', sortable: false, key: 'damage_description' },
            { title: 'Melderzeit', align: 'start', sortable: false, key: 'damage_time' },
            { title: 'Aktionen', key: 'actions', sortable: false }
        ];

        const route = useRoute();

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);

        const vehicles = ref<Vehicle[]>([]);
        const deactivateDialog = ref(false);
        const activateDialog = ref(false);
        const reportDamageDialog = ref(false);
        const removeDamageDialog = ref(false);
        const selectedVehicle = ref<Vehicle | null>(null);
        const damageOfficer = ref('');
        const damageDescription = ref('');

        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        const openDeactivateDialog = (vehicle) => {
            selectedVehicle.value = vehicle;
            deactivateDialog.value = true;
        };

        const openActivateDialog = (vehicle) => {
            selectedVehicle.value = vehicle;
            activateDialog.value = true;
        };

        const openReportDamageDialog = (vehicle) => {
            if (vehicle.damage === 1) {
                errorSnackbar.value.message = 'Schaden muss zuerst entfernt werden.';
                errorSnackbar.value.visible = true;
                return;
            }
            selectedVehicle.value = vehicle;
            reportDamageDialog.value = true;
        };

        const openRemoveDamageDialog = (vehicle) => {
            selectedVehicle.value = vehicle;
            removeDamageDialog.value = true;
        };

        const snackbar = ref({
            value: false,
            color: '',
            message: '',
        });

        const fetchVehicles = async () => {
            try {
                const response = await api.get('vehicle/?action=getVehicles');
                vehicles.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const deactivateVehicle = async (vehicle) => {
            try {
                const response = await api.post("vehicle/?action=deactivateVehicle", { id: vehicle.id });
                await fetchVehicles();
                deactivateDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const activateVehicle = async (vehicle) => {
            try {
                const response = await api.post("vehicle/?action=activateVehicle", { id: vehicle.id });
                await fetchVehicles();
                activateDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const reportDamage = async (vehicle) => {
            try {
                const response = await api.post("vehicle/?action=reportDamage", { vehicle_id: vehicle.id, damage_officer: damageOfficer.value, damage_description: damageDescription.value });
                await fetchVehicles();
                reportDamageDialog.value = false;
                damageOfficer.value = '';
                damageDescription.value = '';
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const removeDamage = async (vehicle) => {
            try {
                const response = await api.post("vehicle/?action=removeDamage", { vehicle_id: vehicle.id });
                await fetchVehicles();
                removeDamageDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const newVehicleDialog = ref(false);
        const newVehicle = ref({
            title: '',
            numberplate: '',
            rank: '',
            sort_order: 0,
        });

        function isFormValid() {
            return (
                newVehicle.value.title !== '' &&
                newVehicle.value.numberplate !== ''
            );
        }

        const addNewVehicle = async () => {
            try {
                const response = await api.post("vehicle/?action=addVehicle", newVehicle.value);
                await fetchVehicles();

                if (response.data.error) {
                    snackbar.value.value = true;
                    snackbar.value.color = 'error';
                    snackbar.value.message = response.data.error;
                } else {
                    newVehicleDialog.value = false;
                    newVehicle.value = { title: '', numberplate: '', rank: '', sort_order: 0 };
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const editVehicleDialog = ref(false);
        const editedVehicle = ref({
            id: '',
            title: '',
            numberplate: '',
            rank: '',
            sort_order: 0,
        });

        const openEditVehicleDialog = (vehicle) => {
            editedVehicle.value = { ...vehicle };
            editVehicleDialog.value = true;
        };

        const updateVehicle = async () => {
            try {
                const response = await api.post("vehicle/?action=editVehicle", editedVehicle.value);
                await fetchVehicles();
                editVehicleDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        onMounted(async () => {
            await fetchVehicles();
        });

        return {
            headers,
            vehicles,
            deactivateVehicle,
            openDeactivateDialog,
            deactivateDialog,
            activateVehicle,
            openActivateDialog,
            activateDialog,
            selectedVehicle,
            newVehicleDialog,
            newVehicle,
            addNewVehicle,
            requiredRule,
            isFormValid,
            snackbar,
            editVehicleDialog,
            editedVehicle,
            openEditVehicleDialog,
            updateVehicle,
            errorSnackbar,
            reportDamageDialog,
            openReportDamageDialog,
            reportDamage,
            damageOfficer,
            damageDescription,
            removeDamageDialog,
            openRemoveDamageDialog,
            removeDamage,
            canEdit, 
            canDelete
        };
    },
});
</script>

<style scoped>
.text-red {
    color: red;
}
</style>
