<template>
	<!-- Firma Details Card -->
	<v-card v-if="viewCompanyDialog && !selectedReport">
		<v-card-title>Firma Details</v-card-title>

		<!-- Tabs für verschiedene Ansichten -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Details</v-tab>
			<v-tab :value="2">Beschreibung</v-tab>
			<v-tab :value="3">Verlinkte Berichte</v-tab>
			<v-tab :value="4">Feuerlöscher</v-tab>
		</v-tabs>

		<!-- Inhalt der Tabs -->
		<v-window v-model="currentTab">
			<!-- Tab 1: Firmendetails -->
			<v-window-item :value="1">
				<v-row class="pa-4">
					<v-col cols="12">
						<v-row>
							<v-col cols="6">
								<v-text-field
									label="Name"
									v-model="localCompanyToView.name"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Standort"
									v-model="localCompanyToView.location"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Leitstellennummer"
									v-model="localCompanyToView.phonenumber"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Venture Mail"
									v-model="localCompanyToView.email"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="CEO"
									v-model="localCompanyToView.ceo"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Co-CEO"
									v-model="localCompanyToView.co_ceo"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Typ"
									v-model="localCompanyToView.type_name"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Ansprechpartner & Nummer"
									v-model="localCompanyToView.contact_person"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Letzte Brandschutzbegehung"
									v-model="
										localCompanyToView.last_fire_protection_inspection
									"
									readonly
									type="date"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Brandschutzbegehung gültig bis"
									v-model="
										localCompanyToView.fire_protection_inspection_valid_until
									"
									readonly
									type="date"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-checkbox
									v-model="
										localCompanyToView.contract_available
									"
									label="Vertrag verfügbar"
									readonly
								></v-checkbox>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-window-item>

			<!-- Tab 2: Beschreibung -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="localCompanyToView.description"
						:disabled="true"
					></ckeditor>
				</v-col>
			</v-window-item>

			<v-window-item :value="3">
				<v-container>
					<v-row
						v-for="category in reportCategories"
						:key="category.id"
						class="mb-5"
					>
						<v-col cols="12">
							<h3>{{ category.name }}</h3>
							<v-data-table
								:headers="reportHeaders"
								:items="category.reports"
								class="elevation-1 mt-2"
								:items-per-page="5"
							>
								<template v-slot:[`item.actions`]="{ item }">
									<v-icon
										size="small"
										class="me-2"
										@click="viewReport(item)"
										>mdi-eye</v-icon
									>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-window-item>

			<v-window-item :value="4">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn style="float:right" @click="openAddExtinguisherDialog">
          Feuerlöscher hinzufügen
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="extinguisherHeaders"
          :items="extinguishers"
          class="elevation-1 mt-2"
          :items-per-page="5"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="me-2" @click="openEditExtinguisherDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="confirmDeleteExtinguisher(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

  <!-- Dialog zum Hinzufügen/Bearbeiten -->
  <v-dialog v-model="extinguisherDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ isEditMode ? 'Feuerlöscher bearbeiten' : 'Neuen Feuerlöscher hinzufügen' }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="extinguisherFormRef">
          <v-text-field label="Identifikator" v-model="extinguisherForm.identifier" required></v-text-field>
          <v-text-field label="Registrierungsdatum" v-model="extinguisherForm.registration_date" type="date"></v-text-field>
          <v-text-field label="Letzte Überprüfung" v-model="extinguisherForm.last_inspection" type="date"></v-text-field>
          <v-text-field label="Überprüft von" v-model="extinguisherForm.inspected_by"></v-text-field>
          <v-text-field label="Standort" v-model="extinguisherForm.location"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeExtinguisherDialog">Abbrechen</v-btn>
        <v-btn color="primary" text @click="saveExtinguisher">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Dialog zur Löschbestätigung -->
  <v-dialog v-model="deleteConfirmationDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Feuerlöscher löschen</span>
      </v-card-title>
      <v-card-text>
        Möchten Sie diesen Feuerlöscher wirklich löschen?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="deleteConfirmationDialog = false">Abbrechen</v-btn>
        <v-btn color="red darken-1" text @click="deleteExtinguisher">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-window-item>

		</v-window>

		<!-- Aktionsbutton -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeView"
				>Schließen</v-btn
			>
		</v-card-actions>
	</v-card>

	<!-- Bericht Details Ansicht -->
	<v-card v-else-if="selectedReport">
	<v-card-title>{{ selectedReport.title }}</v-card-title>
	<v-card-text>
		<div v-html="selectedReport.text"></div>
	</v-card-text>
	<v-card-actions>
		<v-spacer></v-spacer>
		<v-btn color="blue darken-1" text @click="closeReportView">Zurück</v-btn>
	</v-card-actions>
</v-card>

</template>

<script lang="ts">
import { defineComponent, ref, watch, reactive } from "vue";
import api from "@/api";
import EditorClass from "@/ckeditor/ckeditor.js";

export default defineComponent({
	name: "ViewCompanyFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		companyToView: {
			type: Object,
			required: true,
		},
		viewCompanyDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		const currentTab = ref(1);
		const localCompanyToView = ref({ ...props.companyToView });
		const reportCategories = ref<any[]>([]);
		const selectedReport = ref<any | null>(null);
		const reportHeaders = [
			{ title: "Titel", align: "start", key: "title" },
			{ title: "Ersteller", align: "start", key: "emp_name" },
			{ title: "Erstellt am", align: "start", key: "created_at" },
			{ title: "Letzte Änderung", align: "start", key: "updated_at" },
			{ title: "Aktionen", align: "start", key: "actions" },
		];

		const fetchLinkedReports = async () => {
			try {
				const response = await api.get(
					`report/?action=getReportsByLinkedCompanies&companyId=${localCompanyToView.value.id}`
				);
				const rawReports = response.data;

				const categorizedReports = rawReports.reduce(
					(categories: any[], report: any) => {
						const categoryName =
							report.cat_name || "Unkategorisiert";
						let category = categories.find(
							(cat: any) => cat.name === categoryName
						);

						if (!category) {
							category = {
								id: report.category_id,
								name: categoryName,
								reports: [],
							};
							categories.push(category);
						}

						category.reports.push({
							...report,
							created_at: new Date(
								report.created_at
							).toLocaleDateString("de-DE"),
						});

						return categories;
					},
					[]
				);

				reportCategories.value = categorizedReports;
			} catch (error) {
				console.error("Fehler beim Abrufen der Berichte:", error);
			}
		};

		const viewReport = (report: any) => {
			selectedReport.value = report;
		};

		const closeReportView = () => {
			selectedReport.value = null;
		};

		const closeView = () => {
			emit("update:modelValue", false);
		};

		watch(
			() => props.companyToView,
			(newVal) => {
				localCompanyToView.value = { ...newVal };
				fetchLinkedReports();
			},
			{ immediate: true }
		);

		const extinguishers = ref<any[]>([]);
    const extinguisherHeaders = [
      { title: "Identifikator", value: "identifier" },
      { title: "Registrierungsdatum", value: "registration_date" },
      { title: "Letzte Überprüfung", value: "last_inspection" },
      { title: "Überprüft von", value: "inspected_by" },
      { title: "Standort", value: "location" },
      { title: "Aktionen", value: "actions", sortable: false },
    ];
    const extinguisherDialog = ref(false);
    const extinguisherForm = reactive<any>({
      id: null,
      identifier: '',
      registration_date: '',
      last_inspection: '',
      inspected_by: '',
      location: '',
      company_id: null,
    });
    const isEditMode = ref(false);
    const selectedExtinguisher = ref<any | null>(null);
    const deleteConfirmationDialog = ref(false);

    // Funktionen zum Abrufen, Hinzufügen, Bearbeiten und Löschen
    const fetchExtinguishers = async () => {
      try {
        const response = await api.get(
          `company/?action=getExtinguishers&company_id=${localCompanyToView.value.id}`
        );
        extinguishers.value = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Feuerlöscher:", error);
      }
    };

    const openAddExtinguisherDialog = () => {
      isEditMode.value = false;
      Object.assign(extinguisherForm, {
        id: null,
        identifier: '',
        registration_date: '',
        last_inspection: '',
        inspected_by: '',
        location: '',
        company_id: localCompanyToView.value.id,
      });
      extinguisherDialog.value = true;
    };

    const openEditExtinguisherDialog = (extinguisher: any) => {
      isEditMode.value = true;
      Object.assign(extinguisherForm, extinguisher);
      extinguisherDialog.value = true;
    };

    const closeExtinguisherDialog = () => {
      extinguisherDialog.value = false;
    };

    const saveExtinguisher = async () => {
      try {
        if (isEditMode.value) {
          // Bearbeiten
          const response = await api.post(
            'company/?action=editExtinguisher',
            extinguisherForm
          );
          console.log("Feuerlöscher aktualisiert:", response.data);
        } else {
          // Hinzufügen
          const response = await api.post(
            'company/?action=addExtinguisher',
            extinguisherForm
          );
          console.log("Feuerlöscher hinzugefügt:", response.data);
        }
        closeExtinguisherDialog();
        fetchExtinguishers();
      } catch (error) {
        console.error("Fehler beim Speichern des Feuerlöschers:", error);
      }
    };

    const confirmDeleteExtinguisher = (extinguisher: any) => {
      selectedExtinguisher.value = extinguisher;
      deleteConfirmationDialog.value = true;
    };

    const deleteExtinguisher = async () => {
      try {
        await api.post('company/?action=deleteExtinguisher', {
          id: selectedExtinguisher.value.id,
        });
        deleteConfirmationDialog.value = false;
        fetchExtinguishers();
      } catch (error) {
        console.error("Fehler beim Löschen des Feuerlöschers:", error);
      }
    };

    // Aktualisieren der Feuerlöscher, wenn sich die Firma ändert
    watch(
      () => props.companyToView,
      (newVal) => {
        localCompanyToView.value = { ...newVal };
        fetchExtinguishers();
      },
      { immediate: true }
    );

		return {
			localCompanyToView,
			EditorClass,
			currentTab,
			closeView,
			reportCategories,
			reportHeaders,
			viewReport,
			closeReportView,
			selectedReport,
			extinguishers,
      extinguisherHeaders,
      extinguisherDialog,
      extinguisherForm,
      isEditMode,
      openAddExtinguisherDialog,
      openEditExtinguisherDialog,
      closeExtinguisherDialog,
      saveExtinguisher,
      confirmDeleteExtinguisher,
      deleteConfirmationDialog,
      deleteExtinguisher,
		};
	},
});
</script>

<style scoped>
.float-right {
	float: right;
}
</style>
