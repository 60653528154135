<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <v-btn v-if="hasPermission(`WRITE_BLACKBOARD_${boardType.toUpperCase()}` && canEdit)" @click="openAddEntryDialog()">Beitrag hinzufügen</v-btn>
            </v-col>
        </v-row>

        <v-alert
        color="#2A3B4D"
        density="compact"
        icon="mdi-developer-board"
        theme="dark"
        class="mt-3"
        >
        <span v-if="boardType === 'admin'">Hier werden Informationen von der Leitung / Administration kundgegeben.<br>
        Für Informationen an die Belegschaft untereinander ist das Schwarze Brett, "Schwarzes Brett - Mitarbeiter" zu nutzen</span>
        <span v-else-if="boardType === 'employee'">Um das Schwarze Brett zu entlasten und übersichtlich zu halten, ist es gewünscht, dass die Kollegen Ihre Informationen über dieses Schwarze Brett kundgeben.</span>
        <span v-else-if="boardType === 'global'">Hier werden Informationen Behördenübergreifend geteilt.</span>

        </v-alert>

        <v-row>
            <v-col cols="12" md="12" lg="12" v-for="entry in entries" :key="entry.id">
                <v-card
                    class="mx-auto mt-3"
                    :prepend-icon="entry.pinned == 1 ? 'mdi-pin' : '$vuetify'"
                    width="100%"
                    :style="entry.pinned == 0 ? 'border-left:4px solid ' + entry.color : 'border:3px solid #2a3b4d'"
                    elevation="2"
                >
                <template v-slot:subtitle>
                    <span class="font-weight-black">{{ entry.author }} - {{ entry.created }}</span>
                </template>
                <template v-slot:title>
                    <span class="font-weight-black">{{ entry.title }}</span>
                </template>
                <template v-slot:append v-if="hasPermission(`WRITE_BLACKBOARD_${boardType.toUpperCase()}`)">
                    <v-btn v-if="entry.pinned == 1"
                        color="medium-emphasis"
                        icon="mdi-pin-off"
                        size="small"
                        tooltip="Abpinnen"
                        @click="unpinEntry(entry.id)"
                    ></v-btn>
                    <v-btn v-if="entry.pinned == 0"
                        color="medium-emphasis"
                        icon="mdi-pin"
                        size="small"
                        tooltip="Anpinnen"
                        @click="pinEntry(entry.id)"
                    ></v-btn>

                    <v-btn
                        color="medium-emphasis"
                        icon="mdi-delete"
                        size="small"
                        tooltip="Löschen"
                        v-if="canDelete"
                        @click="openDeleteConfirmationDialog(entry.id)"
                    ></v-btn>

                    <v-btn
                        color="medium-emphasis"
                        icon="mdi-file-edit"
                        size="small"
                        tooltip="Bearbeiten"
                        v-if="canEdit"
                        @click="openEditEntryDialog(entry)"
                    ></v-btn>
                </template>
                <v-divider></v-divider>

                <v-card-text class="bg-surface-light pt-4">
                    <div v-html="entry.text"></div>
                </v-card-text>
                <v-divider v-if="entry.need_readed == 1 && boardType != 'global'"></v-divider>
                <v-card-text v-if="entry.need_readed == 1 && boardType != 'global'" class="bg-surface-light pt-4">
                    <strong>Gelesen von:</strong> {{ entry.readers }}
                    <v-btn prepend-icon="mdi-eye-check-outline" class="float-end mb-3" @click="markAsRead(entry.id)">
                        Als gelesen markieren
                    </v-btn>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="addEntryDialog" width="60%">
            <v-card>
                <v-card-title>Neuen Beitrag hinzufügen</v-card-title>
                <v-card-text>
                    <v-form ref="addEntryForm">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field label="Titel" required v-model="newEntry.title"></v-text-field>
                                <v-text-field label="Author" required v-model="newEntry.author"></v-text-field>
                                <ckeditor
                                    :editor="EditorClass"
                                    :config="EditorClass.defaultConfig"
                                    v-model="newEntry.text"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-color-picker
                                    v-model="newEntry.color"
                                    label="Farbe"
                                    :swatches="predefinedColors"
                                    swatches-label="Vorgefertigte Farben"
                                    hide-inputs 
                                    show-swatches
                                    hide-canvas
                                    hide-sliders
                                    mode="hexa"
                                    width="100%"
                                ></v-color-picker>
                            </v-col>
                            <v-col cols="12" md="6" v-if="boardType != 'global'">
                                <v-checkbox label="Muss gelesen werden?" v-model="newEntry.need_readed"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-checkbox label="Anpinnen?" v-model="newEntry.pinned"></v-checkbox>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addEntryDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="submitNewEntry">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteConfirmationDialog" width="500">
            <v-card>
                <v-card-title>Eintrag löschen</v-card-title>
                <v-card-text>
                    Bist du sicher, dass du den Eintrag löschen willst?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text="true" @click="deleteConfirmationDialog = false">Nein</v-btn>
                    <v-btn color="error" text="true" @click="deleteEntry">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editEntryDialog" width="60%">
            <v-card>
                <v-card-title>Bearbeite Beitrag</v-card-title>
                <v-card-text>
                <v-form ref="editEntryForm">
                    <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field label="Titel" required v-model="editingEntry.title"></v-text-field>
                        <v-text-field label="Author" required v-model="editingEntry.author"></v-text-field>
                        <ckeditor
                        :editor="EditorClass"
                        :config="EditorClass.defaultConfig"
                        v-model="editingEntry.text"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-color-picker
                        v-model="editingEntry.color"
                        label="Farbe"
                        :swatches="predefinedColors"
                        swatches-label="Vorgefertigte Farben"
                        hide-inputs
                        show-swatches
                        hide-canvas
                        hide-sliders
                        mode="hexa"
                        width="100%"
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" v-if="boardType != 'global'">
                        <v-checkbox label="Muss gelesen werden?" v-model="editingEntry.need_readed"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox label="Anpinnen?" v-model="editingEntry.pinned"></v-checkbox>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="editEntryDialog = false">Abbrechen</v-btn>
                <v-btn color="primary" text @click="submitEditedEntry">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch, PropType } from 'vue';
import api from '@/api';
import { useRoute } from 'vue-router';
import store from '@/store';
import EditorClass from '@/ckeditor/ckeditor.js';

export default defineComponent({
    setup() {
        const route = useRoute();
        const addEntryDialog = ref(false);

        const entries = ref([]);

        const predefinedColors = [["#008000"], ["#FFA500"], ["#FF0000"], ["#2a3b4d"], ["#212121"]];

        const errorSnackbar = ref<any>({ visible: false, message: "" });

        const boardType = computed(() => route.meta.boardType);
        const currentBoardType = ref(route.meta.boardType);

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);

        async function fetchEntries() {
            try {
                const response = await api.post(`blackboard/?action=getEntries&boardType=${boardType.value}`);
                entries.value = response.data.map(entry => ({
                    ...entry,
                    need_readed: entry.need_readed == '1' ? true : false,
                    pinned: entry.pinned == '1' ? true : false,
                    text: convertOembedToIframe(entry.text)
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        function convertOembedToIframe(html) {
            const div = document.createElement('div');
            div.innerHTML = html;
            const oembeds = div.querySelectorAll('oembed[url]');
            oembeds.forEach(oembed => {
                const url = oembed.getAttribute('url');
                let iframe;

                if (url.includes('youtube.com') || url.includes('youtu.be')) {
                    iframe = createYouTubeIframe(url);
                } else if (url.includes('twitch.tv')) {
                    iframe = createTwitchIframe(url);
                }

                if (iframe) {
                    iframe.setAttribute('width', '560');
                    iframe.setAttribute('height', '315');
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
                    iframe.setAttribute('allowfullscreen', 'true');

                    oembed.parentNode.replaceChild(iframe, oembed);
                }
            });
            return div.innerHTML;
        }

        function createYouTubeIframe(url) {
            const videoId = url.split('v=')[1] || url.split('/')[3];
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', embedUrl);
            return iframe;
        }

        function createTwitchIframe(url) {
            const clipId = url.split('clip/')[1];
            const embedUrl = `https://clips.twitch.tv/embed?clip=${clipId}`;
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', embedUrl);
            return iframe;
        }

        const newEntry = ref({
            title: '',
            author: '',
            text: '',
            color: '',
            need_readed: false,
            pinned: false
        });

        function openAddEntryDialog() {
            addEntryDialog.value = true;
        }

        async function submitNewEntry() {
            try {
                let response = await api.post(`blackboard/?action=addEntry&boardType=${boardType.value}`, newEntry.value);

                if (response.data.success) {
                    fetchEntries(); // Aktualisieren der Einträge nach dem Hinzufügen des neuen Eintrags
                    addEntryDialog.value = false;

                    // Zurücksetzen des newEntry-Objekts
                    newEntry.value = {
                        title: '',
                        author: '',
                        text: '',
                        color: '',
                        need_readed: false,
                        pinned: false
                    };
                } else {
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        const editEntryDialog = ref(false);

        const editingEntry = ref({
            id: null,
            title: '',
            author: '',
            text: '',
            color: '',
            need_readed: false,
            pinned: false
        });

        function openEditEntryDialog(item) {
            editingEntry.value = { ...item };
            editEntryDialog.value = true;
        }

        async function submitEditedEntry() {
            try {
                let response = await api.post(`blackboard/?action=editEntry&boardType=${boardType.value}`, editingEntry.value);
                if (response.data.success) {
                    fetchEntries();
                    editEntryDialog.value = false;
                } else {
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        const deleteConfirmationDialog = ref(false);
        const entryToDeleteId = ref(null);

        function openDeleteConfirmationDialog(id) {
            entryToDeleteId.value = id;
            deleteConfirmationDialog.value = true;
        }

        async function deleteEntry() {
            if (entryToDeleteId.value) {
                try {
                    let response = await api.post(`blackboard/?action=deleteEntry&boardType=${boardType.value}`, { id: entryToDeleteId.value });

                    if (response.data.success) {
                        fetchEntries();
                        deleteConfirmationDialog.value = false;
                        entryToDeleteId.value = null;
                    } else {
                        errorSnackbar.value.message = response.data.error;
                        errorSnackbar.value.visible  = true;
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        }

        async function markAsRead(entryId) {
            if (entryId) {
                try {
                    let response = await api.post(`blackboard/?action=setReaded&boardType=${boardType.value}`, { id: entryId });

                    if (response.data.success) {
                        fetchEntries();
                        errorSnackbar.value.message = response.data.success;
                        errorSnackbar.value.visible  = true;
                        errorSnackbar.value.color  = 'green';
                    } else {
                        errorSnackbar.value.message = response.data.error;
                        errorSnackbar.value.visible  = true;
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        }

        async function pinEntry(entryId) {
            if (entryId) {
                try {
                    let response = await api.post(`blackboard/?action=pinEntry&boardType=${boardType.value}`, { id: entryId });
                    if (response.data.success) {
                        fetchEntries();
                    } else {
                        errorSnackbar.value.message = response.data.error;
                        errorSnackbar.value.visible = true;
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible = true;
                }
            }
        }

        async function unpinEntry(entryId) {
            if (entryId) {
                try {
                    let response = await api.post(`blackboard/?action=unpinEntry&boardType=${boardType.value}`, { id: entryId });
                    if (response.data.success) {
                        fetchEntries();
                    } else {
                        errorSnackbar.value.message = response.data.error;
                        errorSnackbar.value.visible = true;
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible = true;
                }
            }
        }

        const userProfile = computed(() => store.state.user);
        const hasPermission = (permissionName) => {
            const profile = userProfile.value;
            if (profile && profile.permissions) {
                return profile.permissions.includes(permissionName) || profile.permissions.includes('ALL_PERMISSIONS');
            }
            return false;
        };

        const path = computed(() =>route.path)

        watch(boardType, () => {
            if (boardType.value && path.value.includes("/blackboard/") ) {
                fetchEntries();
            }
        });
        



        onMounted(() => {
            fetchEntries();
        });

        return {
            errorSnackbar,
            deleteConfirmationDialog,
            addEntryDialog,
            entries,
            newEntry,
            openAddEntryDialog,
            submitNewEntry,
            editEntryDialog,
            editingEntry,
            openDeleteConfirmationDialog,
            predefinedColors,
            EditorClass,
            markAsRead,
            deleteEntry,
            pinEntry,
            unpinEntry,
            submitEditedEntry,
            openEditEntryDialog,
            hasPermission,
            boardType,
            path,
            canEdit,
            canDelete
        }
    }
});
</script>

<style>

</style>
