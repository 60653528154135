<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-col cols="12" sm="6" md="4" lg="4" xl="3" >
      <v-card :dark="true" :style="{ 'background-color': member.is_terminated == true && !showIsTerminated ? '#551a1a87' : currentVacation? 'rgba(33, 85, 26, 0.53)':'' }">
        <v-row no-gutters class="d-flex align-items-start">
            <v-col cols="10">
                <v-card-title @click="expandCard(member.id)" style="cursor:pointer;">
                        [{{ member.servicenumber }}] {{ member.name }}
                </v-card-title>
                <v-card-subtitle>{{ member.rank }} {{ member.is_terminated && !showIsTerminated ? formatDate(member.leavedate) : '' }}</v-card-subtitle>
                <v-card-subtitle>
                    <template v-for="(company, index) in member.companies" :key="index">
                        {{ company.name }}
                    </template>
                    <template v-for="(department, index) in member.departments" :key="index">
                        | {{ department.name }}
                    </template>
                </v-card-subtitle>
                <v-card-subtitle>
                    <a @click="editNotes(member)" style="cursor:pointer; color:brown">Notizen anzeigen</a><br>
                    <v-btn class="ml-2" v-if="currentVacation && canEdit" density="comfortable" icon="mdi-calendar-remove-outline" variant="tonal" @click="stopVacation(currentVacation.id)">
                    </v-btn><br>
                    <span v-if="currentVacation">
                        {{ currentVacation.reason }}:<br>
                        {{ formatDate(currentVacation.start) }} -
                        {{ formatDate(currentVacation.end) }}
                    </span>
                </v-card-subtitle>
            </v-col>
            <v-col cols="2" class="d-flex justify-end align-top">
                <v-img
                :src="getImage(logoURL)"
                width="64"
                height="64"
                class="d-inline-block mt-2 mr-2"
                ></v-img>
            </v-col>
        </v-row>

        <v-card-text v-if="expandedIndex === member.id">
          {{ member.phone }}<br>
          <v-divider class="border-opacity-100 mb-4"></v-divider>
          <v-table>
                <tbody>
                    <tr>
                        <td>
                            Name:
                        </td>
                        <td>
                            {{ member.name }}
                        </td>
                    </tr>
                    <tr>
                        <td>Company:</td>
                        <td>
                            <template v-for="(company, index) in member.companies" :key="index">
                                <v-chip size="small">{{ company.name }}</v-chip>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>Abteilung:</td>
                        <td>
                            <template v-for="(department, index) in member.departments" :key="index">
                                <v-chip size="small">{{ department.name }}</v-chip>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>Telefonnummer:</td>
                        <td>
                            {{ member.phonenumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>Geburtsdatum:</td>
                        <td>
                            {{ formatDate(member.birthdate) }}
                        </td>
                    </tr>
                    <tr>
                        <td>Kontonummer:</td>
                        <td>
                            {{ member.bankaccount }}
                        </td>
                    </tr>
                    <tr>
                        <td>Dienstnummer:</td>
                        <td>
                            {{ member.servicenumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>Venture Mail:</td>
                        <td>
                            {{ member.mail }}
                        </td>
                    </tr>
                    <tr>
                        <td>Ausweis ID:</td>
                        <td>
                            {{ member.personalid }}
                        </td>
                    </tr>
                    <tr>
                        <td>Beigetreten am:</td>
                        <td>
                            {{ formatDate(member.entrydate) }}
                        </td>
                    </tr>
                    <tr>
                    <td>Letzte Beförderung:</td>
                        <td>
                            {{ formatDate(member.last_promotion) }} <a @click="showPromotionsDialog" style="cursor:pointer; color:brown">(Mehr)</a>
                        </td>
                    </tr>
                    <tr>
                    <td>Urlaub:</td>
                        <td>
                            {{ formatDate(member.last_vacation) }} <a @click="showVacationsDialog" style="cursor:pointer; color:brown">(Anzeigen)</a>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-divider class="border-opacity-100 mb-4"></v-divider>

            <v-table>
                <tbody>
                    <tr>
                        <td>
                            Führerschein:
                        </td>
                        <td>
                            <template v-for="license in filteredLicenses('driverlicense')" :key="license.id">
                                <v-chip size="small">{{ license.license }}</v-chip>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>Tauchschein:</td>
                        <td>
                            <template v-for="license in filteredLicenses('diverlicense')" :key="license.id">
                                <v-chip size="small">{{ license.license }}</v-chip>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>Sonstiges:</td>
                        <td>
                            <template v-for="license in filteredLicenses('medic')" :key="license.id">
                                <v-chip size="small">{{ license.license }}</v-chip>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-divider class="border-opacity-100 mb-4"></v-divider>
        </v-card-text>
        <v-card-actions>

          <v-btn prepend-icon="mdi-calendar-plus" @click="openNewVacationDialog(member)" v-if="canEdit">
                Urlaub eintragen
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn text="true" @click="editUser"  v-if="canEdit">
            Edit
          </v-btn>
          <employee-form ref="employeeForm" :edit-mode="editMode" :hide-edit-button="true" />
        </v-card-actions>
      </v-card>
    </v-col>
    <v-dialog v-model="promotionDialog" max-width="700px">
        <v-card>
            <v-card-title class="headline">Beförderungsverlauf</v-card-title>
            <v-card-text>
                <v-data-table :headers="promotionTableHeaders" :items="promotionsArray" class="elevation-1" :sort-by="[{ key: 'promotion_date', order: 'desc' }]">

                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="true" @click="promotionDialog = false">Schließen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="vacationDialog" max-width="800px">
        <v-card>
            <v-card-title class="headline">Urlaubsverlauf</v-card-title>
            <v-card-text>
                <v-data-table :headers="vacationTableHeaders" :items="vacationsArray" class="elevation-1" :sort-by="[{ key: 'end', order: 'desc' }]">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="confirmDelete(item.id)">mdi-delete</v-icon>
                        <!-- Add delete functionality if required -->
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="true" @click="vacationDialog = false">Schließen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog v-model="newVacationDialog" persistent max-width="800">
        <v-card>
        <v-card-title>
            <span class="text-h5">Urlaub hinzufügen</span>
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="4">
                        <v-select
                            :items="['Urlaub', 'Krank', 'Sporadisch im Dienst', 'Sonstiges']"
                            label="Art des Urlaubs"
                            v-model="newVacation.type"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="newVacation.start" label="Von" outlined type="date" required/>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="newVacation.end" label="Bis" outlined type="date" required/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-checkbox
                        v-model="newVacation.reported"
                        label="Gemeldet"
                        outlined
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="newVacation.other" label="Sonstiges" outlined required/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="newVacationDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="saveVacation">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

     <v-dialog v-model="confirmDialog" persistent max-width="300px">
        <v-card>
            <v-card-title>
            <span class="text-h5">Löschen bestätigen</span>
            </v-card-title>
            <v-card-text>
            Willst du das wirklich löschen?
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <DocumentEditor
        class="document-editor-container"
        :selectedDocumentPreview="content"
        :categories="[]"
        @close-document="resetSelectedDocument"
        persistent
    />

    <DocumentEditor
        class="document-editor-container"
        :selectedDocumentEmployeeDocument="selectedDocumentEmployeeNotes"
        :categories="[]"
        @update:selectedDocumentEmployeeDocument="updateSelectedDocumentEmployeeNotes"
        @close-document="resetSelectedDocumentEmployeeNotes"
        persistent
    />



  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from "vue";
  import { License, Employee } from "@/types/Members";
import { TrainingAssign, Training } from  "@/types/Training"
  import EmployeeForm from "./EmployeeForm.vue";
  import DocumentEditor from '@/components/document/DocumentEditor.vue';
  import api from "@/api";
  import { useRoute } from "vue-router";

  interface Vacation {
    id: number;
    reason: string;
    start: string;
    end: string;
    reported: boolean;
    other: string;
    }
  
  export default defineComponent({
    components: {
        EmployeeForm,
        DocumentEditor,
    },
    props: ["member", "logoURL", "companies", "departments", "ranks", "licenses", "showPreview", "showIsTerminated", "trainings", "trainingassigns"],
    setup(props, {emit}) {
        const expandedIndex = ref(-1);
        const editMode = ref(false);
        const errorSnackbar = ref<any>({ visible: false, message: "", color: "red"});
  
        const licensesArray = computed(() => {
            if (props.member.licenses && typeof props.member.licenses === "object") {
                return Object.values(props.member.licenses);
            }
            return [];
        });

        const route = useRoute();

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);
      
        const getImage = (path:string) => {
            try{
                if(path != null){
                    return require('../../assets/ranks/' + path)
                }else{
                    return require('../../assets/logo.png')
                }
            }catch {
                return require('@/assets/logo.png')
            }
        }
  
        const filteredLicenses = (filter:string) => {
            return (licensesArray.value as License[]).filter(
                (license: License) => license.type === filter
            );
        };


        
        const confirmDialog = ref(false);
        const itemToDelete = ref(null);

        const confirmDelete = (id) => {
            itemToDelete.value = id;
            confirmDialog.value = true;
        };

        const proceedWithDelete = async () => {
            try{
                await api.post('employee/?action=deleteVacation', {
                    id: itemToDelete.value,
                });
                confirmDialog.value = false;
            } catch (error) {
                console.error("Error deleting item:", error);
            }
        };



        const newVacation = ref({ type: '', start: '', end: '', reported: false, other: '' });

        async function saveVacation() {
            try {
                await api.post('employee/?action=addVacation', {
                            id: props.member.id,
                            start: newVacation.value.start,
                            end: newVacation.value.end,
                            type: newVacation.value.type,
                            reported: newVacation.value.reported,
                            other: newVacation.value.other,
                        });
                newVacationDialog.value = false;
                newVacation.value = { type: '', start: '', end: '', reported: false, other: '' };
            } catch (err) {
                console.error('Fehler beim Speichern des Urlaubs:', err);
            }
        }


        async function stopVacation(id){
            try{
                await api.post('employee/?action=stopVacation', {
                    id: id,
                    newdate: new Date(),
                });
            } catch (err){
                console.error('Fehler beim Löschen des Urlaubs:', err);
            }
        }


  
        const expandCard = (index: number) => {
            if (expandedIndex.value === index) {
                expandedIndex.value = -1;
            } else {
            expandedIndex.value = index;
            }
        };
  
        const editUser = (user: number) => {
            // Find the employee in the list
            editMode.value = true;
            const employee = props.member;
            const { companies, departments, ranks, licenses } = props;
            const editEmployee = true;
            emit('edit', { employee, companies, departments, ranks, licenses, editEmployee });
        };


        const employeeForm = ref();


        const promotionTableHeaders = [
            { title: "Beförderungsdatum", key: "promotion_date" },
            { title: "Von Rang", key: "old_rank_name" },
            { title: "Zu Rang", key: "new_rank_name" },
        ];

        const vacationTableHeaders = [
            { title: "Grund", key: "reason" },
            { title: "Von", key: "start" },
            { title: "Bis", key: "end" },
            { title: "Gemeldet", key: "reported" },
            { title: "Sonstiges", key: "other" },
            { title: 'Actions', align: 'start', sortable: false, key: 'actions' },
        ];


        const promotionDialog = ref(false);
        const promotionsArray = computed(() => {
            if (props.member.promotions && typeof props.member.promotions === "object") {
                return Object.values(props.member.promotions);
            }
            return [];
        });
        const showPromotionsDialog = () => {
            promotionDialog.value = true;
        };

        const vacationDialog = ref(false);
        const vacationsArray = computed<Vacation[]>(() => {
            if (props.member.vacations && typeof props.member.vacations === "object") {
                return Object.values(props.member.vacations) as Vacation[];
            }
            return [];
        });

        const currentVacation = computed<Vacation | undefined>(() => {
        const today = new Date();
        return vacationsArray.value.find(vacation => {
                const startDate = new Date(vacation.start);
                const endDate = new Date(vacation.end);
                return today >= startDate && today <= endDate;
            });
        });
        const showVacationsDialog = () => {
            vacationDialog.value = true;
        };

        const newVacationDialog = ref(false);


        function formatDate(date: any) {
            if (!date) return "";
            const [datePart] = date.split(" ");
            const [year, month, day] = datePart.split("-");
            return `${day}.${month}.${year}`;
        }


        function processFilteredLicenses(type: string): string[] {
            const filtered = filteredLicenses(type);
            const results: string[] = [];

            filtered.forEach((license) => {
                results.push(license.license);
            });

            return results;
        }


        //ONLY FIREDEPARTMENT
        const personalFile = computed(() => { return `<table border="0" cellpadding="0" cellspacing="0" style="width:100%">
                <tbody>
                    <tr>
                        <td colspan="3">
                        <table align="left" border="1" cellpadding="1" cellspacing="1" style="width:48%">
                            <tbody>
                                <tr>
                                    <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center; width:200px"><span style="color:#FFFFFF"><strong>Mitarbeiterakte</strong></span></td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Dienstnummer, Vor- und Nachname:</strong></span></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.servicenumber} - ${props.member.name}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Geburtsdatum:</strong></td>
                                    <td>
                                    <ul>
                                        <li>${formatDate(props.member.birthdate)}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Venture Account:&nbsp;</strong></span></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.mail}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Positionen:</strong></span></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.rank}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Telefonnummer:</strong></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.phonenumber}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Ausweis ID:</strong></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.personalid}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Kontonummer:</strong></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.bankaccount}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Führerscheine:</strong></td>
                                    <td>
                                    <ul>
                                    <li>${processFilteredLicenses('driverlicense').join(' | ')} | ${processFilteredLicenses('diverlicense').join(' | ')}</li> 
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Nebentätigkeit:</strong></td>
                                    <td>
                                    <ul>
                                        <li>${props.member.sidejob}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Spindnummern:</strong></td>
                                    <td>
                                    <ul>
                                        <li>ES:${props.member.servicenumber}|${props.member.servicenumber}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Vertrag gesendet / unterschrieben:</strong></td>
                                    <td>
                                    <ul>
                                    <li>${formatDate(props.member.entrydate)} / ${formatDate(props.member.entrydate)}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Probezeit Ende:</strong></td>
                                    <td>
                                    <ul>
                                        <li>&nbsp;</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Eintrittsdatum:</strong></td>
                                    <td>
                                    <ul>
                                    <li>${formatDate(props.member.entrydate)}</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Beförderungen:</strong></td>
                                    <td>
                                    <ul>
                                        <li>&nbsp;</li>
                                    </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170); width:250px"><strong>Austrittsdatum:</strong></td>
                                    <td>
                                    <ul>
                                        <li>&nbsp;</li>
                                    </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table align="right" border="1" cellpadding="1" cellspacing="1" style="width:48%">
                            <tbody>
                                <tr>
                                    <td colspan="3" style="background-color:rgb(255, 0, 51); text-align:center; white-space:nowrap"><span style="color:#FFFFFF"><em><u><strong>Ausgehändigtes Eigentum vom LSFD</strong></u></em></span></td>
                                </tr>
                                <tr>
                                    <td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Ausrüstung</strong></td>
                                    <td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Ausgehändigt Ja/Nein</strong></td>
                                    <td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Zurückgegeben Ja/Nein</strong></td>
                                </tr>
                                <tr>
                                    <td style="text-align:center; white-space:nowrap"><strong>Funkgerät und Funkgurt</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                </tr>
                                <tr>
                                    <td style="text-align:center; white-space:nowrap"><strong>FD Dienstmarke</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                </tr>
                                <tr>
                                    <td style="text-align:center; white-space:nowrap"><strong>FD Arbeitskleidung</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                </tr>
                                <tr>
                                    <td style="text-align:center; white-space:nowrap"><strong>Taschenlampe</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                    <td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="right" border="1" cellpadding="1" cellspacing="1" style="width:48%">
                            <tbody>
                                <tr>
                                    <td colspan="5" style="background-color:rgb(255, 0, 51); text-align:center"><span style="color:#FFFFFF"><strong>Vorfälle / Abmahnungen / Suspendierungen</strong></span></td>
                                </tr>
                                <tr>
                                    <td style="background-color: rgb(170, 170, 170);"><strong>Datum</strong></td>
                                    <td style="background-color: rgb(170, 170, 170);"><strong>Vorfall</strong></td>
                                    <td style="background-color: rgb(170, 170, 170);"><strong>Zeugen</strong></td>
                                    <td style="background-color: rgb(170, 170, 170);"><strong>Konsequenz</strong></td>
                                    <td style="background-color: rgb(170, 170, 170);"><strong>Eintragung</strong></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>`;
        });



        
    // Transform departments and companies objects to arrays
    const memberDepartments = computed(() => {
        return props.member.departments ? Object.values(props.member.departments) : [];
    });

    const memberCompanies = computed(() => {
        return props.member.companies ? Object.values(props.member.companies) : [];
    });

    const createTrainingRows = () => {
    const categoriesMap: { [key: number]: { catName: string, color: string, trainings: Training[] } } = {};

    console.log("Props trainings:", props.trainings);
    console.log("Props trainingassigns:", props.trainingassigns);
    console.log("Member ID:", props.member.id);

    // Group trainings by category ID
    (props.trainings as Training[]).forEach(training => {
        if (!categoriesMap[training.catId]) {
            categoriesMap[training.catId] = {
                catName: training.cat_name,
                color: training.color,
                trainings: []
            };
        }
        categoriesMap[training.catId].trainings.push(training);
    });

    // Convert categories map to array and sort by the original order of Trainings
    const sortedCategories = Object.values(categoriesMap).sort((a, b) => {
        const aIndex = (props.trainings as Training[]).findIndex(t => t.catId === a.trainings[0].catId);
        const bIndex = (props.trainings as Training[]).findIndex(t => t.catId === b.trainings[0].catId);
        return aIndex - bIndex;
    });

    let trainingRows = '';

    sortedCategories.forEach(category => {
        trainingRows += `
            <tr>
                <td colspan="2" style="background-color:#${category.color}; text-align:center"><strong><span style="color:#D3D3D3">${category.catName}</span></strong></td>
            </tr>
        `;
        category.trainings.forEach(training => {
            const assignments = (props.trainingassigns as any[]).filter(assign => {
                const match = assign.training_id === training.id && assign.employeeid == props.member.id;
                if (match) {
                    console.log("Matched assignment:", assign);
                }
                return match;
            });

            if (assignments.length > 0) {
                const latestAssignment = assignments.reduce((prev, current) => (prev.id > current.id ? prev : current), assignments[0]);
                const trainingDate = latestAssignment ? latestAssignment.date : '&nbsp;';
                const trainingInstructor = latestAssignment ? latestAssignment.instructor : '&nbsp;';
                trainingRows += `
                    <tr>
                        <td style="width:200px"><strong>${training.name}</strong></td>
                        <td>${trainingDate === '&nbsp;' ? '' : formatDate(trainingDate)} - ${trainingInstructor}</td>
                    </tr>
                `;
            } else {
                trainingRows += `
                    <tr>
                        <td style="width:200px"><strong>${training.name}</strong></td>
                        <td>&nbsp;</td>
                    </tr>
                `;
            }
        });
    });

    console.log("Generated training rows:", trainingRows);
    return trainingRows;
};




    const createCompanies = () => {
        return memberCompanies.value.map((company: any) => company.name).join(', ');
    };

    const createDepartments = () => {
        return memberDepartments.value.map((department: any) => department.name).join(', ');
    };


    const personFile = computed(() => {
        return `
            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                <tbody>
                    <tr>
                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Mitarbeiterdaten</span></strong></td>
                    </tr>
                    <tr>
                        <td style="width:200px"><strong>Name</strong></td>
                        <td><strong>${props.member.name}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Telefonnummer</strong></td>
                        <td><strong>${props.member.phonenumber}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Dienstnummer</strong></td>
                        <td><strong>${props.member.servicenumber}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Rang</strong></td>
                        <td><strong>${props.member.rank}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Abteilung & Position</strong></td>
                        <td>${createDepartments()}</td>
                    </tr>
                    <tr>
                        <td><strong>Company</strong></td>
                        <td>${createCompanies()}</td>
                    </tr>
                </tbody>
            </table>

            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                <tbody>
                    <tr>
                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Lizenzen</span></strong></td>
                    </tr>
                    <tr>
                        <td style="width:200px"><strong>PKW</strong></td>
                        <td><strong>${(licensesArray.value as License[]).some(license => license.license === 'PKW') ? 'X' : ''}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>LKW</strong></td>
                        <td><strong>${(licensesArray.value as License[]).some(license => license.license === 'LKW') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>Boot</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'Boot') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>Helikopter</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'Pilot') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>OWD</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'OWD') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>AOWD</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'AOWD') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>DSD</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'DSD') ? 'X' : ''}</td>
                    </tr>
                    <tr>
                        <td><strong>Rescue Diver</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'RSD') ? 'X' : ''}</td>
                    </tr>
                </tbody>
            </table>

            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                <tbody>
                    <tr>
                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Ausbildungsnachweise & Einweisungen</span></strong></td>
                    </tr>
                    ${createTrainingRows()}
                </tbody>
            </table>

            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                <tbody>
                    <tr>
                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><span style="color:#D3D3D3"><strong>Sonstiges</strong></span></td>
                    </tr>
                    <tr>
                        <td style="width:200px"><strong>Erweiterter EH-Kurs</strong></td>
                        <td>${(licensesArray.value as License[]).some(license => license.license === 'erw. Erstehilfe') ? 'X' : ''}</td>
                    </tr>
                </tbody>
            </table>

            <p><span style="color:#FF0000"><em>*Informationen in dieser Akte dürfen nur von der Leitung sowie dazu berechtigten Personen abgeändert/hinzugefügt/entfernt werden</em></span></p>
        `;
    });







        function openNewVacationDialog(member) {
            newVacationDialog.value = true;
        }

        async function copyToClipboard(file:string) {
            try {
                if(file == 'personalFile'){
                    await navigator.clipboard.writeText(personalFile.value);
                    if(props.showPreview == true) content.value = personalFile.value;
                    errorSnackbar.value.message = 'Personalakte erfolgreich kopiert.';
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = 'green';
                }else if(file == 'personFile'){
                    await navigator.clipboard.writeText(personFile.value);
                    if(props.showPreview == true) content.value = personFile.value;
                    errorSnackbar.value.message = 'Personenakte erfolgreich kopiert.';
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = 'green';
                }
            } catch (err) {
                errorSnackbar.value.message = 'Personalakte erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
            }
        }
        //ONLY FIREDEPARTMENT


        //Preview File System

        const content = ref(null);
        function resetSelectedDocument(){
            content.value = null;
        }

        //Preview File System

        //Employee File Notes
        const selectedDocumentEmployeeNotes = ref<Employee | null>(null);
        function resetSelectedDocumentEmployeeNotes(){
            selectedDocumentEmployeeNotes.value = null;
        }

        const updateSelectedDocumentEmployeeNotes = async (updatedEmployee: any) => {
            if (updatedEmployee.id != -1) {
                try {
                    const response = await api.post('employee/?action=updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes});
                    if(response.data.success){
                        emit('updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes });
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        };

        async function editNotes(member: Employee) {
            try {
                selectedDocumentEmployeeNotes.value = member;
            } catch (err) {
                errorSnackbar.value.message = 'Fehler beim bearbeiten.';
                errorSnackbar.value.visible  = true;
            }
        }

        async function addVacation(member: Employee) {
            try {
                selectedDocumentEmployeeNotes.value = member;
            } catch (err) {
                errorSnackbar.value.message = 'Fehler beim bearbeiten.';
                errorSnackbar.value.visible  = true;
            }
        }

        //Employee File Notes

  
      return {
        expandedIndex,
        expandCard,
        employeeForm,
        editUser,
        filteredLicenses,
        getImage,
        editMode,
        promotionDialog,
        vacationDialog,
        showPromotionsDialog,
        promotionsArray,
        promotionTableHeaders,
        vacationTableHeaders,
        formatDate,
        copyToClipboard,
        errorSnackbar,
        content,
        resetSelectedDocument,
        resetSelectedDocumentEmployeeNotes,
        selectedDocumentEmployeeNotes,
        updateSelectedDocumentEmployeeNotes,
        editNotes,
        showVacationsDialog,
        vacationsArray,
        currentVacation,
        addVacation,
        newVacationDialog,
        newVacation,
        openNewVacationDialog,
        saveVacation,
        stopVacation,
        confirmDialog,
        itemToDelete,
        proceedWithDelete,
        confirmDelete,
        canEdit,
        canDelete
      };
    },
  });
  </script>
  