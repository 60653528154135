<template>
  <v-container>
    <v-data-table :headers="templateHeaders" :items="templates" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Templates verwalten</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="openNewTemplateDialog" v-if="canEdit">Neues Template</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="small" class="me-2" @click="openEditTemplateDialog(item)" v-if="canEdit">mdi-pencil</v-icon>
        <v-icon size="small" class="me-2" @click="openDeleteTemplateDialog(item)" v-if="canDelete">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- New Template Dialog -->
    <v-dialog v-model="newTemplateDialog" max-width="60%">
      <v-card>
        <v-card-title>
          Neues Template
        </v-card-title>
        <v-card-text>
          <v-form ref="newTemplateForm">
            <v-text-field label="Name" v-model="newTemplate.name" required :rules="[requiredRule]"></v-text-field>
            <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="newTemplate.template"></ckeditor>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newTemplateDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="addNewTemplate" :disabled="!isFormValid(newTemplate)">Hinzufügen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Template Dialog -->
    <v-dialog v-model="editTemplateDialog" max-width="60%">
      <v-card>
        <v-card-title>
          Template bearbeiten
        </v-card-title>
        <v-card-text>
          <v-form ref="editTemplateForm">
            <v-text-field label="Name" v-model="editedTemplate.name" required :rules="[requiredRule]"></v-text-field>
            <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="editedTemplate.template"></ckeditor>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editTemplateDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="updateTemplate">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Template Dialog -->
    <v-dialog v-model="deleteTemplateDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">Template löschen</v-card-title>
        <v-card-text>
          Möchten Sie das Template "{{ selectedTemplate?.name }}" wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteTemplateDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteTemplate(selectedTemplate)">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import EditorClass from '@/ckeditor/ckeditor.js';
import { useRoute } from "vue-router";

interface Template {
  id: number;
  name: string;
  template: string;
}

export default defineComponent({
  setup() {
    const errorSnackbar = ref<any>({ visible: false, message: '' });
    const templateHeaders = [
      { title: 'Name', align: 'start', sortable: false, key: 'name' },
      { title: 'Aktionen', key: 'actions', sortable: false }
    ];

    const route = useRoute();

    const canEdit = computed(() => route.meta.canEdit);
    const canDelete = computed(() => route.meta.canDelete);

    const templates = ref<Template[]>([]);
    const newTemplateDialog = ref(false);
    const editTemplateDialog = ref(false);
    const deleteTemplateDialog = ref(false);
    const selectedTemplate = ref<Template | null>(null);

    const newTemplate = ref({
      name: '',
      template: ''
    });

    const editedTemplate = ref<Template>({
      id: 0,
      name: '',
      template: ''
    });

    const snackbar = ref({
      value: false,
      color: '',
      message: ''
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchTemplates = async () => {
      try {
        const response = await api.get('report/?action=getTemplates');
        templates.value = response.data;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const addNewTemplate = async () => {
      try {
        const response = await api.post('report/?action=addTemplate', newTemplate.value);
        await fetchTemplates();
        newTemplateDialog.value = false;
        newTemplate.value = { name: '', template: '' };
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openEditTemplateDialog = (template: Template) => {
      editedTemplate.value = { ...template };
      editTemplateDialog.value = true;
    };

    const updateTemplate = async () => {
      try {
        const response = await api.post('report/?action=editTemplate', editedTemplate.value);
        await fetchTemplates();
        editTemplateDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openDeleteTemplateDialog = (template: Template) => {
      selectedTemplate.value = template;
      deleteTemplateDialog.value = true;
    };

    const deleteTemplate = async (template: Template) => {
      try {
        const response = await api.post('report/?action=deleteTemplate', { id: template.id });
        await fetchTemplates();
        deleteTemplateDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    function isFormValid(form: { name: string; template: string }) {
      return form.name !== '' && form.template !== '';
    }

    const openNewTemplateDialog = () => {
      newTemplate.value = { name: '', template: '' };
      newTemplateDialog.value = true;
    };

    onMounted(async () => {
      await fetchTemplates();
    });

    return {
      templateHeaders,
      templates,
      newTemplateDialog,
      newTemplate,
      addNewTemplate,
      requiredRule,
      editTemplateDialog,
      editedTemplate,
      updateTemplate,
      deleteTemplateDialog,
      selectedTemplate,
      deleteTemplate,
      openEditTemplateDialog,
      openDeleteTemplateDialog,
      isFormValid,
      errorSnackbar,
      snackbar,
      EditorClass,
      openNewTemplateDialog,
      canEdit,
      canDelete
    };
  }
});
</script>

<style scoped>
</style>
