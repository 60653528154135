import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_data_table, {
          headers: _ctx.headers,
          items: _ctx.vehicles,
          class: "elevation-1"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Fahrzeugverwaltung")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                (_ctx.canEdit)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newVehicleDialog = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Neues Fahrzeug")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            (_ctx.canEdit)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  size: "small",
                  class: "me-2",
                  onClick: ($event: any) => (_ctx.openEditVehicleDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-pencil ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (item.active == 1)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  size: "small",
                  onClick: ($event: any) => (_ctx.openDeactivateDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-close-circle ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 2,
                  size: "small",
                  onClick: ($event: any) => (_ctx.openActivateDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-check-circle ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])),
            (item.damage == 1)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 3,
                  size: "small",
                  class: "me-2",
                  onClick: ($event: any) => (_ctx.openRemoveDamageDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-engine-off ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 4,
                  size: "small",
                  class: "me-2",
                  onClick: ($event: any) => (_ctx.openReportDamageDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-engine ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
          ]),
          [`item.title`]: _withCtx(({ item }) => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'text-red': item.damage == 1 })
            }, _toDisplayString(item.title), 3)
          ]),
          _: 2
        }, 1032, ["headers", "items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deactivateDialog,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.deactivateDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fahrzeug deaktivieren")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchtest du das Fahrzeug \"" + _toDisplayString(_ctx.selectedVehicle.title) + "\" wirklich deaktivieren? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deactivateDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deactivateVehicle(_ctx.selectedVehicle)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Deaktivieren")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.activateDialog,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activateDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fahrzeug aktivieren")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchtest du das Fahrzeug \"" + _toDisplayString(_ctx.selectedVehicle.title) + "\" wirklich aktivieren? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activateDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.activateVehicle(_ctx.selectedVehicle)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Aktivieren")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.newVehicleDialog,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newVehicleDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neues Fahrzeug")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "newVehicleForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Titel",
                          modelValue: _ctx.newVehicle.title,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newVehicle.title) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Nummernschild",
                          modelValue: _ctx.newVehicle.numberplate,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newVehicle.numberplate) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Rang",
                          modelValue: _ctx.newVehicle.rank,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newVehicle.rank) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          type: "number",
                          label: "Sort Order",
                          modelValue: _ctx.newVehicle.sort_order,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newVehicle.sort_order) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.newVehicleDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.addNewVehicle,
                      disabled: !_ctx.isFormValid()
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.editVehicleDialog,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editVehicleDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Fahrzeug bearbeiten")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "editVehicleForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Titel",
                          modelValue: _ctx.editedVehicle.title,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editedVehicle.title) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Nummernschild",
                          modelValue: _ctx.editedVehicle.numberplate,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editedVehicle.numberplate) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Rang",
                          modelValue: _ctx.editedVehicle.rank,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editedVehicle.rank) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          type: "number",
                          label: "Sort Order",
                          modelValue: _ctx.editedVehicle.sort_order,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editedVehicle.sort_order) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.editVehicleDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.updateVehicle
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.reportDamageDialog,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.reportDamageDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Schaden eintragen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "reportDamageForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Meldender Beamter",
                          modelValue: _ctx.damageOfficer,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.damageOfficer) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Schaden Beschreibung",
                          modelValue: _ctx.damageDescription,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.damageDescription) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.reportDamageDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.reportDamage(_ctx.selectedVehicle)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Eintragen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.removeDamageDialog,
          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.removeDamageDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Schaden entfernen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchtest du den Schaden am Fahrzeug \"" + _toDisplayString(_ctx.selectedVehicle.title) + "\" wirklich entfernen? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.removeDamageDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.removeDamage(_ctx.selectedVehicle)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Entfernen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar.value,
          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.snackbar.value) = $event)),
          color: _ctx.snackbar.color,
          timeout: 4000
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}