<template>
	<v-container>
		<!-- Button zum Hinzufügen einer neuen Rechnung -->
		<v-btn
			@click="openAddInvoiceForm"
			class="float-right"
			v-if="
				!showAddInvoice &&
				!editInvoiceDialog &&
				!viewInvoiceDialog &&
				canEdit
			"
		>
			Neue Rechnung
		</v-btn>

		<!-- Tabelle für Rechnungsverwaltung -->
		<v-data-table
			:headers="invoiceHeaders"
			:items="filteredInvoices"
			class="elevation-1 mt-12"
			:search="search"
			:items-per-page="25"
			v-if="!showAddInvoice && !editInvoiceDialog && !viewInvoiceDialog"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Rechnungsverwaltung</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						label="Suche"
						class="mx-4 mt-3"
						variant="underlined"
					/>
				</v-toolbar>
			</template>
			<template v-slot:[`item.outgoing`]="{ item }">
				<div class="text-start">
				<v-chip
					:color="item.outgoing ? 'green' : 'red'"
					:text="item.outgoing ? 'Einnahme' : 'Ausgabe'"
					class="text-uppercase"
					size="small"
					label
				></v-chip>
				</div>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					size="small"
					class="me-2"
					@click="openEditInvoiceDialog(item)"
					v-if="canEdit"
					>mdi-pencil</v-icon
				>
				<v-icon
					size="small"
					class="me-2"
					@click="openViewInvoiceDialog(item)"
					>mdi-eye</v-icon
				>
				<v-icon
					size="small"
					class="me-2"
					@click="deleteInvoice(item.id)"
					v-if="canDelete"
					>mdi-delete</v-icon
				>
			</template>
		</v-data-table>

		<!-- Add Invoice Dialog -->
		<AddInvoiceFile
			v-if="showAddInvoice"
			@invoiceAdded="handleInvoiceAdded"
			@closeForm="closeAddInvoiceForm"
		/>

		<!-- Edit Invoice Dialog -->
		<EditInvoiceFile
			v-if="editInvoiceDialog"
			:invoiceToEdit="selectedInvoice"
			@invoiceUpdated="handleInvoiceUpdated"
			@closeForm="closeEditInvoiceDialog"
		/>

		<!-- View Invoice Dialog -->
		<ViewInvoiceFile
			v-if="viewInvoiceDialog"
			:invoiceToView="selectedInvoice"
			@closeForm="closeViewInvoiceDialog"
		/>

		<!-- Snackbar für Fehlermeldungen -->
		<v-snackbar v-model="errorSnackbar.visible" :color="'red'">
			{{ errorSnackbar.message }}
		</v-snackbar>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import AddInvoiceFile from "@/components/InvoiceFile/Add.vue";
import EditInvoiceFile from "@/components/InvoiceFile/Edit.vue";
import ViewInvoiceFile from "@/components/InvoiceFile/View.vue";
import api from "@/api";
import { useRoute } from "vue-router";

export default defineComponent({
	components: { AddInvoiceFile, EditInvoiceFile, ViewInvoiceFile },
	setup() {
		const invoices = ref([]);
		const search = ref("");
		const showAddInvoice = ref(false);
		const editInvoiceDialog = ref(false);
		const viewInvoiceDialog = ref(false);
		const selectedInvoice = ref(null);
		const errorSnackbar = ref({ visible: false, message: "" });

		const invoiceHeaders = [
			{ title: "", align: "start", key: "outgoing" },
			{ title: "Betreff", align: "start", key: "title" },
			{ title: "Kunde", align: "start", key: "customer" },
			{
				title: "Bestellung Geliefert",
				align: "start",
				key: "is_delivered_date",
			},
			{ title: "Rechnung gesendet", align: "start", key: "is_sent_date" },
			{ title: "Rechnung bezahlt", align: "start", key: "is_paid_date" },
			{ title: "Aktionen", key: "actions", sortable: false },
		];

		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const filteredInvoices = computed(() =>
			invoices.value.filter((invoice) =>
				invoice.customer
					.toLowerCase()
					.includes(search.value.toLowerCase())
			)
		);

		const fetchInvoices = async () => {
			try {
				const response = await api.post("invoice/?action=getInvoices");
				invoices.value = response.data.map((invoice) => ({
					...invoice,
					is_sent: invoice.is_sent === "1",
					is_paid: invoice.is_paid === "1",
					is_delivered: invoice.is_delivered === "1",
					outgoing: invoice.outgoing == "1" ? 1 : 0,
					is_sent_date: invoice.is_sent_date || "Nicht gesendet",
					is_paid_date: invoice.is_paid_date || "Nicht bezahlt",
					is_delivered_date:
						invoice.is_delivered_date || "Nicht geliefert",
				}));
			} catch (error) {
				showErrorSnackbar(
					error.response?.data?.error ||
						"Fehler beim Laden der Rechnungen."
				);
			}
		};

		const addInvoice = async (invoice: any) => {
			try {
				const formData = new FormData();

				for (const [key, value] of Object.entries(invoice)) {
					if (key === "attachments" && Array.isArray(value)) {
						value.forEach((file: File) =>
							formData.append("newAttachments[]", file)
						);
					} else if (key === "items" && Array.isArray(value)) {
						value.forEach((item, index) => {
							for (const [itemKey, itemValue] of Object.entries(
								item
							)) {
								formData.append(
									`items[${index}][${itemKey}]`,
									String(itemValue)
								);
							}
						});
					} else {
						formData.append(key, String(value));
					}
				}

				await api.post("invoice/?action=addInvoice", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				});

				await fetchInvoices();
			} catch (error) {
				showErrorSnackbar(
					error.response?.data?.error ||
						"Fehler beim Hinzufügen der Rechnung."
				);
			}
		};

		const editInvoice = async (invoice: any) => {
			try {
				const formData = new FormData();

				for (const [key, value] of Object.entries(invoice)) {
					if (key === "newAttachments" && Array.isArray(value)) {
						// Verwende newAttachments für den Datei-Upload
						value.forEach((file: File) => {
							if (file) {
								formData.append("newAttachments[]", file);
							}
						});
					} else if (
						key === "removedAttachments" &&
						Array.isArray(value)
					) {
						// Entfernte Anhänge an das Backend senden
						value.forEach((attachment: string) => {
							formData.append("removedAttachments[]", attachment);
						});
					} else if (key === "items" && Array.isArray(value)) {
						value.forEach((item, index) => {
							for (const [itemKey, itemValue] of Object.entries(
								item
							)) {
								formData.append(
									`items[${index}][${itemKey}]`,
									String(itemValue)
								);
							}
						});
					} else {
						formData.append(key, String(value));
					}
				}

				await api.post("invoice/?action=editInvoice", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				});

				await fetchInvoices();
			} catch (error) {
				showErrorSnackbar(
					error.response?.data?.error ||
						"Fehler beim Bearbeiten der Rechnung."
				);
			}
		};

		const deleteInvoice = async (invoiceId) => {
			try {
				await api.post("invoice/?action=deleteInvoice", {
					id: invoiceId,
				});
				await fetchInvoices();
			} catch (error) {
				showErrorSnackbar(
					error.response?.data?.error ||
						"Fehler beim Löschen der Rechnung."
				);
			}
		};

		const showErrorSnackbar = (message) => {
			errorSnackbar.value.message = message;
			errorSnackbar.value.visible = true;
		};

		const openAddInvoiceForm = () => {
			showAddInvoice.value = true;
		};

		const closeAddInvoiceForm = () => {
			showAddInvoice.value = false;
		};

		const handleInvoiceAdded = async (newInvoice) => {
			await addInvoice(newInvoice);
			closeAddInvoiceForm();
		};

		const openEditInvoiceDialog = (invoice) => {
			selectedInvoice.value = { ...invoice };
			editInvoiceDialog.value = true;
		};

		const closeEditInvoiceDialog = () => {
			editInvoiceDialog.value = false;
		};

		const handleInvoiceUpdated = async (updatedInvoice) => {
			await editInvoice(updatedInvoice);
			closeEditInvoiceDialog();
		};

		const openViewInvoiceDialog = (invoice) => {
			selectedInvoice.value = { ...invoice };
			viewInvoiceDialog.value = true;
		};

		const closeViewInvoiceDialog = () => {
			viewInvoiceDialog.value = false;
		};

		onMounted(fetchInvoices);

		return {
			invoices,
			search,
			invoiceHeaders,
			filteredInvoices,
			fetchInvoices,
			addInvoice,
			editInvoice,
			deleteInvoice,
			openAddInvoiceForm,
			closeAddInvoiceForm,
			handleInvoiceAdded,
			openEditInvoiceDialog,
			closeEditInvoiceDialog,
			handleInvoiceUpdated,
			openViewInvoiceDialog,
			closeViewInvoiceDialog,
			showAddInvoice,
			editInvoiceDialog,
			viewInvoiceDialog,
			selectedInvoice,
			errorSnackbar,
			canEdit,
			canDelete,
		};
	},
});
</script>
