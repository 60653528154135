<template>
	<!-- Firma bearbeiten Formular -->
	<v-card v-if="editCompanyDialog">
		<v-card-title>Firma bearbeiten</v-card-title>

		<!-- Tabs für verschiedene Formularinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Firmendetails</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>

		<!-- Inhalt der Tabs -->
		<v-window v-model="currentTab">
			<!-- Tab 1: Firmendetails -->
			<v-window-item :value="1">
				<v-form ref="editCompanyForm" class="pa-4">
					<v-row>
						<v-col cols="12">
							<v-row>
								<!-- Allgemeine Felder -->
								<v-col cols="6">
									<v-text-field
										label="Name"
										v-model="editedCompany.name"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Standort"
										v-model="editedCompany.location"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Leitstellennummer"
										v-model="editedCompany.phonenumber"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Venture Mail" v-model="editedCompany.email"></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="CEO"
										v-model="editedCompany.ceo"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Co-CEO" v-model="editedCompany.co_ceo"></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-select
										label="Typ"
										v-model="editedCompany.type_id"
										:items="companyTypes"
										item-title="name"
										item-value="id"
										required
										:rules="[requiredRule]"
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Ansprechpartner & Nummer"
										v-model="editedCompany.contact_person"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Letzte Brandschutzbegehung"
										v-model="editedCompany.last_fire_protection_inspection"
										type="date"
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Brandschutzbegehung gültig bis"
										v-model="editedCompany.fire_protection_inspection_valid_until"
										type="date"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										v-model="editedCompany.contract_available"
										label="Vertrag verfügbar"
									></v-checkbox>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="editedCompany.description"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeForm">Abbrechen</v-btn>
			<v-btn color="blue darken-1" text @click="updateCompany">Speichern</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { Company, CompanyType } from "@/types/Company";
import api from "@/api";
import EditorClass from "@/ckeditor/ckeditor.js";

export default defineComponent({
	name: "EditCompanyFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		companyToEdit: {
			type: Object as () => Company,
			required: true,
		},
        editCompanyDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "companyUpdated"],
	setup(props, { emit }) {
		const editedCompany = ref<Company>({ ...props.companyToEdit });
		const companyTypes = ref<CompanyType[]>([]);
		const currentTab = ref(1);

		const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

		const fetchCompanyTypes = async () => {
			try {
				const response = await api.get("company/?action=getCompanyTypes");
				companyTypes.value = response.data;
			} catch (error) {
				console.error("Fehler beim Laden der Firmentypen:", error);
			}
		};

		const updateCompany = async () => {
			try {
				await api.post("company/?action=editCompany", editedCompany.value);
				emit("companyUpdated");
				closeForm();
			} catch (error) {
				console.error("Fehler beim Aktualisieren der Firma:", error);
			}
		};

		const closeForm = () => {
			emit("update:modelValue", false);
		};

		watch(
			() => props.companyToEdit,
			(newVal) => {
				editedCompany.value = { ...newVal };
			},
			{ immediate: true }
		);

		onMounted(async () => {
			await fetchCompanyTypes();
		});

		return {
			editedCompany,
			companyTypes,
			requiredRule,
			updateCompany,
			EditorClass,
			currentTab,
			closeForm,
		};
	},
});
</script>

<style scoped>
.float-right {
	float: right;
}
</style>
