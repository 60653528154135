import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23a85b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "truncate" }
const _hoisted_2 = { class: "file-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                label: "Suche",
                "single-line": "",
                "hide-details": "",
                clearable: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.openFolderDialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Ordner erstellen")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "3" }, {
            default: _withCtx(() => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_file_input, {
                    key: 0,
                    modelValue: _ctx.uploadedFiles,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.uploadedFiles) = $event)),
                    label: "Dateien auswählen",
                    accept: _ctx.allowedFileTypes
                  }, null, 8, ["modelValue", "accept"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.uploadFiles,
                    disabled: !_ctx.uploadedFiles.length
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Hochladen")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "my-4" }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_alert, {
                border: "left",
                icon: "mdi-folder-outline",
                prominent: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentFolderName || "Root-Verzeichnis"), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_divider, { class: "my-4" }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_ctx.currentFolderId)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { onClick: _ctx.goBack }, {
                    default: _withCtx(() => [
                      _createTextVNode("Zurück")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.filteredFolders.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredFolders, (folder) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  key: folder.id,
                  cols: "12",
                  sm: "2",
                  md: "2",
                  lg: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      class: "folder-card",
                      onDblclick: ($event: any) => (_ctx.navigateToFolder(folder.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_text, { class: "d-flex flex-column align-center justify-center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, {
                              size: "80",
                              class: "folder-icon"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-folder")
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_1, _toDisplayString(folder.name), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onDblclick"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFiles, (file) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              key: file.id,
              cols: "12",
              sm: "2",
              md: "2",
              lg: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, { class: "file-card" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.isImage(file.path))
                        ? (_openBlock(), _createBlock(_component_v_img, {
                            key: 0,
                            src: file.path,
                            class: "file-image"
                          }, null, 8, ["src"]))
                        : (_openBlock(), _createBlock(_component_v_icon, {
                            key: 1,
                            size: "80",
                            class: "file-icon"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-file")
                            ]),
                            _: 1
                          }))
                    ]),
                    _createVNode(_component_v_card_title, { class: "truncate" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(file.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Typ: " + _toDisplayString(_ctx.getFileType(file.name)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatFileSize(file.size)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          icon: "",
                          onClick: ($event: any) => (_ctx.downloadFile(file.path))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-download")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          onClick: ($event: any) => (_ctx.copyLink(file.path))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-content-copy")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        (_ctx.canDelete)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              icon: "",
                              color: "error",
                              onClick: ($event: any) => (_ctx.deleteFile(file.id))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-delete")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showFolderDialog,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showFolderDialog) = $event)),
        "max-width": "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Neuen Ordner erstellen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Ordnername",
                    modelValue: _ctx.newFolderName,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newFolderName) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    text: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showFolderDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, { onClick: _ctx.createFolder }, {
                    default: _withCtx(() => [
                      _createTextVNode("Erstellen")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}