<template>
	<error-snackbar v-model:snackbar="errorSnackbar" />
	<v-banner color="default" icon="mdi-alert-decagram" lines="one">
		<v-banner-text>
			<MarqueeAddon>
				{{ marqueeText }}
			</MarqueeAddon>
		</v-banner-text>
	</v-banner>
	<v-container class="dark-theme">
		<v-row>
			<!-- Linke Spalte mit Berichten -->
			<v-col cols="12" md="4" lg="2">
				<v-row>
					<v-col cols="12" v-for="report in reportCards" :key="report.title">
						<v-card class="pa-4 text-center report-card d-flex flex-column">
							<v-icon size="x-large" :color="report.color">{{ report.icon }}</v-icon>
							<h2 class="text-h5 mt-2 white--text">{{ report.count }}</h2>
							<p class="mt-1 white--text">{{ report.title }}</p>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			
			<!-- Mittlere Spalte mit flexiblen Inhalten -->
			<v-col cols="12" md="8" lg="6">
				<v-row>
					<v-col cols="6" mb="2">
						<v-card class="pa-4">
							<v-card-title class="text-h6 white--text">Willkommen, {{ username }}</v-card-title>
							<v-card-text class="white--text">Letzter Login: {{ lastLogin }}</v-card-text>
							<v-card-text class="white--text"></v-card-text>
						</v-card>
					</v-col>
					<v-col cols="6" mb="2">
						<v-card>
							<v-card-title class="text-h6 white--text">Aktueller Urlaube</v-card-title>
							<v-card-text>
								<v-simple-table>
									<template v-slot:default>
										<tbody>
											<tr v-for="vacation in currentVacations" :key="vacation.id">
												<td class="white--text">Vom: <b>{{ formatDateToDDMMYYYY(vacation.startDate) }}</b> bis <b>{{ formatDateToDDMMYYYY(vacation.endDate) }}</b>; Grund: <b>{{ vacation.reason }}</b></td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
							<v-card-actions>
								<v-btn prepend-icon="mdi-calendar-plus" variant="tonal" @click="openNewVacationDialog()">
									Urlaub eintragen
								</v-btn>
								<v-btn class="ml-2" v-if="currentVacations.length > 0" density="comfortable" icon="mdi-calendar-remove-outline" variant="tonal" @click="stopVacation()"></v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12" mb="4">
						<v-card class="pa-4">
							<div>Angemeldete Benutzer</div>
							<v-card-text>
								<v-chip v-for="user in activeUsers" :key="user" color="green" class="mr-2">{{ user.name }}</v-chip>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" mb="4" v-if="suspendedUsers.length > 0">
						<v-card class="pa-4">
							<div>Suspendierte Benutzer</div>
							<v-card-text>
								<v-chip v-for="user in suspendedUsers" :key="user" small color="red" class="mr-2">{{ user.name }}</v-chip>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" mb="4">
						<v-card class="pa-4">
							<div>Letzte 5 Nachrichten</div>
							<v-card-text>
								<v-data-table
									:headers="mailHeaders"
									:items="lastMails"
									class="elevation-1"
								>
									<template v-slot:[`item.is_read`]="{ item }">
										<span>{{ item.is_read == 1 ? "🟢" : "🔴" }}</span>
									</template>
									<template v-slot:[`item.sender_name`]="{ item }">
										<span>{{ item.sender_name }}</span>
									</template>
									<template v-slot:[`item.title`]="{ item }">
										<span>{{ item.title }}</span>
									</template>
									<template v-slot:[`item.created_at`]="{ item }">
										<span>{{ item.created_at }}</span>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			
			<!-- Rechte Spalte mit Wetter -->
			<v-col cols="12" md="4" lg="4">
				<v-card class="mx-auto weather-card" style="margin-top: 0px !important;">
					<v-card-item title="San Andreas">
						<template v-slot:subtitle>
							<v-icon class="me-1 pb-1" color="error" icon="mdi-alert" size="18"></v-icon>
							{{ currentWeather.warning }}
						</template>
					</v-card-item>
					<v-card-text class="py-0">
						<v-row align="center" no-gutters>
							<v-col class="text-h2" cols="6">
								{{ currentWeather.min_temp }}&deg;C - {{ currentWeather.max_temp }}&deg;C
							</v-col>
							<v-col class="text-right" cols="6">
								<v-icon :color="getWeatherIconColor(currentWeather.icon)" :icon="currentWeather.icon" size="88"></v-icon>
							</v-col>
						</v-row>
					</v-card-text>
					<div class="d-flex py-3 justify-space-between">
						<v-list-item density="compact" prepend-icon="mdi-weather-windy">
							<v-list-item-subtitle>{{ currentWeather.wind_speed }} km/h</v-list-item-subtitle>
						</v-list-item>
						<v-list-item density="compact" prepend-icon="mdi-weather-pouring">
							<v-list-item-subtitle>{{ currentWeather.humidity }}%</v-list-item-subtitle>
						</v-list-item>
					</div>
					<v-expand-transition>
						<div v-if="expand">
							<div class="py-2">
								<v-slider
									v-model="time"
									:max="6"
									:step="1"
									:ticks="labels"
									class="mx-4"
									color="primary"
									density="compact"
									show-ticks="always"
									thumb-size="10"
									hide-details
								></v-slider>
							</div>
							<v-list class="bg-transparent">
								<v-list-item v-for="item in forecast" :key="item.day" :append-icon="item.icon" :subtitle="item.temp" :title="item.day">
								</v-list-item>
							</v-list>
						</div>
					</v-expand-transition>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn color="primary" @click="expand = !expand">{{ !expand ? 'Full Report' : 'Hide Report' }}</v-btn>
					</v-card-actions>
				</v-card>
				<v-card width="100%" class=" mt-5">
					<v-img
						v-if="Events7Days.length > 0"
						height="157"
						src="../assets/calendarOverviewTop400.png"
						cover
						class="text-white"
					>
					<span class="text-h6 pl-3 ">Termine der nächsten 7 Tage</span>
					</v-img>
					<v-img
						v-else
						height="157"
						src="../assets/calendarOverviewTop400Free_2.png"
						cover
						class="text-white"
					>
					<span class="text-h6 pl-3">Termine der nächsten 7 Tage</span>
					</v-img>
					<v-card-text>
						<div class="font-weight-bold ms-1 mb-2">Nächste 7 Tage</div>
						<v-timeline density="compact" align="start" v-if="Events7Days.length > 0">
							<v-timeline-item v-for="event in Events7Days" :key="event.start" :dot-color="event.color" size="x-small">
								<div class="mb-4">
									<div class="font-weight-normal">
										<strong>{{ isUserAssigned(event.assigned_to) }} <v-chip v-if="Object.keys(event.assigned_to).length > 1" size="x-small" class="ma-2">{{ '+' + (Object.keys(event.assigned_to).length - 1) }}</v-chip></strong> @{{ formatDateCalPreview(event.start) }} - {{ formatDateToDDMMYYYY(formatDate(event.start, false, true)) }}
										<v-tooltip activator="parent" location="top" v-if="Object.keys(event.assigned_to).length > 1">
											<v-chip variant="text" v-for="user in event.assigned_to" :key="user.user_id">{{ user.username }}</v-chip>
										</v-tooltip>
									</div>
									<div>{{ event.title }}</div>
								</div>
							</v-timeline-item>
						</v-timeline>
						<v-timeline density="compact" align="start" v-else>
							<v-timeline-item dot-color="#00000" size="x-small">
								<div class="mb-4">
									<div class="font-weight-normal">
										<strong>In den nächsten 7 Tagen sind keine Termine. ☀️🏖️</strong>
									</div>
								</div>
							</v-timeline-item>
						</v-timeline>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<v-dialog v-model="newVacationDialog" persistent max-width="800">
        <v-card>
        <v-card-title>
            <span class="text-h5">Urlaub hinzufügen</span>
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="4">
                        <v-select
                            :items="['Urlaub', 'Krank', 'Sporadisch im Dienst', 'Sonstiges']"
                            label="Art des Urlaubs"
                            v-model="newVacation.type"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="newVacation.start" label="Von" outlined type="date" required/>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="newVacation.end" label="Bis" outlined type="date" required/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <v-text-field v-model="newVacation.other" label="Sonstiges" outlined required/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="newVacationDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="saveVacation">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import api from "@/api";
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import MarqueeAddon from "@/components/MarqueeAddon.vue";
import { EventOverview } from "@/types/Calendar";

export default defineComponent({
	components: {
		MarqueeAddon,
	},
	setup() {
		const errorSnackbar = ref<any>({ visible: false, message: "" });
		const store = useStore();
		const username = computed(() => store.state.user?.name || '');
		const lastLogin = computed(() => store.state.user?.last_login || '');
		const suspendedUsers = ref<any[]>([]);
		const activeUsers = ref<any[]>([]);
		const marqueeText = ref<any[]>([]);
		const Events7Days = ref<EventOverview[]>([]);
		const openReports = ref<number>(0); // Beispiel: 5 offene Berichte
		const openInvoice = ref<number>(0);
		const openMeetings = ref<number>(0);
		const actualVacations = ref<number>(0); 
		const lastMails = ref<any[]>([]);
		const vacationsData = ref<any[]>([]);
		const currentVacations = computed(() => vacationsData.value.map(vacation => ({
			id: vacation.id,
			user: vacation.employee,
			startDate: vacation.start,
			endDate: vacation.end,
			reason: vacation.reason,
		})));

		const mailHeaders = [
			{ title: "", value: "is_read" },
			{ title: "Absender", value: "sender_name" },
			{ title: "Betreff", value: "title" },
			{ title: "Datum", value: "created_at" },
		];
		

		const reportCards = computed(() => [
			{
				title: 'Offene Berichte',
				count: openReports.value,
				icon: 'mdi-file-document',
				color: 'info',
			},
			{
				title: "Mitarbeiter im Urlaub",
				count: actualVacations.value,
				icon: "mdi-calendar-account",
				color: "info",
			},
			{
				title: 'Offene Rechnungen',
				count: openInvoice.value,
				icon: 'mdi-currency-usd',
				color: 'info',
			},
			{
				title: 'Anstehende Termine',
				count: openMeetings.value,
				icon: 'mdi-calendar-clock',
				color: 'info',
			},
		]);

		const expand = ref(false);
		const time = ref(0);
		const labels = ref([0, 1, 2, 3, 4, 5, 6]);
		const currentWeather = ref<any>({});
		const forecast = ref<any[]>([]);

		async function fetchWeather() {
			try {
				const response = await api.get('weather/?action=getWeather');
				const weatherData = response.data;
				if (weatherData.length > 0) {
					currentWeather.value = weatherData[0];
					forecast.value = weatherData.slice(1).map((day: any) => ({
						day: day.day + " " + formatDateToDDMMYYYY(day.date),
						temp: `${day.min_temp}°C - ${day.max_temp}°C | Luftfeuchtigkeit: ${day.humidity}%`,
						icon: day.icon,
					}));
				}
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		}

		async function fetchEvents() {
            try {
                const next7days = await api.get('calendar/?action=getEvents7DaysTodayInc');
                Events7Days.value = next7days.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents

            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

		function formatDateToDDMMYYYY(datePart: any) {
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        }

        function isUserAssigned(users: any){
            if(Object.keys(users).length == 0) return "Alle";
            if(users.some(user => user.user_id === parseInt(store.state.user.id))) return "You"
        }

        function formatDateCalPreview(dateInput: string | Date) {
            const date = new Date(dateInput);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        }

		function formatDate(dateInput: string | Date, isStart: boolean, onlyDate = false) {
            const date = new Date(dateInput);
            if (!isStart) {
                date.setHours(date.getHours() + 1);
            }

            const timezoneOffset = date.getTimezoneOffset() * 60000;
            const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);

            const [datePart, timePart] = localISOTime.split('T');
            let [hour, minute] = timePart.split(':');

            // Runde die Minuten auf die nächsten 15-Minuten-Schritte
            const roundedMinute = Math.ceil(parseInt(minute) / 15) * 15;
            if (roundedMinute === 60) {
                // Wenn die gerundeten Minuten 60 sind, erhöhe die Stunden um 1 und setze die Minuten auf 0
                hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                minute = "00";
            } else {
                minute = roundedMinute.toString().padStart(2, "0");
            }

            return onlyDate ? datePart : `${datePart}T${hour}:${minute}`;
        }

		onMounted(async () => {
			await fetchDashboardData();
			await fetchWeather();
		});

		async function fetchDashboardData() {
			try {
				const marqueeTextResponse = await api.get('user/?action=getMarqueeText')
				marqueeText.value = marqueeTextResponse.data.marquee_dashboard;
				const lastMailsResponse = await api.get('user/?action=getLast5Messages')
				lastMails.value = lastMailsResponse.data;
				const vacations = await api.get('user/?action=getOwnVacations');
				vacationsData.value = vacations.data;
				const reports = await api.get('user/?action=getOwnReportCount');
				openReports.value = parseInt(reports.data.report_count, 10);
				const activeUsersResponse = await api.get("user/?action=getActiveUsers");
				activeUsers.value = activeUsersResponse.data;
				const suspendedUsersResponse = await api.get("user/?action=getSuspendedUsers");
				suspendedUsers.value = suspendedUsersResponse.data;
				const actualVacationsResponse = await api.get("user/?action=getVacationsCount");
				actualVacations.value = actualVacationsResponse.data.vacation_count;
				const openInvoiceResponse = await api.get("user/?action=getInvoiceCount");
				openInvoice.value = openInvoiceResponse.data.invoice_count;
				const openMeetingResponse = await api.get("user/?action=getMeetingCount");
				openMeetings.value = openMeetingResponse.data.meeting_count;
				await fetchEvents()
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		}

		function getWeatherIconColor(icon: string) {
			switch (icon) {
				case 'mdi-weather-sunny':
					return 'yellow';
				case 'mdi-weather-partly-cloudy':
					return 'gray';
				case 'mdi-weather-rainy':
					return 'blue';
				case 'mdi-weather-cloudy':
					return 'gray';
				case 'mdi-weather-lightning-rainy':
					return 'purple';
				default:
					return 'white';
			}
		}

		const newVacationDialog = ref(false);
		function openNewVacationDialog() {
            newVacationDialog.value = true;
        }

		async function stopVacation(){
            try{
                await api.post('user/?action=stopVacation', {
                    newdate: new Date(),
                });
				const vacations = await api.get('user/?action=getOwnVacations');
				vacationsData.value = vacations.data;
            } catch (err){
                console.error('Fehler beim Löschen des Urlaubs:', err);
            }
        }

		const newVacation = ref({ type: '', start: '', end: '', other: '' });

		async function saveVacation() {
			try {
				await api.post('user/?action=addVacation', {
							start: newVacation.value.start,
							end: newVacation.value.end,
							type: newVacation.value.type,
							other: newVacation.value.other,
						});
				newVacationDialog.value = false;
				newVacation.value = { type: '', start: '', end: '', other: '' };
				const vacations = await api.get('user/?action=getOwnVacations');
				vacationsData.value = vacations.data;
			} catch (err) {
				console.error('Fehler beim Speichern des Urlaubs:', err);
			}
		}

		return {
			username,
			lastLogin,
			suspendedUsers,
			activeUsers,
			openReports,
			lastMails,
			currentVacations,
			reportCards,
			errorSnackbar,
			expand,
			time,
			labels,
			currentWeather,
			forecast,
			Events7Days,
			formatDateToDDMMYYYY,
			isUserAssigned,
			formatDateCalPreview,
			formatDate,
			mailHeaders,
			marqueeText,
			getWeatherIconColor,
			openNewVacationDialog,
			stopVacation,
			saveVacation,
			newVacationDialog,
			newVacation 
		};
	},
});
</script>
<style scoped>
.text-center {
	text-align: center;
}

.pa-4 {
	padding: 16px;
}

.mb-4 {
	margin-bottom: 16px;
}

.report-card {
	background-color: #212121;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 150px;
}

.report-card v-icon {
	font-size: 48px;
	margin-bottom: 8px;
}

.weather-card {
	margin-top: 16px;
}

.white--text {
	color: white !important;
}
</style>
<style lang="css" scoped>
.text-info {
  color: var(--primary) !important;
}
</style>