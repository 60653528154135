import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (!_ctx.addApartmentDialog && !_ctx.editedApartment && !_ctx.selectedApartment && _ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _ctx.openAddApartmentDialog,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Neue Wohnung")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.addApartmentDialog && !_ctx.editedApartment && !_ctx.selectedApartment)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            headers: _ctx.apartmentHeaders,
            items: _ctx.filteredApartments,
            class: "elevation-1 mt-12",
            search: _ctx.search,
            "items-per-page": 10
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Wohnungsverzeichnis")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            [`item.name`]: _withCtx(({ item }) => [
              _createElementVNode("span", {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openViewApartmentDialog(item)),
                style: {"cursor":"pointer"}
              }, _toDisplayString(item.name), 9, _hoisted_1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openEditApartmentDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDeleteApartmentDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["headers", "items", "search"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteApartmentDialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deleteApartmentDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Fahrzeug löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie die Wohnung wirklich löschen? ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteApartmentDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteApartment(_ctx.selectedApartment)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.addApartmentComponent), {
        modelValue: _ctx.addApartmentDialog,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addApartmentDialog) = $event)),
        addApartmentDialog: _ctx.addApartmentDialog,
        onApartmentAdded: _ctx.onApartmentAdded,
        onClose: _ctx.addApartmentClose
      }, null, 40, ["modelValue", "addApartmentDialog", "onApartmentAdded", "onClose"])),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editApartmentComponent), {
        modelValue: _ctx.editApartmentDialog,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editApartmentDialog) = $event)),
        apartmentToEdit: _ctx.editedApartment,
        onApartmentUpdated: _ctx.onApartmentUpdated,
        onClose: _ctx.editApartmentClose
      }, null, 40, ["modelValue", "apartmentToEdit", "onApartmentUpdated", "onClose"])),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewApartmentComponent), {
        modelValue: _ctx.viewApartmentDialog,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.viewApartmentDialog) = $event)),
        apartmentToView: _ctx.selectedApartment,
        onClose: _ctx.viewApartmentClose
      }, null, 40, ["modelValue", "apartmentToView", "onClose"])),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}