<template>
	<!-- Neue Person hinzufügen Formular -->
	<v-card v-if="addPersonDialog">
		<v-card-title>
			Neue Person hinzufügen
		</v-card-title>
		<!-- Tabs für verschiedene Formularinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Personeninfos</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Personeninformationen -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="12">
							<v-row>
								<!-- Allgemeine Felder -->
								<v-col cols="2">
									<v-select
										label="Geschlecht"
										v-model="person.gender"
										:items="genderOptions"
										item-value="value"
										item-title="text"
										required
										:rules="[requiredRule]"
									></v-select>
								</v-col>
								<v-col cols="2">
									<v-text-field
										label="Titel"
										v-model="person.title"
									></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field
										label="Vorname"
										v-model="person.firstname"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field
										label="Nachname"
										v-model="person.lastname"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsdatum"
										v-model="person.birthday"
										type="date"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsort"
										v-model="person.birthplace"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Telefonnummer"
										v-model="person.phonenumber"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Personalausweisnummer"
										v-model="person.idcard"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Adresse"
										v-model="person.address"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Bankkonto"
										v-model="person.bankaccount"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="E-Mail"
										v-model="person.mail"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Lizenzen"
										v-model="person.licenses"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Einreise"
										v-model="person.entry"
										type="datetime-local"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										v-model="person.wanted"
										label="Gesucht"
									></v-checkbox>
								</v-col>

								<!-- Authority-spezifische Felder -->
								<template v-if="authority === 'fire'">
									<v-col cols="12">
										<v-text-field
											label="Feuerwehr-Ausbildung"
											v-model="person.fireRelatedTraining"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Feuerwehrabteilung"
											v-model="person.fireDepartment"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'police' || authority === 'test'">
									<v-col cols="12">
										<v-text-field
											label="Letzter bekannter Ort"
											v-model="person.lastKnownLocation"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'medic'">
									<v-col cols="12">
										<v-text-field
											label="Medizinische Geschichte"
											v-model="person.medicalHistory"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Allergien"
											v-model="person.allergies"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Notfallkontakt"
											v-model="person.emergencyContact"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'justice'">
									<v-col cols="12">
										<v-text-field
											label="Fallhistorie"
											v-model="person.caseHistory"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Rechtsstatus"
											v-model="person.legalStatus"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Anwalt Name"
											v-model="person.lawyerName"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'statepark'">
									<v-col cols="12">
										<v-text-field
											label="Park Eintrittsstatus"
											v-model="person.parkEntryStatus"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Verstöße"
											v-model="person.violations"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Ranger Notizen"
											v-model="person.rangerNotes"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'casa'">
									<v-col cols="12">
										<v-text-field
											label="Immobilienstatus"
											v-model="person.propertyStatus"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Mietverlauf"
											v-model="person.rentalHistory"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Vermieter Name"
											v-model="person.landlordName"
										></v-text-field>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="person.text"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Abbrechen
			</v-btn>
			<v-btn
				color="blue darken-1"
				text
				@click="addNewPerson"
				:disabled="!isFormValid(person)"
			>
				Hinzufügen
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import EditorClass from "@/ckeditor/ckeditor.js";
import { defineComponent, ref, computed } from "vue";
import { PersonFile } from "@/types/Person";
import api from "@/api";
import { useStore } from "vuex";

export default defineComponent({
	name: "AddPersonFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		newPersonDialog: {
			type: Boolean,
			required: true,
		},
		addPersonDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "personAdded"],
	setup(props, { emit }) {
		const store = useStore();
		const authority = store.state.user.authority;

		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const person = ref<PersonFile>({
			id: 0,
			firstname: "",
			lastname: "",
			birthplace: "",
			birthday: "",
			gender: "",
			title: "",
			phonenumber: "",
			address: "",
			idcard: "",
			bankaccount: "",
			mail: "",
			entry: "",
			licenses: "",
			wanted: false,
			text: "",
			is_deleted: false,
			...getAuthoritySpecificFields(authority),
		} as PersonFile);

		const genderOptions = [
			{ text: "Männlich", value: "male" },
			{ text: "Weiblich", value: "female" },
			{ text: "Divers", value: "diverse" },
		];

		const currentTab = ref(1); 

		// Funktion, um authority-spezifische Felder festzulegen
		function getAuthoritySpecificFields(authority: string) {
			switch (authority) {
				case "fire":
				case "fireguard":
					return { fireRelatedTraining: "", fireDepartment: "" };
				case "police":
				case "test":
					return { lastKnownLocation: "" };
				case "medic":
					return { medicalHistory: "", allergies: "", emergencyContact: "" };
				case "justice":
					return { caseHistory: "", legalStatus: "", lawyerName: "" };
				case "statepark":
					return { parkEntryStatus: "", violations: "", rangerNotes: "" };
				case "casa":
					return { propertyStatus: "", rentalHistory: "", landlordName: "" };
				default:
					return {};
			}
		}

		const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

		const addNewPerson = async () => {
			try {
				await api.post("personfile/?action=addPerson", person.value);
				emit("personAdded");
				closeDialog();
			} catch (error) {
				console.error("Fehler beim Hinzufügen der Person:", error);
			}
		};

		const isFormValid = (form: PersonFile) =>
			form.firstname !== "" && form.lastname !== "" && form.birthplace !== "" && form.birthday !== "";

		const closeDialog = () => {
			dialog.value = false;
		};

		return {
			dialog,
			person,
			currentTab,
			requiredRule,
			addNewPerson,
			isFormValid,
			closeDialog,
			EditorClass,
			genderOptions,
			authority,
		};
	},
});
</script>
