import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_AddInvoiceFile = _resolveComponent("AddInvoiceFile")!
  const _component_EditInvoiceFile = _resolveComponent("EditInvoiceFile")!
  const _component_ViewInvoiceFile = _resolveComponent("ViewInvoiceFile")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (
				!_ctx.showAddInvoice &&
				!_ctx.editInvoiceDialog &&
				!_ctx.viewInvoiceDialog &&
				_ctx.canEdit
			)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _ctx.openAddInvoiceForm,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Neue Rechnung ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.showAddInvoice && !_ctx.editInvoiceDialog && !_ctx.viewInvoiceDialog)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            headers: _ctx.invoiceHeaders,
            items: _ctx.filteredInvoices,
            class: "elevation-1 mt-12",
            search: _ctx.search,
            "items-per-page": 25
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Rechnungsverwaltung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            [`item.outgoing`]: _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_chip, {
                  color: item.outgoing ? 'green' : 'red',
                  text: item.outgoing ? 'Einnahme' : 'Ausgabe',
                  class: "text-uppercase",
                  size: "small",
                  label: ""
                }, null, 8, ["color", "text"])
              ])
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openEditInvoiceDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openViewInvoiceDialog(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-eye")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.deleteInvoice(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["headers", "items", "search"]))
        : _createCommentVNode("", true),
      (_ctx.showAddInvoice)
        ? (_openBlock(), _createBlock(_component_AddInvoiceFile, {
            key: 2,
            onInvoiceAdded: _ctx.handleInvoiceAdded,
            onCloseForm: _ctx.closeAddInvoiceForm
          }, null, 8, ["onInvoiceAdded", "onCloseForm"]))
        : _createCommentVNode("", true),
      (_ctx.editInvoiceDialog)
        ? (_openBlock(), _createBlock(_component_EditInvoiceFile, {
            key: 3,
            invoiceToEdit: _ctx.selectedInvoice,
            onInvoiceUpdated: _ctx.handleInvoiceUpdated,
            onCloseForm: _ctx.closeEditInvoiceDialog
          }, null, 8, ["invoiceToEdit", "onInvoiceUpdated", "onCloseForm"]))
        : _createCommentVNode("", true),
      (_ctx.viewInvoiceDialog)
        ? (_openBlock(), _createBlock(_component_ViewInvoiceFile, {
            key: 4,
            invoiceToView: _ctx.selectedInvoice,
            onCloseForm: _ctx.closeViewInvoiceDialog
          }, null, 8, ["invoiceToView", "onCloseForm"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.errorSnackbar.visible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errorSnackbar.visible) = $event)),
        color: 'red'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.errorSnackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}