<template>
	<v-container>
		<v-btn @click="openAddVehicleDialog" class="float-right" v-if="!editedVehicle && !addVehicleDialog && !viewVehicleDialog && canEdit"
			>Neues Fahrzeug</v-btn
		>

		<v-data-table
			:headers="vehicleHeaders"
			:items="filteredVehicles"
			class="elevation-1 mt-12"
			:search="search"
			:items-per-page="10"
			v-if="!editedVehicle && !addVehicleDialog && !viewVehicleDialog"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Fahrzeugverwaltung</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						label="Suche"
						class="mx-4 mt-3"
						variant="underlined"
					/>
				</v-toolbar>
			</template>
			<template v-slot:[`item.numberplate`]="{ item }">
				<span
					size="small"
					class="me-2"
					@click="openViewVehicleDialog(item)"
					style="cursor: pointer"
				>
					{{ item.numberplate }}
				</span>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					size="small"
					class="me-2"
					@click="openEditVehicleDialog(item)"
					v-if="canEdit"
					>mdi-pencil</v-icon
				>
				<v-icon
					size="small"
					class="me-2"
					@click="openDeleteVehicleDialog(item)"
					v-if="canDelete"
					>mdi-delete</v-icon
				>
			</template>
		</v-data-table>

		<!-- AddVehicleFile Component -->
		<component
			:is="addVehicleComponent"
			v-model="addVehicleDialog"
			:addVehicleDialog="addVehicleDialog"
			@vehicleAdded="onVehicleAdded"
		/>

		<!-- EditVehicleFile Component -->
		<component
			:is="editVehicleComponent"
			v-model="editVehicleDialog"
			:vehicleToEdit="editedVehicle"
			@vehicleUpdated="onVehicleUpdated"
			@close="editVehicleClose"
		/>

		<!-- ViewVehicleFile Component -->
		<component
			:is="viewVehicleComponent"
			v-if="selectedVehicle"
			v-model="viewVehicleDialog"
			:vehicleToView="selectedVehicle"
			@close="viewVehicleClose"
		/>

		<!-- Delete Vehicle Dialog -->
		<v-dialog v-model="deleteVehicleDialog" max-width="400" persistent>
			<v-card>
				<v-card-title class="headline">Fahrzeug löschen</v-card-title>
				<v-card-text>
					Möchten Sie das Fahrzeug mit dem Kennzeichen "{{
						selectedVehicle?.numberplate
					}}" wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteVehicleDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteVehicle(selectedVehicle)"
						>Löschen</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Snackbar -->
		<v-snackbar
			v-model="snackbar.value"
			:color="snackbar.color"
			:timeout="4000"
		>
			{{ snackbar.message }}
		</v-snackbar>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import api from "@/api";
import { VehicleFile } from "@/types/Vehicle";
import { useStore } from "vuex";
// Import components for vehicles
import AuthorityAddVehicleFile from "@/components/VehicleFile/Authority/Add.vue";
import AuthorityEditVehicleFile from "@/components/VehicleFile/Authority/Edit.vue";
import AuthorityViewVehicleFile from "@/components/VehicleFile/Authority/View.vue";
import { useRoute } from "vue-router";

export default defineComponent({
	components: {
		AuthorityAddVehicleFile,
		AuthorityEditVehicleFile,
		AuthorityViewVehicleFile,
	},
	setup() {
		const store = useStore();
		const vehicleHeaders = [
			{ title: "Kennzeichen", key: "numberplate", sortable: true },
			{ title: "Fahrzeugmarke", key: "brand", sortable: true },
			{ title: "Fahrzeugmodell", key: "model", sortable: true },
			{ title: "Farbe", key: "color", sortable: true },
			{ title: "Aktionen", key: "actions", sortable: false },
		];


		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		// Computed properties to return authority-specific components for adding a vehicle
		const addVehicleComponent = computed(() => {
			return AuthorityAddVehicleFile; // Fallback component
		});

		// Computed properties to return authority-specific components for editing a vehicle
		const editVehicleComponent = computed(() => {
			return AuthorityEditVehicleFile; // Fallback component
		});

		// Computed properties to return authority-specific components for viewing a vehicle
		const viewVehicleComponent = computed(() => {
			return AuthorityViewVehicleFile; // Fallback component
		});

		const vehicles = ref<VehicleFile[]>([]);
		const addVehicleDialog = ref(false);
		const editVehicleDialog = ref(false);
		const viewVehicleDialog = ref(false);
		const deleteVehicleDialog = ref(false);
		const editedVehicle = ref<VehicleFile | null>(null);
		const selectedVehicle = ref<VehicleFile | null>(null);
		const search = ref("");
		const snackbar = ref({
			value: false,
			color: "",
			message: "",
		});

		const fetchVehicles = async () => {
			try {
				const response = await api.get(
					"vehiclefile/?action=getVehicles"
				);
				vehicles.value = response.data.map((vehicles) => ({
					...vehicles,
					stolen: vehicles.stolen == "1" ? true : false,
					wanted: vehicles.wanted == "1" ? true : false,
				}));
			} catch (error) {
				snackbar.value.message =
					error.response?.data?.error ||
					"Fehler beim Laden der Fahrzeuge";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		const filteredVehicles = computed(() => {
			const searchTerm = search.value.trim().toLowerCase();
			return vehicles.value.filter((vehicle) => {
				const searchableContent =
					`${vehicle.numberplate} ${vehicle.brand} ${vehicle.model} ${vehicle.color}`.toLowerCase();
				return searchTerm
					? searchableContent.includes(searchTerm)
					: true;
			});
		});

		const openAddVehicleDialog = () => {
			addVehicleDialog.value = true;
		};

		const onVehicleAdded = async () => {
			await fetchVehicles();
		};

		const openEditVehicleDialog = (vehicle: VehicleFile) => {
			editedVehicle.value = { ...vehicle };
			editVehicleDialog.value = true;
		};

		const editVehicleClose = (vehicle: VehicleFile) => {
			editedVehicle.value = null;
			editVehicleDialog.value = false;
		};

		const viewVehicleClose = (vehicle: VehicleFile) => {
			selectedVehicle.value = null;
			viewVehicleDialog.value = false;
		};

		const onVehicleUpdated = async () => {
			await fetchVehicles();
		};

		const openViewVehicleDialog = (vehicle: VehicleFile) => {
			// Überprüfe, ob vehicle gültig ist
			if (vehicle) {
				selectedVehicle.value = { ...vehicle };
				viewVehicleDialog.value = true;
			} else {
				console.error("Kein gültiges Fahrzeug gefunden:", vehicle);
			}
		};

		const openDeleteVehicleDialog = (vehicle: VehicleFile) => {
			selectedVehicle.value = vehicle;
			deleteVehicleDialog.value = true;
		};

		const deleteVehicle = async (vehicle: VehicleFile) => {
			try {
				await api.post("vehiclefile/?action=deleteVehicle", {
					id: vehicle.id,
				});
				await fetchVehicles();
				deleteVehicleDialog.value = false;
				snackbar.value.message = "Fahrzeug erfolgreich gelöscht";
				snackbar.value.color = "success";
				snackbar.value.value = true;
			} catch (error) {
				snackbar.value.message =
					error.response?.data?.error ||
					"Fehler beim Löschen des Fahrzeugs";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		onMounted(async () => {
			await fetchVehicles();
		});

		return {
			vehicleHeaders,
			vehicles,
			filteredVehicles,
			addVehicleDialog,
			editVehicleDialog,
			viewVehicleDialog,
			deleteVehicleDialog,
			editedVehicle,
			selectedVehicle,
			search,
			snackbar,
			openAddVehicleDialog,
			openEditVehicleDialog,
			openViewVehicleDialog,
			openDeleteVehicleDialog,
			deleteVehicle,
			onVehicleAdded,
			onVehicleUpdated,
			addVehicleComponent,
			editVehicleComponent,
			viewVehicleComponent,
			editVehicleClose,
			viewVehicleClose,
			canEdit,
			canDelete
		};
	},
});
</script>
