<template>
	<v-card>
		<v-card-title>
			Rechnung anzeigen
			<v-spacer></v-spacer>
			<v-btn @click="copyInvoiceText"> Rechnungsmail kopieren </v-btn>
		</v-card-title>
		<v-card-text>
			<!-- Status -->
			<v-row justify="end" class="d-flex align-center">
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_delivered"
						label="Geliefert?"
						readonly
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_sent"
						label="Rechnung versendet?"
						readonly
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_paid"
						label="Bezahlt?"
						readonly
					></v-switch>
				</v-col>
			</v-row>

			<!-- Kunde, Telefonnummer, E-Mail und Konto -->
			<v-row>
				<v-col cols="12">
					<v-text-field
						v-model="invoice.title"
						label="Betreff"
						readonly
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3">
					<v-text-field
						v-model="invoice.customer"
						label="Kunde"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
						v-model="invoice.phone_number"
						label="Telefonnummer"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
						v-model="invoice.email"
						label="E-Mail"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
						v-model="invoice.account"
						label="Konto"
						readonly
					></v-text-field>
				</v-col>
			</v-row>

			<!-- Beschreibung -->
			<v-row>
				<v-col cols="12">
					<v-textarea
						v-model="invoice.description"
						label="Beschreibung"
						readonly
					></v-textarea>
				</v-col>
			</v-row>

			<!-- Items anzeigen -->
			<v-row>
				<v-col cols="12">
					<h4>Items</h4>
				</v-col>
			</v-row>
			<v-row
				v-for="(item, index) in invoice.items"
				:key="index"
				class="mt-2"
			>
				<v-col cols="3">
					<v-text-field
						v-model="item.item_name"
						label="Gegenstand"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="4">
					<v-text-field
						v-model="item.description"
						label="Beschreibung"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="item.price"
						label="Preis"
						type="number"
						readonly
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="item.quantity"
						label="Menge"
						type="number"
						readonly
					></v-text-field>
				</v-col>
			</v-row>

			<!-- Rabatt und Gesamtsumme -->
			<v-row class="mt-4">
				<v-col cols="6">
					<v-text-field
						v-model="invoice.discount"
						label="Rabatt"
						readonly
						class="text-right"
					></v-text-field>
				</v-col>
				<v-col cols="6" class="d-flex align-center justify-end">
					<div class="text-right">
						<span class="font-weight-bold"
							>Gesamtsumme (nach Rabatt):</span
						>
						<br />
						<span class="text-h6 text-success">{{
							formattedTotal
						}}</span>
					</div>
				</v-col>
			</v-row>

			<!-- Anhänge anzeigen -->
			<v-row>
				<v-col cols="12">
					<h4>Vorhandene Anhänge</h4>
					<v-row v-if="invoice.attachments.length > 0">
						<template
							v-for="(attachment, index) in invoice.attachments"
							:key="index"
						>
							<v-col cols="12" sm="2" md="2" lg="2">
								<v-card class="file-card">
									<div class="file-card-content">
										<!-- Vorschau für Bilder -->
										<v-img
											v-if="isImage(attachment)"
											:src="attachment"
											class="file-image"
										></v-img>
										<!-- Icon für andere Dateitypen -->
										<v-icon
											v-else
											size="80"
											class="file-icon"
											>mdi-file</v-icon
										>
									</div>
									<v-card-title class="truncate">{{
										getFileName(attachment)
									}}</v-card-title>
									<v-card-actions>
										<!-- Aktionen: Öffnen -->
										<v-btn
											icon
											@click="openAttachment(attachment)"
										>
											<v-icon>mdi-download</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</template>
					</v-row>
					<v-row v-else>
						<v-col cols="12">
							<p>Keine Anhänge vorhanden.</p>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>

		<!-- Aktionen -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text @click="closeForm">Schließen</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
	name: "ViewInvoiceFile",
	props: {
		modelValue: { type: Boolean, required: true },
		invoiceToView: { type: Object, required: true },
	},
	emits: ["closeForm"],
	setup(props, { emit }) {
		const invoice = ref({
			...props.invoiceToView,
			items: Array.isArray(props.invoiceToView.items)
				? props.invoiceToView.items
				: [],
			attachments: Array.isArray(props.invoiceToView.attachments)
				? props.invoiceToView.attachments
				: [],
			discount: props.invoiceToView.discount || 0,
		});

		if (typeof props.invoiceToView.attachments === "string") {
			try {
				invoice.value.attachments = JSON.parse(
					props.invoiceToView.attachments
				);
			} catch (e) {
				console.error("Fehler beim Parsen der Anhänge:", e);
				invoice.value.attachments = [];
			}
		}

		const calculateTotal = computed(() => {
			const subtotal = invoice.value.items.reduce((sum, item) => {
				return sum + item.price * item.quantity;
			}, 0);
			const discount = Number(invoice.value.discount) || 0;
			return subtotal - (parseFloat(invoice.value.discount) || 0);
		});

		const formattedTotal = computed(() => {
			return `$ ${calculateTotal.value.toFixed(2)}`;
		});

		const closeForm = () => {
			emit("closeForm");
		};

		const openAttachment = (attachment: string) => {
			window.open(attachment, "_blank");
		};

		const isImage = (path: string) =>
			/\.(jpg|jpeg|png|gif|bmp)$/i.test(path);

		const getFileName = (path: string): string => {
			return path.split("/").pop() || "Unbekannt";
		};

		const copyInvoiceText = async () => {
			const today = new Date();
			const date = today.toLocaleDateString("de-DE");
			const total = formattedTotal.value;

			const itemList = invoice.value.items
				.map(
					(item) =>
						`${item.item_name} | ${item.description} | ${
							item.quantity
						} | ${item.price.toFixed(2)}`
				)
				.join("\n");

			const discount = Number(invoice.value.discount).toFixed(2);

			const invoiceText = `
Sehr geehrte Damen und Herren,
anbei finden Sie die Rechnung für die bei Ihnen durchgeführte Dienstleistung.

________________________________________________________
Rechnungsdatum: ${date}

Folgende Dienstleistung wurde durchgeführt:

Gegenstand | Beschreibung | Menge | Preis
${itemList}

Rabatt: ${discount}
Insgesamt: ${total}

________________________________________________________

Wir bitte Sie, den Betrag innerhalb von 3 Tagen zu überweisen, sofern Sie die Rechnung noch nicht beglichen haben.
Bitte beachten Sie, den richtigen Verwendungszweck zu benutzen.

Kontoinhaber: FireGuard Solutions
Kontonummer: 15186374
Verwendungszweck: ${props.invoiceToView.title}
Rechnungsbetrag: ${total}
			`;

			if (navigator.clipboard) {
				try {
					await navigator.clipboard.writeText(invoiceText);
					console.log(
						"Text erfolgreich in die Zwischenablage kopiert"
					);
				} catch (err) {
					console.error("Fehler beim Kopieren des Textes:", err);
				}
			}
		};

		return {
			invoice,
			calculateTotal,
			formattedTotal,
			closeForm,
			openAttachment,
			isImage,
			getFileName,
			copyInvoiceText,
		};
	},
});
</script>

<style scoped>
.file-card {
	border-radius: 12px;
	height: 300px; /* Einheitliche Höhe für alle Karten */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
}

.file-card-content {
	height: 150px; /* Einheitliche Höhe für den Inhalt */
	display: flex;
	align-items: center;
	justify-content: center;
}

.file-image {
	object-fit: contain;
	max-height: 100%;
	max-width: 100%;
}

.file-icon {
	color: var(--primary);
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
</style>
