<template>
	<error-snackbar v-model:snackbar="errorSnackbar" />
	<v-container fluid>
		<v-row>
			<v-col cols="auto">
				<v-btn @click="openDocumentEditor(null, true)" v-if="canEdit"
					>Dokument hinzufügen</v-btn
				>
				<v-btn
					class="ml-5"
					@click="openAddCategorieDialog"
					v-if="canEdit"
					>Kategorie hinzufügen</v-btn
				>
				<v-btn
					class="ml-5"
					@click="showSortingModal = true"
					v-if="canEdit"
					>Kategorien sortieren</v-btn
				>
				<v-btn class="ml-5" @click="toggleViewMode"
					>Ansicht wechseln</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-text-field
					label="Suche"
					class="search-input"
					single-line
					hide-details
					clearable
					v-model="search"
				></v-text-field>
			</v-col>
		</v-row>
		<template v-if="viewMode === 'tiles'">
			<template v-for="category in filteredCategories" :key="category.id">
				<v-row>
					<v-col cols="12" sm="6" md="4" lg="3">
						<template
							v-if="
								editingField.type !== 'categoryName' ||
								editingField.id !==
									(category ? category.id : -1)
							"
						>
							<h2
								@dblclick="
									startEditingField(
										'categoryName',
										category ? category.id : -1
									)
								"
							>
								{{ category ? category.name : "" }}
							</h2>
						</template>
						<template v-else>
							<v-text-field
								v-model="editedValue"
								single-line
								@keydown.enter="
									updateField(category ? category.id : -1)
								"
								@keydown.esc="cancelEditingField"
								@blur="cancelEditingField"
								ref="editField"
								class="mt-5 mr-5"
							></v-text-field>
						</template>
					</v-col>
				</v-row>

				<v-row>
					<draggable
						v-model="category.documents"
						:group="{
							name: 'documents',
							pull: 'clone',
							put: false,
						}"
						tag="transition-group"
						:animation="150"
						@end="saveDocumentSorting(category.documents)"
					>
						<template v-slot:item="{ element }">
							<v-col
								:key="element.id"
								cols="12"
								sm="6"
								md="4"
								lg="3"
							>
								<v-tooltip activator="parent" location="top">
									{{ element.title }}
								</v-tooltip>
								<v-card @click="openDocumentEditor(element)">
									<div
										style="
											display: flex;
											flex-direction: column;
											justify-content: space-between;
											height: 100%;
											min-height: 180px;
										"
									>
										<div
											style="
												display: flex;
												justify-content: flex-end;
											"
										>
											<v-btn
												class="mr-2 mt-2"
												density="comfortable"
												icon="mdi-delete"
												@click.stop="
													openDeleteConfirmationDialog(
														element.id
													)
												"
												v-if="canDelete"
											></v-btn>
										</div>
										<v-card-text
											class="d-flex justify-center align-center mt-n4"
										>
											<v-icon size="60"
												>mdi-file-document</v-icon
											>
										</v-card-text>
										<v-card-title class="truncate mb-n2">{{
											element.title
										}}</v-card-title>
										<v-card-subtitle
											class="d-flex justify-center align-center"
											>{{
												!element.creator_name
													? "-"
													: "Erstellt von " +
													element.creator_name
											}}</v-card-subtitle
										>
										<v-card-subtitle
											class="d-flex justify-center align-center"
											>{{
												!element.updated_at
													? "-"
													: "Geändert: " +
													formatDate(
															element.updated_at
													)
											}}
											{{
												element.updated_at_user == null
													? ""
													: " von " +
													element.updated_at_user
											}}</v-card-subtitle
										>
									</div>
								</v-card>
							</v-col>
						</template>
					</draggable>
				</v-row>
				<v-divider class="border-opacity-50 mt-12 mb-12"></v-divider>
			</template>
		</template>
		<template v-else-if="viewMode === 'table'">
			<v-data-table
				:headers="headers"
				:items="filteredFlattenedDocuments"
				class="elevation-1"
				:group-by="groupBy"
				items-per-page="25"
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-toolbar-title>Dokumente</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>
					</v-toolbar>
				</template>
				<template
					v-slot:group-header="{
						item,
						columns,
						toggleGroup,
						isGroupOpen,
					}"
				>
					<tr>
						<td :colspan="columns.length">
							<VBtn
								:icon="isGroupOpen(item) ? '$expand' : '$next'"
								size="small"
								variant="text"
								@click="toggleGroup(item)"
							></VBtn>
							{{ item.value }}
						</td>
					</tr>
				</template>
				<!-- @ts-ignore -->
				<template v-slot:[`item.title`]="{ item }">
					<span
						style="cursor: pointer"
						@click="openDocumentEditor(item)"
						>{{ item.title }}</span
					>
				</template>
				<!-- @ts-ignore -->
				<template v-slot:[`item.created_at`]="{ item }">
					<span>{{ formatDate(item.created_at) }}</span>
				</template>
				<!-- @ts-ignore -->
				<template v-slot:[`item.updated_at`]="{ item }">
					<span
						>{{ formatDate(item.updated_at) }}
						{{
							item.updated_at_user == null
								? ""
								: " von " + item.updated_at_user
						}}</span
					>
				</template>
				<!-- @ts-ignore -->
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						size="small"
						class="me-2"
						@click="openDeleteConfirmationDialog(item.id)"
					>
						mdi-delete
					</v-icon>
					<v-icon
						size="small"
						class="me-2"
						@click="openDocumentEditor(item)"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</template>

		<DocumentEditor
			class="document-editor-container"
			:selected-document="selectedDocument"
			:categories="categories"
			@update:selectedDocument="updateSelectedDocument"
			@close-document="resetSelectedDocument"
			persistent
		/>

		<v-dialog v-model="addCategorieDialog" width="500">
			<v-card>
				<v-card-title>Neue Kategorie hinzufügen</v-card-title>
				<v-card-text>
					<v-form ref="addCategorieForm">
						<v-row>
							<v-col cols="12" md="12">
								<v-text-field
									label="Name"
									required
									v-model="newCategorie.name"
									@keydown.enter="openAddCategorieDialog"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text="true" @click="addCategorieDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn text="true" @click="addNewCategorie"
						>Hinzufügen</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showSortingModal" max-width="600px">
			<v-card>
				<v-card-title> Kategorien sortieren </v-card-title>
				<v-card-text>
					<draggable
						v-model="categories"
						:list="categories"
						tag="transition-group"
						:animation="150"
					>
						<template v-slot:item="{ element }">
							<v-list-item
								:key="element.id"
								class="list-item mb-2 d-flex align-center"
								style="cursor: move"
							>
								<v-avatar size="24" class="me-2">
									<v-icon>mdi-drag-variant</v-icon>
								</v-avatar>
								<span>{{ element.name }}</span>
							</v-list-item>
						</template>
					</draggable>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="showSortingModal = false"
						>Abbrechen</v-btn
					>
					<v-btn @click="saveCategorySorting">Speichern</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="deleteConfirmationDialog" width="500">
			<v-card>
				<v-card-title>Dokument löschen</v-card-title>
				<v-card-text>
					Bist du sicher, dass du das Dokument löschen willst?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text="true" @click="deleteConfirmationDialog = false"
						>Nein</v-btn
					>
					<v-btn
						color="error"
						text="true"
						@click="
							deleteDocument(documentToDeleteId);
							deleteConfirmationDialog = false;
						"
						>Ja</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, Ref, computed, watch } from "vue";
import DocumentEditor from "@/components/document/DocumentEditor.vue";
import api from "@/api";
import { Category, Document } from "@/types/Document";
import draggable from "vuedraggable";
import { useRoute } from "vue-router";
import store from "@/store";

export default defineComponent({
	components: {
		DocumentEditor,
		draggable,
	},
	setup() {
		const route = useRoute();
		const docSite = ref(route.meta.docType);
		const search = ref("");
		const categories = ref<Category[]>([]);
		const editedValue = ref("");
		const editingField = ref({ type: "", id: -1 });
		const selectedDocument = ref<Document | null>(null);
		const showSortingModal = ref(false);
		const addCategorieDialog = ref(false);
		const newCategorie = ref({
			name: "",
			site: docSite.value,
		});

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const viewMode = ref("tiles");
		const toggleViewMode = () => {
			viewMode.value = viewMode.value === "tiles" ? "table" : "tiles";
		};

		const headers = [
			{ title: "Titel", align: "start", sortable: true, key: "title" },
			{
				title: "Ersteller",
				align: "start",
				sortable: false,
				key: "creator_name",
			},
			{
				title: "Erstellt am",
				align: "start",
				sortable: false,
				key: "created_at",
			},
			{
				title: "Letzte Änderung",
				align: "start",
				sortable: true,
				key: "updated_at",
			},
			{ title: "Actions", key: "actions", sortable: false },
		];

		const groupBy = [
			{
				key: "categoryName",
				order: "asc",
			},
		];

		const errorSnackbar = ref<any>({ visible: false, message: "" });

		const flattenedDocuments = computed(() => {
			let documents = [];
			categories.value.forEach((category) => {
				category.documents.forEach((document) => {
					documents.push({
						...document,
						categoryName: category.name,
					});
				});
			});
			console.log(documents);
			return documents;
		});

		const deleteConfirmationDialog = ref(false);
		const documentToDeleteId = ref<number | null>(null);

		const openDeleteConfirmationDialog = (id: number) => {
			documentToDeleteId.value = id;
			deleteConfirmationDialog.value = true;
		};

		const logAccess = async (table: string, entryId: number) => {
			try {
				await api.post("logging/?action=logAccess", {
					table,
					entry_id: entryId,
				});
				console.log(
					"Access logged successfully for table:",
					table,
					"entry ID:",
					entryId
				);
			} catch (error: any) {
				console.error(
					"Error logging access:",
					error.response?.data?.error || error.message
				);
			}
		};

		// eslint-disable-next-line
		const openDocumentEditor = ( document: Document | null = null, isNew: boolean = false) => {
			if (isNew) {
				selectedDocument.value = {
					id: -1, // Temporäre ID für neues Dokument
					title: "",
					content: "",
					category_id: 1, // Standardkategorie-ID
					sort_order: -1,
					notes: "",
					creator: 0,
					creator_name: "",
					updated_at: "",
					updated_at_user: "",
					created_at: "",
				};
			} else {
				selectedDocument.value = document;

				// Log für das Öffnen eines bestehenden Dokuments
				if (document && document.id) {
					logAccess("documents", document.id); // Log mit der tatsächlichen Dokument-ID
				}
			}
		};

		const updateSelectedDocument = async (updatedDocument: Document) => {
			if (updatedDocument.id === -1) {
				// Implementieren Sie die Logik zum Hinzufügen eines neuen Dokuments
				try {
					const response = await api.post(
						"document/?action=addDocument",
						{
							...updatedDocument,
							site: docSite.value,
						}
					);
					const addedDocument = response.data;
					// Fügen Sie das hinzugefügte Dokument zur entsprechenden Kategorie hinzu
					fetchCategoriesAndDocuments();
				} catch (error) {
					errorSnackbar.value.message = error.response.data.error;
					errorSnackbar.value.visible = true;
				}
			} else {
				try {
					const response = await api.post(
						"document/?action=updateDocument",
						{
							...updatedDocument,
							site: docSite.value,
						}
					);
					const addedDocument = response.data;
					// Fügen Sie das hinzugefügte Dokument zur entsprechenden Kategorie hinzu
					fetchCategoriesAndDocuments();
				} catch (error) {
					errorSnackbar.value.message = error.response.data.error;
					errorSnackbar.value.visible = true;
				}
			}
		};

		const filteredFlattenedDocuments = computed(() => {
			if (!search.value) {
				return flattenedDocuments.value;
			}
			const searchLowercase = search.value.toLowerCase();
			return flattenedDocuments.value.filter((doc) =>
				doc.title.toLowerCase().includes(searchLowercase)
			);
		});

		const filteredCategories = computed(() => {
			if (!search.value) {
				return categories.value;
			}

			const searchLowercase = search.value.toLowerCase();

			return categories.value.map((category) => {
				const filteredDocuments = category.documents.filter((doc) =>
					doc.title.toLowerCase().includes(searchLowercase)
				);

				return { ...category, documents: filteredDocuments };
			});
		});

		watch(
			() => route.path,
			() => {
				docSite.value = route.meta.docType;
				fetchCategoriesAndDocuments();
			}
		);

		onMounted(() => {
			viewMode.value = store.state.user.documentView;
			fetchCategoriesAndDocuments();
		});

		async function fetchCategoriesAndDocuments() {
			try {
				const response = await api.post(
					"document/?action=getCategoriesAndDocuments",
					{ site: docSite.value }
				);
				categories.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		}

		const resetSelectedDocument = () => {
			selectedDocument.value = null;
		};

		const startEditingField = (fieldType: string, fieldId: number) => {
			editingField.value = { type: fieldType, id: fieldId };
			if (fieldType === "categoryName" && categories.value) {
				const category = categories.value.find(
					(cat) => cat.id === fieldId
				);
				if (category) {
					editedValue.value = category.name;
				}
			}
			// Füge hier weitere Bedingungen für andere Feldtypen hinzu
		};

		const cancelEditingField = () => {
			editingField.value = { type: "", id: -1 };
		};

		async function updateField(fieldId: number) {
			if (editedValue.value.trim() === "") return;

			if (
				editingField.value.type === "categoryName" &&
				categories.value
			) {
				try {
					const category = categories.value.find(
						(cat) => cat.id === fieldId
					);
					if (!category) {
						return;
					}

					await api.post("document/?action=updateCategoryName", {
						id: fieldId,
						name: editedValue.value,
						site: docSite.value,
					});

					category.name = editedValue.value;
				} catch (error) {
					errorSnackbar.value.message = error.response.data.error;
					errorSnackbar.value.visible = true;
				}
			}

			// Füge hier weitere Bedingungen für andere Feldtypen hinzu

			cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
		}

		const saveCategorySorting = async () => {
			try {
				const sortedCategories = categories.value.map(
					(category, index) => ({
						id: category.id,
						sort_order: index,
					})
				);

				const response = await api.post(
					"document/?action=saveCategorySorting",
					{
						categories: sortedCategories,
						site: docSite.value,
					},
					{
						headers: { "Content-Type": "application/json" },
					}
				);

				if (response.data.success) {
					errorSnackbar.value.message =
						"Kategorie-Sortierung gespeichert.";
					errorSnackbar.value.color = "green";
					errorSnackbar.value.visible = true;
				} else {
					errorSnackbar.value.message = response.data.error;
					errorSnackbar.value.visible = true;
				}
			} catch (error) {
				errorSnackbar.value.message =
					error.response?.data?.error ||
					"Ein unbekannter Fehler ist aufgetreten.";
				errorSnackbar.value.visible = true;
			}

			showSortingModal.value = false;
		};

		function openAddCategorieDialog() {
			addCategorieDialog.value = true;
		}

		async function addNewCategorie() {
			try {
				let response = await api.post("document/?action=addCategorie", {
					...newCategorie.value,
					site: docSite.value,
				});

				if (response.data.success) {
					fetchCategoriesAndDocuments(); // Refresh categories after adding the new template
					addCategorieDialog.value = false;
					newCategorie.value.name = "";
				} else {
					errorSnackbar.value.message = response.data.error;
					errorSnackbar.value.visible = true;
				}
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		}

		async function deleteDocument(id: number) {
			try {
				await api.post("document/?action=deleteDocument", {
					id,
					site: docSite.value,
				});
				fetchCategoriesAndDocuments(); // Refresh categories after adding the new template
				documentToDeleteId.value = 0;
				deleteConfirmationDialog.value = false;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		}

		const saveDocumentSorting = async (items) => {
			try {
				const sortedItems = items.map((item, index) => ({
					id: item.id,
					sort_order: index,
				}));
				const response = await api.post(
					"document/?action=saveDocumentSorting",
					{
						sortedItems,
						site: docSite.value,
					}
				);
				if (response.data.success) {
					errorSnackbar.value.message = "Sortierung gespeichert.";
					errorSnackbar.value.visible = true;
					errorSnackbar.value.color = "green";
				} else {
					console.error(
						"Failed to save template sorting:",
						response.data.error
					);
				}
			} catch (error) {
				if (error.response) {
					errorSnackbar.value.message = error.response.data.error;
				} else {
					errorSnackbar.value.message = "An unknown error occurred";
				}
				errorSnackbar.value.visible = true;
			}
		};

		const formatDate = (dateString) => {
			const date = new Date(dateString);
			// Überprüfen, ob das Datum gültig ist
			if (isNaN(date.getTime())) {
				return "Ungültiges Datum";
			}
			const day = date.getDate().toString().padStart(2, "0");
			const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Monate beginnen bei 0 in JavaScript
			const year = date.getFullYear();
			const hours = date.getHours().toString().padStart(2, "0");
			const minutes = date.getMinutes().toString().padStart(2, "0");
			const seconds = date.getSeconds().toString().padStart(2, "0");
			return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
		};

		return {
			categories,
			editingField,
			editedValue,
			startEditingField,
			cancelEditingField,
			updateField,
			openDocumentEditor,
			selectedDocument,
			updateSelectedDocument,
			resetSelectedDocument,
			saveCategorySorting,
			showSortingModal,
			openAddCategorieDialog,
			addCategorieDialog,
			addNewCategorie,
			newCategorie,
			deleteDocument,
			openDeleteConfirmationDialog,
			deleteConfirmationDialog,
			documentToDeleteId,
			viewMode,
			toggleViewMode,
			headers,
			flattenedDocuments,
			search,
			filteredFlattenedDocuments,
			filteredCategories,
			errorSnackbar,
			saveDocumentSorting,
			formatDate,
			groupBy,
			canEdit,
			canDelete,
		};
	},
});
</script>
<style>
.document-editor-container {
	position: fixed;
	top: 34px; /* Höhe der oberen Navbar */
	left: 128px;
	right: 0;
	bottom: 0;
	background-color: transparent; /* Optional: Hintergrundfarbe, um den Inhalt unterhalb abzudunkeln */
	z-index: 1000; /* Stellen Sie sicher, dass der DocumentEditor über anderen Inhalten angezeigt wird */
}

.document-editor-container > .v-overlay__scrim {
	background: transparent;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}
</style>
