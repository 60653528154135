<template>
  <error-snackbar v-model:snackbar="errorSnackbar" />
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>Map Categories</h3>
        <v-btn @click="openNewCategoryDialog">Add New Category</v-btn>
        <v-data-table :headers="categoryHeaders" :items="categories" :item-key="'id'" class="mt-4">
          <template v-slot:[`item.icon`]="{ item }">
            <img :src="`/img/mapIcons/${item.icon}.png`" width="24" height="24" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openEditCategoryDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Edit Dialog -->
    <v-dialog v-model="editDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.name" label="Name" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="iconPickerVisible = !iconPickerVisible"
                  class="icon-picker-btn"
                  block
                >
                  <v-avatar size="24">
                    <img
                      v-if="selectedItem.icon"
                      :src="`/img/mapIcons/${selectedItem.icon}.png`"
                      alt="Icon"
                    />
                  </v-avatar>
                  {{ selectedItem.icon ? selectedItem.icon : 'Select an Icon' }}
                </v-btn>

                <div
                  v-if="iconPickerVisible"
                  class="icon-picker"
                >
                  <v-row dense>
                    <v-col
                      v-for="icon in availableIcons"
                      :key="icon.filename"
                      cols="3"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        icon
                        @click="selectIcon(icon.filename)"
                        class="icon-btn"
                      >
                        <v-avatar size="24">
                          <img :src="`/img/mapIcons/${icon.filename}`" />
                        </v-avatar>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Delete Dialog -->
    <v-dialog v-model="confirmDialog" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Löschen bestätigen</span>
        </v-card-title>
        <v-card-text>Willst du das wirklich löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>



<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import api from '@/api';
import { Categories } from '@/types/Map';

export default defineComponent({
  setup() {
    const errorSnackbar = ref({ visible: false, message: '' });
    const categories = ref<Categories[]>([]);
    const availableIcons = ref([]);
    const selectedItem = ref<any>({ name: '', icon: '' });
    const formTitle = ref('');
    const editDialog = ref(false);
    const iconPickerVisible = ref(false);
    const confirmDialog = ref(false);
    const itemToDelete = ref<any>(null);

    const categoryHeaders = [
      { title: 'ID', key: 'id', align: 'start' },
      { title: 'Name', key: 'name' },
      { title: 'Aktuelle Elemente', key: 'marker_count' },
      { title: 'Icon', key: 'icon' },
      { title: 'Actions', key: 'actions' },
    ];

    const fetchCategories = async () => {
      try {
        const response = await api.get('admin/map/?action=getCategories');
        categories.value = response.data;
      } catch (error: any) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const fetchAvailableIcons = async () => {
  try {
    const response = await axios.get('/mapIcons.php');
    const icons = response.data;

    if (Array.isArray(icons)) {
      // Nur verarbeiten, wenn es sich um ein Array handelt
      availableIcons.value = icons.map((icon) => ({
        name: icon.name,
        filename: icon.filename,
      }));
    } else {
      console.error('Unexpected response format:', icons);
    }
  } catch (error) {
    console.error('Error fetching available icons:', error);
  }
};


    const openEditCategoryDialog = (category: any) => {
      selectedItem.value = { ...category };
      formTitle.value = 'Kategorie bearbeiten';
      editDialog.value = true;
    };

    const saveItem = async () => {
      try {
        await api.post('admin/map/?action=saveCategory', selectedItem.value);
        editDialog.value = false;
        await fetchCategories();
      } catch (error: any) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const selectIcon = (filename: string) => {
      selectedItem.value.icon = filename.split('.')[0];
      iconPickerVisible.value = false;
    };

    const confirmDelete = (item: any) => {
      itemToDelete.value = item;
      confirmDialog.value = true;
    };

    const proceedWithDelete = async () => {
      try {
        await api.post('admin/map/?action=deleteCategory', {
          id: itemToDelete.value.id,
        });
        confirmDialog.value = false;
        await fetchCategories();
      } catch (error: any) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openNewCategoryDialog = () => {
      selectedItem.value = { name: '', icon: '' };
      formTitle.value = 'Neue Kategorie hinzufügen';
      editDialog.value = true;
    };

    onMounted(async () => {
      await fetchCategories();
      await fetchAvailableIcons();
    });

    return {
      categories,
      availableIcons,
      selectedItem,
      formTitle,
      editDialog,
      iconPickerVisible,
      confirmDialog,
      itemToDelete,
      categoryHeaders,
      errorSnackbar,
      openEditCategoryDialog,
      saveItem,
      selectIcon,
      confirmDelete,
      proceedWithDelete,
      openNewCategoryDialog,
    };
  },
});
</script>
<style scoped>
.icon-picker-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.icon-picker {
  position: relative; /* Macht den Picker innerhalb des Dialogs relativ */
  width: 100%; /* Passt sich an die Breite des Buttons an */
  max-height: 200px; /* Begrenzung der Höhe */
  background: #2a2a2a;
  border-radius: 4px;
  overflow-y: auto; /* Scrollbar bei vielen Icons */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Sicherstellen, dass es über anderen Elementen angezeigt wird */
}

.icon-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-btn img {
  border-radius: 50%;
  max-width: 24px;
  max-height: 24px;
}
</style>
