import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_data_table, {
        headers: _ctx.templateHeaders,
        items: _ctx.templates,
        class: "elevation-1"
      }, {
        top: _withCtx(() => [
          _createVNode(_component_v_toolbar, { flat: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Templates verwalten")
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    onClick: _ctx.openNewTemplateDialog
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Neues Template")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        [`item.actions`]: _withCtx(({ item }) => [
          (_ctx.canEdit)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openEditTemplateDialog(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-pencil")
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.canDelete)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 1,
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openDeleteTemplateDialog(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-delete")
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["headers", "items"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.newTemplateDialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newTemplateDialog) = $event)),
        "max-width": "60%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Neues Template ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "newTemplateForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: "Name",
                        modelValue: _ctx.newTemplate.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTemplate.name) = $event)),
                        required: "",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.newTemplate.template,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newTemplate.template) = $event))
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.newTemplateDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.addNewTemplate,
                    disabled: !_ctx.isFormValid(_ctx.newTemplate)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Hinzufügen")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.editTemplateDialog,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editTemplateDialog) = $event)),
        "max-width": "60%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Template bearbeiten ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "editTemplateForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: "Name",
                        modelValue: _ctx.editedTemplate.name,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedTemplate.name) = $event)),
                        required: "",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.editedTemplate.template,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editedTemplate.template) = $event))
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editTemplateDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.updateTemplate
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteTemplateDialog,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.deleteTemplateDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Template löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie das Template \"" + _toDisplayString(_ctx.selectedTemplate?.name) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.deleteTemplateDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.deleteTemplate(_ctx.selectedTemplate)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}