import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_data_table, {
          headers: _ctx.headers,
          items: _ctx.crews,
          class: "elevation-1"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Besatzungsverwaltung")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                (_ctx.canEdit)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newCrewDialog = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Neue Besatzung")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            (_ctx.canEdit)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  size: "small",
                  class: "me-2",
                  onClick: ($event: any) => (_ctx.openEditCrewDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-pencil ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.canDelete)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  size: "small",
                  class: "me-2",
                  onClick: ($event: any) => (_ctx.openDeleteCrewDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-delete ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_icon, {
              size: "small",
              class: "me-2",
              onClick: ($event: any) => (_ctx.toggleCrewActivation(item))
            }, {
              default: _withCtx(() => [
                (item.active == 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" mdi-power ")
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" mdi-power-off ")
                    ], 64))
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1032, ["headers", "items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.newCrewDialog,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newCrewDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neue Besatzung")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "newCrewForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Name",
                          modelValue: _ctx.newCrew.name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCrew.name) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Status",
                          modelValue: _ctx.newCrew.status,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCrew.status) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "Sortierreihenfolge",
                          modelValue: _ctx.newCrew.sort_order,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newCrew.sort_order) = $event)),
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newCrewDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.addNewCrew,
                      disabled: !_ctx.isFormValid(_ctx.newCrew)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.editCrewDialog,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editCrewDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Besatzung bearbeiten")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "editCrewForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Name",
                          modelValue: _ctx.editedCrew.name,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editedCrew.name) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Status",
                          modelValue: _ctx.editedCrew.status,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedCrew.status) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "Sortierreihenfolge",
                          modelValue: _ctx.editedCrew.sort_order,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editedCrew.sort_order) = $event)),
                          type: "number"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editCrewDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.updateCrew
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deleteCrewDialog,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.deleteCrewDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Besatzung löschen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchten Sie die Besatzung \"" + _toDisplayString(_ctx.selectedCrew.name) + "\" wirklich löschen? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deleteCrewDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.deleteCrew(_ctx.selectedCrew)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Löschen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar.value,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.snackbar.value) = $event)),
          color: _ctx.snackbar.color,
          timeout: 4000
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}