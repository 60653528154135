<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-data-table
        :headers="headers"
        :items="crews"
        class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Besatzungsverwaltung</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn @click="newCrewDialog = true" v-if="canEdit">Neue Besatzung</v-btn>
                </v-toolbar>
            </template>
            <!-- @ts-ignore -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    size="small"
                    class="me-2"
                    @click="openEditCrewDialog(item)"
                    v-if="canEdit"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    size="small"
                    class="me-2"
                    @click="openDeleteCrewDialog(item)"
                    v-if="canDelete"
                >
                    mdi-delete
                </v-icon>
                <v-icon
                    size="small"
                    class="me-2"
                    @click="toggleCrewActivation(item)"
                >
                    <template v-if="item.active == 1">
                        mdi-power
                    </template>
                    <template v-else>
                        mdi-power-off
                    </template>
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="newCrewDialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="headline">Neue Besatzung</v-card-title>
                <v-card-text>
                    <v-form ref="newCrewForm">
                        <v-text-field label="Name" v-model="newCrew.name" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Status" v-model="newCrew.status"></v-text-field>
                        <v-text-field label="Sortierreihenfolge" v-model="newCrew.sort_order" type="number"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="newCrewDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="addNewCrew" :disabled="!isFormValid(newCrew)">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editCrewDialog" max-width="500" persistent>
            <v-card>
                <v-card-title class="headline">Besatzung bearbeiten</v-card-title>
                <v-card-text>
                    <v-form ref="editCrewForm">
                        <v-text-field label="Name" v-model="editedCrew.name" required :rules="[requiredRule]"></v-text-field>
                        <v-text-field label="Status" v-model="editedCrew.status"></v-text-field>
                        <v-text-field label="Sortierreihenfolge" v-model="editedCrew.sort_order" type="number"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editCrewDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="updateCrew">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteCrewDialog" max-width="400" persistent>
            <v-card>
                <v-card-title class="headline">Besatzung löschen</v-card-title>
                <v-card-text>
                    Möchten Sie die Besatzung "{{ selectedCrew.name }}" wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteCrewDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteCrew(selectedCrew)">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { Crew } from '@/types/Crew';
import { useRoute } from "vue-router";

export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const headers = [
            { title: 'Name', align: 'start', sortable: false, key: 'name' },
            { title: 'Status', align: 'start', sortable: false, key: 'status' },
            { title: 'Sortierreihenfolge', align: 'start', sortable: false, key: 'sort_order' },
            { title: 'Aktionen', key: 'actions', sortable: false }
        ];

        const route = useRoute();

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);

        const crews = ref<Crew[]>([]);
        const newCrewDialog = ref(false);
        const editCrewDialog = ref(false);
        const deleteCrewDialog = ref(false);
        const selectedCrew = ref<Crew | null>(null);
        const newCrew = ref({
            name: '',
            status: '',
            sort_order: 0,
        });
        const editedCrew = ref({
            id: '',
            name: '',
            status: '',
            sort_order: 0,
        });

        const snackbar = ref({
            value: false,
            color: '',
            message: '',
        });

        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        const fetchCrews = async () => {
            try {
                const response = await api.get('crew/?action=getCrews');
                crews.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const addNewCrew = async () => {
            try {
                const response = await api.post("crew/?action=addCrew", newCrew.value);
                await fetchCrews();
                newCrewDialog.value = false;
                newCrew.value = { name: '', status: '', sort_order: 0 };
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const openEditCrewDialog = (crew) => {
            editedCrew.value = { ...crew };
            editCrewDialog.value = true;
        };

        const updateCrew = async () => {
            try {
                const response = await api.post("crew/?action=editCrew", editedCrew.value);
                await fetchCrews();
                editCrewDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const openDeleteCrewDialog = (crew) => {
            selectedCrew.value = crew;
            deleteCrewDialog.value = true;
        };

        const deleteCrew = async (crew) => {
            try {
                const response = await api.post("crew/?action=deleteCrew", { id: crew.id });
                await fetchCrews();
                deleteCrewDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const toggleCrewActivation = async (crew) => {
            try {
                const response = await api.post("crew/?action=toggleCrewActivation", { id: crew.id });
                await fetchCrews();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        function isFormValid(form) {
            return form.name !== '';
        }

        onMounted(async () => {
            await fetchCrews();
        });

        return {
            headers,
            crews,
            newCrewDialog,
            newCrew,
            addNewCrew,
            requiredRule,
            editCrewDialog,
            editedCrew,
            updateCrew,
            deleteCrewDialog,
            selectedCrew,
            deleteCrew,
            openEditCrewDialog,
            openDeleteCrewDialog,
            toggleCrewActivation,
            isFormValid,
            errorSnackbar,
            snackbar,
            canEdit,
            canDelete,
        };
    },
});
</script>

<style scoped>
.text-red {
    color: red;
}
</style>
