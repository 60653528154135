<template>
	<v-container>
		<v-btn
			@click="openAddApartmentDialog"
			class="float-right"
			v-if="!addApartmentDialog && !editedApartment && !selectedApartment && canEdit"
			>Neue Wohnung</v-btn
		>

		<v-data-table
			:headers="apartmentHeaders"
			:items="filteredApartments"
			class="elevation-1 mt-12"
			:search="search"
			:items-per-page="10"
			v-if="!addApartmentDialog && !editedApartment && !selectedApartment"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Wohnungsverzeichnis</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						label="Suche"
						class="mx-4 mt-3"
						variant="underlined"
					/>
				</v-toolbar>
			</template>
			<template v-slot:[`item.name`]="{ item }">
				<span
					size="small"
					class="me-2"
					@click="openViewApartmentDialog(item)"
					style="cursor: pointer"
				>
					{{ item.name }}
				</span>
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					size="small"
					class="me-2"
					@click="openEditApartmentDialog(item)"
					v-if="canEdit"
					>mdi-pencil</v-icon
				>
				<v-icon
					size="small"
					class="me-2"
					@click="openDeleteApartmentDialog(item)"
					v-if="canDelete"
					>mdi-delete</v-icon
				>
			</template>
		</v-data-table>

				<!-- Delete Vehicle Dialog -->
		<v-dialog v-model="deleteApartmentDialog" max-width="400" persistent>
			<v-card>
				<v-card-title class="headline">Fahrzeug löschen</v-card-title>
				<v-card-text>
					Möchten Sie die Wohnung wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteApartmentDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteApartment(selectedApartment)"
						>Löschen</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- AddApartmentFile Component -->
		<component
			:is="addApartmentComponent"
			v-model="addApartmentDialog"
			:addApartmentDialog="addApartmentDialog"
			@apartmentAdded="onApartmentAdded"
			@close="addApartmentClose"
		/>

		<!-- EditApartmentFile Component -->
		<component
			:is="editApartmentComponent"
			v-model="editApartmentDialog"
			:apartmentToEdit="editedApartment"
			@apartmentUpdated="onApartmentUpdated"
			@close="editApartmentClose"
		/>

		<!-- ViewApartmentFile Component -->
		<component
			:is="viewApartmentComponent"
			v-model="viewApartmentDialog"
			:apartmentToView="selectedApartment"
			@close="viewApartmentClose"
		/>

		<!-- Snackbar -->
		<v-snackbar
			v-model="snackbar.value"
			:color="snackbar.color"
			:timeout="4000"
		>
			{{ snackbar.message }}
		</v-snackbar>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import api from "@/api";
import { Apartment } from "@/types/Apartment";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// Import authority-specific components
import AuthorityAddApartmentFile from "@/components/ApartmentFile/Authority/Add.vue";
import AuthorityEditApartmentFile from "@/components/ApartmentFile/Authority/Edit.vue";
import AuthorityViewApartmentFile from "@/components/ApartmentFile/Authority/View.vue";

export default defineComponent({
	components: {
		AuthorityAddApartmentFile,
		AuthorityEditApartmentFile,
		AuthorityViewApartmentFile,
	},
	setup() {
		const store = useStore();
		const apartmentHeaders = [
			{ title: "Name", key: "name", sortable: true },
			{ title: "Ort", key: "location", sortable: true },
			{ title: "Straße", key: "street", sortable: true },
			{ title: "Hausnummer", key: "housenumber", sortable: true },
			{ title: "Aktionen", key: "actions", sortable: false },
		];

		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const addApartmentComponent = computed(() => {
			return AuthorityAddApartmentFile; // Fallback
		});

		const editApartmentComponent = computed(() => {
			return AuthorityEditApartmentFile; // Fallback
		});

		const viewApartmentComponent = computed(() => {
			return AuthorityViewApartmentFile; // Fallback
		});

		const apartments = ref<Apartment[]>([]);
		const addApartmentDialog = ref(false);
		const editApartmentDialog = ref(false);
		const viewApartmentDialog = ref(false);
		const editedApartment = ref<Apartment | null>(null);
		const selectedApartment = ref<Apartment | null>(null);
		const search = ref("");
		const snackbar = ref({
			value: false,
			color: "",
			message: "",
		});

		const fetchApartments = async () => {
			try {
				const response = await api.get(
					"apartmentfile/?action=getApartments"
				);
				apartments.value = response.data;
			} catch (error) {
				snackbar.value.message =
					error.response?.data?.error ||
					"Fehler beim Laden der Wohnungen";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		const filteredApartments = computed(() => {
			const searchTerm = search.value.trim().toLowerCase();
			return apartments.value.filter((apartment) =>
				`${apartment.name} ${apartment.location} ${apartment.street}`
					.toLowerCase()
					.includes(searchTerm)
			);
		});

		const onApartmentAdded = async () => {
			await fetchApartments();
		};

		const openAddApartmentDialog = () => {
			addApartmentDialog.value = true;
		};

		const addApartmentClose = () => {
			addApartmentDialog.value = false;
		};


		const editApartmentClose = (apartment: Apartment) => {
			editedApartment.value = null;
			editApartmentDialog.value = false;
		};

		const viewApartmentClose = () => {
			selectedApartment.value = null;
			viewApartmentDialog.value = false;
		};

		const openEditApartmentDialog = (apartment: Apartment) => {
			editedApartment.value = { ...apartment };
			editApartmentDialog.value = true;
		};

		const onApartmentUpdated = async () => {
			await fetchApartments();
		};

		const openViewApartmentDialog = (apartment: Apartment) => {
			selectedApartment.value = { ...apartment };
			viewApartmentDialog.value = true;
		};

		const deleteApartmentDialog = ref(false);

		const openDeleteApartmentDialog = (apartment: Apartment) => {
			selectedApartment.value = apartment;
			deleteApartmentDialog.value = true;
		};

		const deleteApartment = async (apartment: Apartment) => {
			try {
				await api.post("apartmentfile/?action=deleteApartment", {
					id: apartment.id,
				});
				await fetchApartments();
				deleteApartmentDialog.value = false;
				snackbar.value.message = "Wohnung erfolgreich gelöscht";
				snackbar.value.color = "success";
				snackbar.value.value = true;
			} catch (error) {
				snackbar.value.message =
					error.response?.data?.error ||
					"Fehler beim Löschen der Wohnung";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		onMounted(async () => {
			await fetchApartments();
		});

		return {
			apartmentHeaders,
			apartments,
			filteredApartments,
			addApartmentDialog,
			editApartmentDialog,
			viewApartmentDialog,
			editedApartment,
			selectedApartment,
			search,
			snackbar,
			addApartmentComponent,
			editApartmentComponent,
			viewApartmentComponent,
			openAddApartmentDialog,
			openEditApartmentDialog,
			openViewApartmentDialog,
			openDeleteApartmentDialog,
			onApartmentAdded,
			onApartmentUpdated,
			addApartmentClose,
			editApartmentClose,
			viewApartmentClose,
			canEdit,
			canDelete,
			deleteApartmentDialog,
			deleteApartment
		};
	},
});
</script>
