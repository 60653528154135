<template>
	<v-container>
		<v-data-table
			:headers="additionalHeaders"
			:items="additionals"
			class="elevation-1"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title
						>Zusätze verwalten</v-toolbar-title
					>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-btn @click="openNewAdditionalDialog" v-if="canEdit"
						>Neuer Zusatz</v-btn
					>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					size="small"
					class="me-2"
					@click="openEditAdditionalDialog(item)"
					v-if="canEdit"
					>mdi-pencil</v-icon
				>
				<v-icon
					size="small"
					class="me-2"
					@click="openDeleteAdditionalDialog(item)"
					v-if="canDelete"
					>mdi-delete</v-icon
				>
			</template>
		</v-data-table>

		<!-- New Additional Dialog -->
		<v-dialog v-model="newAdditionalDialog" max-width="60%">
			<v-card>
				<v-card-title>Neue Zusatzleistung</v-card-title>
				<v-card-text>
					<v-form ref="newAdditionalForm">
						<v-text-field
							label="Name"
							v-model="newAdditional.name"
							required
							:rules="[requiredRule]"
						></v-text-field>
						<v-textarea
							label="Beschreibung"
							v-model="newAdditional.description"
						></v-textarea>
						<v-text-field
							label="Preis"
							v-model="newAdditional.price"
							required
							type="number"
							:rules="[requiredRule]"
						></v-text-field>
						<v-text-field
							label="Einheiten"
							v-model="newAdditional.units"
							type="number"
						></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="newAdditionalDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn
						color="blue darken-1"
						text
						@click="addNewAdditional"
						:disabled="!isFormValid(newAdditional)"
						>Hinzufügen</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Edit Additional Dialog -->
		<v-dialog v-model="editAdditionalDialog" max-width="60%">
			<v-card>
				<v-card-title>Zusatzleistung bearbeiten</v-card-title>
				<v-card-text>
					<v-form ref="editAdditionalForm">
						<v-text-field
							label="Name"
							v-model="editedAdditional.name"
							required
							:rules="[requiredRule]"
						></v-text-field>
						<v-textarea
							label="Beschreibung"
							v-model="editedAdditional.description"
						></v-textarea>
						<v-text-field
							label="Preis"
							v-model="editedAdditional.price"
							required
							type="number"
							:rules="[requiredRule]"
						></v-text-field>
						<v-text-field
							label="Einheiten"
							v-model="editedAdditional.units"
							type="number"
						></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="editAdditionalDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn color="blue darken-1" text @click="updateAdditional"
						>Speichern</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete Additional Dialog -->
		<v-dialog v-model="deleteAdditionalDialog" max-width="400" persistent>
			<v-card>
				<v-card-title class="headline"
					>Zusatzleistung löschen</v-card-title
				>
				<v-card-text>
					Möchten Sie die Zusatzleistung "{{
						selectedAdditional?.name
					}}" wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteAdditionalDialog = false"
						>Abbrechen</v-btn
					>
					<v-btn
						color="blue darken-1"
						text
						@click="deleteAdditional(selectedAdditional)"
						>Löschen</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Snackbar -->
		<v-snackbar
			v-model="snackbar.value"
			:color="snackbar.color"
			:timeout="4000"
		>
			{{ snackbar.message }}
		</v-snackbar>
	</v-container>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import api from "@/api";
import { ReportAdditional } from "@/types/Report";
import { useRoute } from "vue-router";

export default defineComponent({
	setup() {
		const errorSnackbar = ref<any>({ visible: false, message: "" });
		const additionalHeaders = [
			{ title: "Name", align: "start", sortable: false, key: "name" },
			{
				title: "Beschreibung",
				align: "start",
				sortable: false,
				key: "description",
			},
			{ title: "Preis", align: "start", sortable: false, key: "price" },
			{
				title: "Einheiten",
				align: "start",
				sortable: false,
				key: "units",
			},
			{ title: "Aktionen", key: "actions", sortable: false },
		];

		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const additionals = ref<ReportAdditional[]>([]);
		const newAdditionalDialog = ref(false);
		const editAdditionalDialog = ref(false);
		const deleteAdditionalDialog = ref(false);
		const selectedAdditional = ref<ReportAdditional | null>(null);

		const newAdditional = ref({
			name: "",
			description: "",
			price: 0,
			units: 1,
		});

		const editedAdditional = ref<ReportAdditional>({
			id: 0,
			name: "",
			description: "",
			price: 0,
			units: 1,
			sort_order: 0,
			created_at: "",
			changed_at: "",
			is_deleted: false,
		});

		const snackbar = ref({
			value: false,
			color: "",
			message: "",
		});

		const requiredRule = (value: string) =>
			!!value || "Dieses Feld ist erforderlich.";

		const fetchAdditionals = async () => {
			try {
				const response = await api.get("report/?action=getAdditionals");
				additionals.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const addNewAdditional = async () => {
			try {
				await api.post(
					"report/?action=addAdditional",
					newAdditional.value
				);
				await fetchAdditionals();
				newAdditionalDialog.value = false;
				newAdditional.value = {
					name: "",
					description: "",
					price: 0,
					units: 1,
				}; // Reset
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const openEditAdditionalDialog = (additional: ReportAdditional) => {
			editedAdditional.value = { ...additional };
			editAdditionalDialog.value = true;
		};

		const updateAdditional = async () => {
			try {
				await api.post(
					"report/?action=editAdditional",
					editedAdditional.value
				);
				await fetchAdditionals();
				editAdditionalDialog.value = false;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const openDeleteAdditionalDialog = (additional: ReportAdditional) => {
			selectedAdditional.value = additional;
			deleteAdditionalDialog.value = true;
		};

		const deleteAdditional = async (additional: ReportAdditional) => {
			try {
				await api.post("report/?action=deleteAdditional", {
					id: additional.id,
				});
				await fetchAdditionals();
				deleteAdditionalDialog.value = false;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		function isFormValid(form: {
			name: string;
			description: string;
			price: number;
			units: number;
		}) {
			return form.name !== "" && form.price > 0;
		}

		const openNewAdditionalDialog = () => {
			newAdditional.value = {
				name: "",
				description: "",
				price: 0,
				units: 1,
			};
			newAdditionalDialog.value = true;
		};

		onMounted(async () => {
			await fetchAdditionals();
		});

		return {
			additionalHeaders,
			additionals,
			newAdditionalDialog,
			newAdditional,
			addNewAdditional,
			requiredRule,
			editAdditionalDialog,
			editedAdditional,
			updateAdditional,
			deleteAdditionalDialog,
			selectedAdditional,
			deleteAdditional,
			openEditAdditionalDialog,
			openDeleteAdditionalDialog,
			isFormValid,
			errorSnackbar,
			snackbar,
			openNewAdditionalDialog,
			canEdit,
			canDelete
		};
	},
});
</script>

<style scoped></style>
