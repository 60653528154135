<template>
	<v-card>
		<v-card-title>
			Rechnung bearbeiten
			<v-spacer></v-spacer>
			<v-row justify="end" class="d-flex align-center">
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_delivered"
						label="Geliefert?"
						hide-details
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_sent"
						label="Rechnung versendet?"
						hide-details
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_paid"
						label="Bezahlt?"
						hide-details
					></v-switch>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<v-form ref="form">
				<v-row>
					<v-col cols="12">
						<v-select
                            label="System auswählen"
                            :items="[
                                { text: 'Ausgabe', value: 0 },
                                { text: 'Einnahme', value: 1 }
                            ]"
                            item-title="text"
                            item-value="value"
                            v-model="invoice.outgoing"
                        ></v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="invoice.title"
							label="Betreff"
							required
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.customer"
							label="Kunde"
							required
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.phone_number"
							label="Telefonnummer"
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.email"
							label="E-Mail"
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.account"
							label="Konto"
						></v-text-field>
					</v-col>
				</v-row>

				<!-- Beschreibung -->
				<v-row>
					<v-col cols="12">
						<v-textarea
							v-model="invoice.description"
							label="Beschreibung"
						></v-textarea>
					</v-col>
				</v-row>

				<!-- Items bearbeiten -->
				<v-row>
					<v-col cols="12">
						<h4>Items</h4>
						<v-btn @click="addItem">Item hinzufügen</v-btn>
					</v-col>
				</v-row>

				<v-row
					v-for="(item, index) in invoice.items"
					:key="index"
					class="mt-2"
				>
					<v-col cols="3">
						<v-select
							v-model="invoice.items[index].item_id"
							:items="itemOptions"
							item-title="item_name"
							item-value="id"
							label="Gegenstand"
							placeholder="Wähle einen Gegenstand"
							required
							@update:modelValue="onItemSelected(index, $event)"
						></v-select>
					</v-col>
					<v-col cols="4">
						<v-text-field
							v-model="item.description"
							label="Beschreibung"
							placeholder="Beschreibung"
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field
							v-model="item.price"
							label="Preis"
							type="number"
							placeholder="0.00"
							required
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-row>
							<v-col cols="8">
								<v-text-field
									v-model="item.quantity"
									label="Menge"
									type="number"
									placeholder="1"
									required
								></v-text-field>
							</v-col>
							<v-col cols="4" class="d-flex align-center">
								<v-btn
									icon
									color="error"
									@click="removeItem(index)"
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<!-- Rabatt und Anhänge -->
				<v-row class="mt-4">
					<v-col cols="6">
						<v-text-field
							v-model="invoice.discount"
							label="Rabatt"
							type="number"
						></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-file-input
							v-model="invoice.newAttachments"
							label="Anhänge hochladen"
							multiple
						></v-file-input>
					</v-col>
				</v-row>

				<!-- Vorhandene Anhänge -->
				<v-row>
					<v-col cols="12">
						<h4>Vorhandene Anhänge</h4>
						<v-row v-if="invoice.attachments.length > 0">
							<template
								v-for="(
									attachment, index
								) in invoice.attachments"
								:key="index"
							>
								<v-col cols="12" sm="2" md="2" lg="2">
									<v-card class="file-card">
										<div class="file-card-content">
											<!-- Vorschau für Bilder -->
											<v-img
												v-if="isImage(attachment)"
												:src="attachment"
												class="file-image"
											></v-img>
											<!-- Icon für andere Dateitypen -->
											<v-icon
												v-else
												size="80"
												class="file-icon"
												>mdi-file</v-icon
											>
										</div>
										<v-card-title class="truncate">{{
											getFileName(attachment)
										}}</v-card-title>
										<v-card-actions>
											<!-- Aktionen: Öffnen, Löschen -->
											<v-btn
												icon
												@click="
													openAttachment(attachment)
												"
											>
												<v-icon>mdi-download</v-icon>
											</v-btn>
											<v-btn
												icon
												color="error"
												@click="removeAttachment(index)"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
							</template>
						</v-row>
						<v-row v-else>
							<v-col cols="12">
								<p>Keine Anhänge vorhanden.</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<!-- Aktionen -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="updateInvoice">Aktualisieren</v-btn>
			<v-btn text @click="closeForm">Abbrechen</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import api from "@/api";

export default defineComponent({
	name: "EditInvoiceFile",
	props: {
		modelValue: { type: Boolean, required: true },
		invoiceToEdit: { type: Object, required: true },
	},
	emits: ["invoiceUpdated", "closeForm"],
	setup(props, { emit }) {
		const invoice = ref({
			...props.invoiceToEdit,
			items: Array.isArray(props.invoiceToEdit.items)
				? props.invoiceToEdit.items
				: [],
			attachments: Array.isArray(props.invoiceToEdit.attachments)
				? props.invoiceToEdit.attachments
				: [],
			newAttachments: [],
			removedAttachments: [],
		});

		const isImage = (path: string) =>
			/\.(jpg|jpeg|png|gif|bmp)$/i.test(path);

		// Datei aus dem Pfad extrahieren
		const getFileName = (path: string): string => {
			return path.split("/").pop() || "Unbekannt";
		};

		// Anhang öffnen
		const openAttachment = (attachment: string) => {
			window.open(attachment, "_blank");
		};

		if (typeof props.invoiceToEdit.attachments === "string") {
			try {
				invoice.value.attachments = JSON.parse(
					props.invoiceToEdit.attachments
				);
			} catch (e) {
				console.error("Fehler beim Parsen der Anhänge:", e);
				invoice.value.attachments = [];
			}
		}

		const itemOptions = ref([]);

		const fetchItems = async () => {
			try {
				const response = await api.post(
					"invoiceitems/?action=getInvoiceItems"
				);
				itemOptions.value = response.data.map((item) => ({
					id: Number(item.id),
					item_name: item.item_name,
					description: item.description,
					price: item.price,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Gegenstände:", error);
			}
		};

		const addItem = () => {
			invoice.value.items.push({
				id: null,
				description: "",
				price: 0,
				quantity: 1,
			});
		};

		const removeItem = (index: number) => {
			invoice.value.items.splice(index, 1);
		};

		const removeAttachment = (index: number) => {
			const removed = invoice.value.attachments.splice(index, 1)[0];
			invoice.value.removedAttachments.push(removed); // Entfernten Anhang speichern
		};

		const onItemSelected = (index, selectedId) => {
			const selectedItem = itemOptions.value.find(
				(opt) => opt.id === selectedId
			);
			if (selectedItem) {
				invoice.value.items[index].item_id = selectedItem.id;
				invoice.value.items[index].item_name = selectedItem.item_name;
				invoice.value.items[index].description =
					selectedItem.description;
				invoice.value.items[index].price = selectedItem.price;
			} else {
				console.warn(
					`Item with ID ${selectedId} not found in itemOptions`
				);
			}
		};

		const updateInvoice = () => {
			emit("invoiceUpdated", invoice.value);
		};

		const closeForm = () => {
			emit("closeForm");
		};

		onMounted(fetchItems);

		return {
			invoice,
			itemOptions,
			addItem,
			removeItem,
			onItemSelected,
			updateInvoice,
			closeForm,
			openAttachment,
			removeAttachment,
			isImage,
			getFileName,
		};
	},
});
</script>
<style scoped>
.file-card {
    border-radius: 12px;
    height: 300px; /* Einheitliche Höhe für alle Karten */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.file-card-content {
    height: 150px; /* Einheitliche Höhe für den Inhalt */
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-image {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}

.file-icon {
    color: var(--primary);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
</style>
