<template>
	<v-card>
		<v-card-title>
			Neue Rechnung hinzufügen
			<v-spacer></v-spacer>
			<v-row class="mb-4">
				<v-col cols="5">
					<v-autocomplete
						v-model="selectedCompanyId"
						:items="companies"
						item-title="name"
						item-value="id"
						label="Unternehmen auswählen"
						placeholder="Wähle ein Unternehmen"
						@change="clearPersonSelection"
						clearable
						dense
						hide-details
					></v-autocomplete>
				</v-col>
				<v-col cols="5">
					<v-autocomplete
						v-model="selectedPersonId"
						:items="persons"
						item-title="fullName"
						item-value="id"
						label="Person auswählen"
						placeholder="Wähle eine Person"
						@change="clearCompanySelection"
						clearable
						dense
						hide-details
					></v-autocomplete>
				</v-col>
				<v-col cols="2" class="d-flex justify-end">
					<v-btn @click="loadCustomerData">Daten laden</v-btn>
				</v-col>
			</v-row>
			<v-row justify="end" class="d-flex align-center">
				<!-- Linker Text -->
				<v-col>
					<p v-show="linked">{{ linked }} <br>(Achtung, kann später nicht mehr geändert werden)"</p>
				</v-col>

				<!-- Rechte Slider -->
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_delivered"
						label="Geliefert?"
						hide-details
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_sent"
						label="Rechnung versendet?"
						hide-details
					></v-switch>
				</v-col>
				<v-col cols="auto">
					<v-switch
						v-model="invoice.is_paid"
						label="Bezahlt?"
						hide-details
					></v-switch>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<v-form ref="form">
				<v-row>
					<v-col cols="12">
						<v-select
                            label="System auswählen"
                            :items="[
                                { text: 'Ausgabe', value: 0 },
                                { text: 'Einnahme', value: 1 }
                            ]"
                            item-title="text"
                            item-value="value"
                            v-model="invoice.outgoing"
                        ></v-select>
					</v-col>
				</v-row>		
				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="invoice.title"
							label="Betreff"
							required
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.customer"
							label="Kunde"
							required
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.phone_number"
							label="Telefonnummer"
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.email"
							label="E-Mail"
						></v-text-field>
					</v-col>
					<v-col cols="3">
						<v-text-field
							v-model="invoice.account"
							label="Konto"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-textarea
							v-model="invoice.description"
							label="Beschreibung"
						></v-textarea>
					</v-col>
				</v-row>

				<!-- Items hinzufügen -->
				<v-row>
					<v-col cols="12">
						<h4>Items</h4>
						<v-btn @click="addItem">Item hinzufügen</v-btn>
					</v-col>
				</v-row>

				<v-row
					v-for="(item, index) in invoice.items"
					:key="index"
					class="mt-2"
				>
					<v-col cols="3">
						<v-select
							v-model="item.id"
							:items="itemOptions"
							item-title="item_name"
							item-value="id"
							label="Gegenstand"
							placeholder="Wähle einen Gegenstand"
							required
							@update:modelValue="onItemSelected(index, $event)"
						></v-select>
					</v-col>
					<v-col cols="4">
						<v-text-field
							v-model="item.description"
							label="Beschreibung"
							placeholder="Beschreibung"
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field
							v-model="item.price"
							label="Preis"
							type="number"
							placeholder="0.00"
							required
						></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-row>
							<v-col cols="8">
								<v-text-field
									v-model="item.quantity"
									label="Menge"
									type="number"
									placeholder="1"
									required
								></v-text-field>
							</v-col>
							<v-col cols="4" class="d-flex align-center">
								<v-btn
									icon
									color="error"
									@click="removeItem(index)"
								>
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row class="mt-4">
					<v-col cols="6">
						<v-text-field
							v-model="invoice.discount"
							label="Rabatt"
							type="number"
						></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-file-input
							v-model="invoice.attachments"
							label="Anhänge"
							multiple
						></v-file-input>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="saveInvoice">Speichern</v-btn>
			<v-btn text @click="closeForm">Abbrechen</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import api from "@/api";

export default defineComponent({
	name: "AddInvoiceFile",
	emits: ["invoiceAdded", "closeForm"],
	setup(_, { emit }) {
		const invoice = ref({
			title: "",
			customer: "",
			phone_number: "",
			email: "",
			account: "",
			description: "",
			is_delivered: false,
			is_sent: false,
			is_paid: false,
			discount: 0,
			attachments: [],
			items: [],
			linked_person: null,
			linked_company: null,
			outgoing: 1,
		});

		const linked = ref("");

		const companies = ref([]);
		const persons = ref([]);
		const selectedCompanyId = ref(null);
		const selectedPersonId = ref(null);

		const companySearch = ref("");
		const personSearch = ref("");

		const fetchCompanies = async () => {
			try {
				const response = await api.post("invoice/?action=getCompanies");
				companies.value = response.data;
			} catch (error) {
				console.error("Fehler beim Laden der Unternehmen:", error);
			}
		};

		const fetchPersons = async () => {
			try {
				const response = await api.post("invoice/?action=getPersons");
				persons.value = response.data.map((person) => ({
					...person,
					fullName: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		const clearCompanySelection = () => {
			selectedCompanyId.value = null;
		};

		const clearPersonSelection = () => {
			selectedPersonId.value = null;
		};

		const loadCustomerData = async () => {
			if (selectedCompanyId.value) {
				try {
					const response = await api.post(
						"invoice/?action=getCompanyData",
						{ id: selectedCompanyId.value }
					);
					const data = response.data;
					invoice.value.customer = data.name;
					invoice.value.phone_number = data.phonenumber;
					invoice.value.email = data.email;
					invoice.value.linked_person = null;
					invoice.value.linked_company = selectedCompanyId.value;
					linked.value = `Verlinkt mit Firma: ${data.name}`
				} catch (error) {
					console.error(
						"Fehler beim Laden der Unternehmensdaten:",
						error
					);
				}
			} else if (selectedPersonId.value) {
				try {
					const response = await api.post(
						"invoice/?action=getPersonData",
						{ id: selectedPersonId.value }
					);
					const data = response.data;
					invoice.value.customer = `${data.firstname} ${data.lastname}`;
					invoice.value.phone_number = data.phonenumber;
					invoice.value.email = data.mail;
					invoice.value.linked_person = selectedPersonId.value;
					invoice.value.linked_company = null;
					linked.value = `Verlinkt mit Person: ${data.firstname} ${data.lastname}`
				} catch (error) {
					console.error(
						"Fehler beim Laden der Personendaten:",
						error
					);
				}
			} else {
				alert("Bitte wähle ein Unternehmen oder eine Person aus.");
			}
		};

		// Computed für gefilterte Listen basierend auf der Suchanfrage
		const filteredCompanies = computed(() =>
			companies.value.filter((company) =>
				company.name
					.toLowerCase()
					.includes(companySearch.value.toLowerCase())
			)
		);

		const filteredPersons = computed(() =>
			persons.value.filter((person) =>
				person.fullName
					.toLowerCase()
					.includes(personSearch.value.toLowerCase())
			)
		);

		const itemOptions = ref([]);

		const fetchItems = async () => {
			try {
				const response = await api.post(
					"invoiceitems/?action=getInvoiceItems"
				);
				itemOptions.value = response.data;
			} catch (error) {
				console.error("Fehler beim Laden der Gegenstände:", error);
			}
		};

		const addItem = () => {
			invoice.value.items.push({
				id: null,
				description: "",
				price: 0,
				quantity: 1,
			});
		};

		const removeItem = (index: number) => {
			invoice.value.items.splice(index, 1);
		};

		const onItemSelected = (index: number, selectedId: number) => {
			const selectedItem = itemOptions.value.find(
				(opt) => opt.id === selectedId
			);
			if (selectedItem) {
				invoice.value.items[index].description =
					selectedItem.description;
				invoice.value.items[index].price = selectedItem.price;
				invoice.value.items[index].quantity = 1; // Standardmenge
			}
		};

		const saveInvoice = () => {
			emit("invoiceAdded", invoice.value);
		};

		const closeForm = () => {
			emit("closeForm");
		};

		onMounted(() => {
			fetchCompanies();
			fetchPersons();
			fetchItems();
		});

		return {
			invoice,
			companies,
			persons,
			companySearch,
			personSearch,
			filteredCompanies,
			filteredPersons,
			selectedCompanyId,
			selectedPersonId,
			clearCompanySelection,
			clearPersonSelection,
			loadCustomerData,
			itemOptions,
			addItem,
			removeItem,
			onItemSelected,
			saveInvoice,
			closeForm,
			linked,
		};
	},
});
</script>
