<template>
	<v-container>
		<v-btn @click="openCategorySelectionDialog" class="float-right" v-if="!newReportDialog && !editReportDialog && canEdit">Neuer Bericht</v-btn>
		<v-btn @click="filterReports('all')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Alle ({{ reportCounts.all }})</v-btn>
		<v-btn @click="filterReports('own')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Eigene ({{ reportCounts.own }})</v-btn>
		<v-btn @click="filterReports('missing')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Fehlende Berichtergänzungen ({{ reportCounts.missing }})</v-btn>
		<v-btn @click="filterReports('incomplete')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Alle nichtvollständige ({{ reportCounts.incomplete }})</v-btn>
		<v-btn @click="filterReports('open')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Offene Berichte ({{ reportCounts.open }})</v-btn>
		<v-btn @click="filterReports('pending')" class="mr-2" v-if="!newReportDialog && !editReportDialog">Ausstehende Prüfungen ({{ reportCounts.pending }})</v-btn>
		
		<v-data-table
			v-if="!newReportDialog && !editReportDialog"
			:headers="reportHeaders"
			:items="filteredReports"
			class="elevation-1 mt-2"
			:search="search"
			:items-per-page="5"
			>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Berichtverwaltung</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" label="Suche" class="mx-4 mt-3" variant="underlined" />
					<v-select
						v-model="filters.category_id"
						:items="categories"
						item-title="name"
						item-value="id"
						label="Filter nach Abteilung"
						clearable
						variant="underlined"
						class="mx-4 mt-3"
					/>
					<v-select
						v-model="filters.creator"
						:items="employees"
						item-title="display_name"
						item-value="id"
						label="Filter nach Ersteller"
						clearable
						variant="underlined"
						class="mx-4 mt-3"
					/>
				</v-toolbar>
			</template>
			<template v-slot:[`item.report_info`]="{ item }">
				<span size="small" class="me-2" @click="openEditReportDialog(item)" style="cursor: pointer">
					{{ item.title }}
					<span v-if="item.missing_employees.length > 0">(fehlende Aussagen: {{ item.missing_employees.length }})</span>
				</span>
			</template>
			<template v-slot:[`item.approved`]="{ item }">
				{{ item.pinned == 1 ? "📌 " : "" }}{{ item.approved == 0 ? "🔴" : "🟢" }}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon size="small" class="me-2" @click="openEditReportDialog(item)" v-if="canEdit">mdi-pencil</v-icon>
				<v-icon size="small" class="me-2" @click="openDeleteReportDialog(item)" v-if="canDelete">mdi-delete</v-icon>
				<v-icon size="small" class="me-2" @click="downloadReportPdf(item.id)">mdi-download</v-icon>
				<v-icon size="small" class="me-2" @click="pinReport(item.id)">
					{{ item.pinned == 1 ? "mdi-pin-off" : "mdi-pin" }}
				</v-icon>
			</template>
		</v-data-table>

		<!-- Category Selection Dialog -->
		<v-dialog v-model="categorySelectionDialog" max-width="400">
			<v-card>
				<v-card-title class="headline">Kategorie auswählen</v-card-title>
				<v-card-text>
					<v-select
						label="Kategorie"
						v-model="selectedCategory"
						:items="categories"
						item-title="name"
						item-value="id"
						required
						:rules="[requiredRule]"
					></v-select>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="categorySelectionDialog = false">Abbrechen</v-btn>
					<v-btn color="blue darken-1" text @click="createNewReport">Weiter</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<component
			:is="AuthorityAddReportDialog"
			v-bind="addReportProps"
			v-model="newReportDialog"
			:selectedCategory="selectedCategory"
			:newReportDialog="newReportDialog"
			@reportAdded="onReportAdded"
			@close="closeEditReportDialog()"
		/>

			<component
			:is="AuthorityEditReportDialog"
			v-bind="editReportProps"
			v-model="editReportDialog"
			:reportToEdit="editedReport"
			@reportUpdated="onReportUpdated"
			@close="closeEditReportDialog()"
		/>

		<!-- Delete Report Dialog -->
		<v-dialog v-model="deleteReportDialog" max-width="400" persistent>
			<v-card>
				<v-card-title class="headline">Bericht löschen</v-card-title>
				<v-card-text>
					Möchten Sie den Bericht "{{ selectedReport?.title }}" wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="deleteReportDialog = false">Abbrechen</v-btn>
					<v-btn color="blue darken-1" text @click="deleteReport(selectedReport)">Löschen</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Snackbar -->
		<v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
			{{ snackbar.message }}
		</v-snackbar>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import api from "@/api";
import { Report, BaseReport, FireReport, PoliceReport, Category, Employee, ReportCode } from "@/types/Report";
import { PersonFile } from "@/types/Person";
import { useStore } from "vuex";
import AuthorityAddReportDialog from "@/components/Reports/Authority/Add.vue";
import AuthorityEditReportDialog from "@/components/Reports/Authority/Edit.vue";
import { useRoute } from "vue-router";

export default defineComponent({
	components: {
		AuthorityAddReportDialog,
		AuthorityEditReportDialog,
	},
	setup() {
		const store = useStore();
		const errorSnackbar = ref<any>({ visible: false, message: "" });
		const reportHeaders = [
			{ title: "", align: "start", sortable: false, key: "approved" },
			{ title: "Titel", align: "start", sortable: false, key: "report_info" },
			{ title: "Kategorie", align: "start", sortable: false, key: "cat_name" },
			{ title: "Status", align: "start", sortable: false, key: "status_name" },
			{ title: "Ersteller", align: "start", sortable: false, key: "emp_name" },
			{ title: "Erstellt am", align: "start", sortable: false, key: "created_at" },
			{ title: "Letzte Änderung", align: "start", sortable: false, key: "updated_at" },
			{ title: "Aktionen", key: "actions", sortable: false },
		];

		const route = useRoute();

		const canEdit = computed(() => route.meta.canEdit);
		const canDelete = computed(() => route.meta.canDelete);

		const currentAuthority = computed(() => store.state.user.authority);

		// Props that are authority-specific and passed to the AuthorityAddReportDialog
		const addReportProps = computed(() => {
			switch (currentAuthority.value) {
				case "fire":
					return { title: "Feuerwehr-Bericht hinzufügen", authority: "fire" };
				case "fireguard":
					return { title: "Bericht hinzufügen", authority: "fireguard" };
				case "police":
					return { title: "Polizei-Bericht hinzufügen", authority: "police" };
				case "test":
					return { title: "Test-Bericht hinzufügen", authority: "test" };
				case "casa":
					return { title: "Casa-Bericht hinzufügen", authority: "casa" };
				case "medic":
					return { title: "Medizinischer Bericht hinzufügen", authority: "medic" };
				case "justice":
					return { title: "Justiz-Bericht hinzufügen", authority: "justice" };
				case "statepark":
					return { title: "State Park-Bericht hinzufügen", authority: "statepark" };
				default:
					return { title: "Bericht hinzufügen", authority: "default" }; // Fallback
			}
		});

		// Computed properties to provide dynamic props for editing a report
		const editReportProps = computed(() => {
			switch (currentAuthority.value) {
				case "fire":
					return { title: "Feuerwehr-Bericht bearbeiten", authority: "fire" };
				case "fireguard":
					return { title: "Bericht bearbeiten", authority: "fireguard" };
				case "police":
					return { title: "Polizei-Bericht bearbeiten", authority: "police" };
				case "test":
					return { title: "Test-Bericht bearbeiten", authority: "test" };
				case "casa":
					return { title: "Casa-Bericht bearbeiten", authority: "casa" };
				case "medic":
					return { title: "Medizinischer Bericht bearbeiten", authority: "medic" };
				case "justice":
					return { title: "Justiz-Bericht bearbeiten", authority: "justice" };
				case "statepark":
					return { title: "State Park-Bericht bearbeiten", authority: "statepark" };
				default:
					return { title: "Bericht bearbeiten", authority: "default" }; // Fallback
			}
		});

		const reports = ref<Report[]>([]);
		const categories = ref<Category[]>([]);
		const employees = ref<Employee[]>([]);
		const reportCodes = ref<ReportCode[]>([]);
		const newReportDialog = ref(false);
		const editReportDialog = ref(false);
		const deleteReportDialog = ref(false);
		const categorySelectionDialog = ref(false);
		const selectedReport = ref<Report | null>(null);
		const selectedCategory = ref<number | null>(null);
		const persons = ref<any[]>([]);
		const companies = ref<any[]>([]);

		const search = ref("");
		const filters = ref({
			category_id: null,
			creator: null,
			filterType: "all",
		});

		const reportCounts = computed(() => {
			return {
				all: reports.value.length,
				own: reports.value.filter((report) => report.creator === getCurrentUserId()).length,
				missing: reports.value.filter((report) => report.missing_employees.includes(getCurrentUserId())).length,
				incomplete: reports.value.filter((report) => report.missing_employees.length > 0).length,
				open: reports.value.filter((report) => report.approved === false).length,
				pending: reports.value.filter(
					(report) => report.missing_employees.length === 0 && report.approved === false
				).length,
			};
		});

		const filteredReports = computed(() => {
			const sortedReports = reports.value
				.map((report) => {
					const reportCode = reportCodes.value.find((code) => code.id === report.report_code_id);
					const reportCodeName = reportCode ? reportCode.code : "";
					return {
						...report,
						report_code_name: reportCodeName,
						formatted_report_date: formatDate(report.report_date),
						title: report.title || `${formatDate(report.report_date)} | ${reportCodeName} | ${report.location}`,
						pinned: report.pinned == "1" ? true : false,
					};
				})
				.filter((report) => {
					const matchesSearch = search.value
						? report.title.toLowerCase().includes(search.value.toLowerCase())
						: true;
					const matchesCategory = filters.value.category_id ? report.category_id === filters.value.category_id : true;
					const matchesCreator = filters.value.creator ? report.creator === filters.value.creator : true;
					const matchesFilterType = matchFilterType(report);
					return matchesSearch && matchesCategory && matchesCreator && matchesFilterType;
				})
				.sort((a, b) => {
					if (a.pinned && !b.pinned) return -1;
					if (!a.pinned && b.pinned) return 1;
					return 0;
				});
			return sortedReports;
		});

		const matchFilterType = (report: Report) => {
			switch (filters.value.filterType) {
				case "own":
					return report.creator == getCurrentUserId();
				case "missing":
					return report.missing_employees.includes(getCurrentUserId());
				case "incomplete":
					return report.missing_employees.length > 0;
				case "open":
					return report.approved === false;
				case "pending":
					return report.missing_employees.length === 0 && report.approved === false;
				default:
					return true;
			}
		};

		const getCurrentUserId = () => {
			return store.state.user.linked_employee;
		};

		// Typisierung von editedReport basierend auf der Behörde
		type EditedReportType = FireReport | PoliceReport | null;
		const editedReport = ref<EditedReportType>(null);

		const snackbar = ref({
			value: false,
			color: "",
			message: "",
		});

		const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

		const fetchReports = async () => {
			try {
				const response = await api.get("report/?action=getReports");
				const authority = store.state.user.authority;

				reports.value = response.data.map((entry) => {
					const baseReport: BaseReport = {
						id: entry.id,
						report_date: entry.report_date,
						title: entry.title,
						text: entry.text,
						description: entry.description,
						creator: Number(entry.creator),
						approved: entry.approved == "1",
						category_id: entry.category_id,
						cat_name: entry.cat_name || "Unbekannt", // Kategorie-Name hinzufügen
						status_name: entry.status_name || "Unbekannt", // Status-Name hinzufügen
						emp_name: entry.emp_name || "Unbekannter Ersteller", // Name des Erstellers hinzufügen
						created_at: entry.created_at, // Datum der Erstellung
						updated_at: entry.updated_at, // Datum der letzten Änderung
						missing_employees: entry.missing_employees ? entry.missing_employees.map(Number) : [],
						pinned: entry.pinned == "1", // Pinned-Status als Boolean
						report_code_id: entry.report_code_id,
						report_status_id: entry.report_status_id,
						location: entry.location,
						additionals: entry.additionals || [], // Zusätzliche Felder hinzufügen
						linked_persons: entry.linked_persons ? entry.linked_persons.map(Number) : [],
						linked_companies: entry.linked_companies ? entry.linked_companies.map(Number) : [],
					};

					if (authority === "fire") {
						const fireReport: FireReport = {
							...baseReport,
							causeOfFire: entry.causeOfFire || "",
							fireIntensity: entry.fireIntensity || 0,
							// Weitere Feuerwehr-spezifische Felder...
						};
						return fireReport;
					} else if (authority === "police" || authority === 'test') {
						const policeReport: PoliceReport = {
							...baseReport,
							crimeTime: entry.crimeTime || "",
							// Weitere Polizei-spezifische Felder...
						};
						return policeReport;
					}

					return baseReport; // Fallback für unbekannte Behörden
				});
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchCompanies = async () => {
			try {
				const response = await api.get("company/?action=getOnlyCompanies");
				companies.value = response.data;
			} catch (error) {
				console.error("Fehler beim Abrufen der Unternehmen:", error.response?.data?.error || error.message);
			}
		};


		const fetchCategories = async () => {
			try {
				const response = await api.get("report/?action=getCategories");
				categories.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchEmployees = async () => {
			try {
				const response = await api.get("report/?action=getEmployees");
				employees.value = response.data.map((emp: Employee) => ({
					...emp,
					display_name: `[${emp.servicenumber}] ${emp.name}`,
				}));
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					fullname: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchReportCodes = async () => {
			try {
				const response = await api.get("report/?action=getCodes");
				reportCodes.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const createNewReport = () => {
			categorySelectionDialog.value = false;
			newReportDialog.value = true;
		};

		const onReportAdded = async () => {
			await fetchReports();
		};

		const onReportUpdated = async () => {
			await fetchReports();
		};

		const logAccess = async (table: string, entryId: number) => {
            try {
                await api.post('logging/?action=logAccess', {
                    table,
                    entry_id: entryId
                });
                console.log('Access logged successfully for table:', table, 'entry ID:', entryId);
            } catch (error: any) {
                console.error('Error logging access:', error.response?.data?.error || error.message);
            }
        };

		const openEditReportDialog = (report: Report) => {
			editedReport.value = { ...report };
			editReportDialog.value = true;
			logAccess('reprts', editedReport.value.id);
		};

		// Typ-Guards für die Berichte
		function isFireReport(report: any): report is FireReport {
			return "causeOfFire" in report;
		}

		function isPoliceReport(report: any): report is PoliceReport {
			return "crimeScene" in report;
		}

		const openDeleteReportDialog = (report: Report) => {
			selectedReport.value = report;
			deleteReportDialog.value = true;
		};

		const deleteReport = async (report: Report) => {
			try {
				await api.post("report/?action=deleteReport", {
					id: report.id,
				});
				await fetchReports();
				deleteReportDialog.value = false;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const pinReport = async (reportId) => {
			try {
				await api.post("report/?action=pinReport", { id: reportId });
				await fetchReports();
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const filterReports = (filterType: string) => {
			filters.value.filterType = filterType;
		};

		const openCategorySelectionDialog = () => {
			selectedCategory.value = null;
			categorySelectionDialog.value = true;
		};

		const formatDate = (date: string) => {
			const options: Intl.DateTimeFormatOptions = {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
			};
			return new Date(date).toLocaleDateString("de-DE", options);
		};

		const downloadReportPdf = async (reportId: number) => {
			try {
				const response = await api.get(`report/?action=getReportPdf&report_id=${reportId}`, { responseType: "blob" });
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `report_${reportId}.pdf`);
				document.body.appendChild(link);
				link.click();
			} catch (error) {
				errorSnackbar.value.message = "Fehler beim Herunterladen des PDFs";
				errorSnackbar.value.visible = true;
			}
		};

		const closeEditReportDialog = () => {
			editReportDialog.value = false;
			selectedCategory.value = null;
			newReportDialog.value = false; //  Hier newReportDialog zurücksetzen
			editedReport.value = null; 
		};
		

		onMounted(async () => {
			await fetchReports();
			await fetchCategories();
			await fetchEmployees();
			await fetchPersons();
			await fetchReportCodes();
			await fetchCompanies();
		});

		return {
			reportHeaders,
			reports,
			filteredReports,
			newReportDialog,
			editedReport,
			editReportDialog,
			deleteReportDialog,
			selectedReport,
			deleteReport,
			openEditReportDialog,
			openDeleteReportDialog,
			errorSnackbar,
			snackbar,
			categories,
			categorySelectionDialog,
			selectedCategory,
			openCategorySelectionDialog,
			createNewReport,
			employees,
			search,
			filters,
			filterReports,
			reportCounts,
			reportCodes,
			formatDate,
			downloadReportPdf,
			pinReport,
			onReportAdded,
			onReportUpdated,
			requiredRule,
			addReportProps,
			editReportProps,
			AuthorityAddReportDialog,
			AuthorityEditReportDialog,
			closeEditReportDialog,
			canEdit,
			canDelete,
			companies
		};
	},
});
</script>
<style>

</style>