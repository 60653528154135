<template>
	<error-snackbar v-model:snackbar="errorSnackbar" />
	<v-container fluid>
		<v-btn @click="openCard(null)">Neue Rolle hinzufügen</v-btn>
		<v-data-table
			:headers="headers"
			:items="roles"
			class="elevation-1"
			v-if="!cardVisible"
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="mr-2" @click="openCard(item ?? null)"
					>mdi-pencil</v-icon
				>
			</template>
		</v-data-table>
		<v-card v-if="cardVisible" class="mt-5">
			<v-card-title>
				<span class="headline">{{ cardTitle }}</span>
			</v-card-title>
			<v-card-text>
				<v-tabs v-model="activeTab" background-color="primary" dark>
					<v-tab :value="0">Informationen</v-tab>
					<v-tab :value="1">Admin Rechte</v-tab>
					<v-tab :value="2">Allgemeine Rechte</v-tab>
				</v-tabs>
				<v-window v-model="activeTab">
					<v-window-item :value="0">
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Role Name"
										v-model="editedRole.name"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Description"
										v-model="editedRole.description"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Power"
										v-model="editedRole.power"
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-text-field
										label="Sort Order"
										v-model="editedRole.sort_order"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-window-item>
					<v-window-item :value="1">
					<v-container>
						<v-data-table
							:group-by="groupBy"
							:headers="permissionsHeaders"
							:items="adminPermissions"
							class="elevation-1"
							item-value="id"
							:items-per-page="-1"
						>
							<template v-slot:[`group-header`]="{ item, columns, toggleGroup, isGroupOpen }">
								<tr>
									<td :colspan="columns.length">
										<v-btn :icon="isGroupOpen(item) ? '$expand' : '$next'" size="small" variant="text" @click="toggleGroup(item)"></v-btn>
										{{ item.value }}
									</td>
								</tr>
							</template>
							<template v-slot:[`item.checkbox`]="{ item }">
								<v-checkbox
									v-model="editedRole.permissions"
									:label="item.name_alias"
									:value="item"
									:multiple="true"
								></v-checkbox>
							</template>
							<template v-slot:[`item.description`]="{ item }">
								<span>{{ item.description }}</span>
							</template>
						</v-data-table>
					</v-container>
				</v-window-item>
					<v-window-item :value="2">
					<v-container>
						<v-data-table
							:group-by="groupBy"
							:headers="permissionsHeaders"
							:items="generalPermissions"
							class="elevation-1"
							item-value="id"
							:items-per-page="-1"
						>
							<template v-slot:[`group-header`]="{ item, columns, toggleGroup, isGroupOpen }">
								<tr>
									<td :colspan="columns.length">
										<v-btn :icon="isGroupOpen(item) ? '$expand' : '$next'" size="small" variant="text" @click="toggleGroup(item)"></v-btn>
										{{ item.value }}
									</td>
								</tr>
							</template>
							<template v-slot:[`item.checkbox`]="{ item }">
								<v-checkbox
									v-model="editedRole.permissions"
									:label="item.name_alias"
									:value="item"
									:multiple="true"
								></v-checkbox>
							</template>
							<template v-slot:[`item.description`]="{ item }">
								<span>{{ item.description }}</span>
							</template>
							
						</v-data-table>
					</v-container>
				</v-window-item>
				</v-window>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="save">Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script lang="ts">
import {
	defineComponent,
	onMounted,
	ref,
	reactive,
	computed,
	watch,
} from "vue";
import api from "@/api";
import { Permission, Role, RolePermission } from "@/types/Roles";

export default defineComponent({
	setup() {
		const groupBy = [
			{
				key: 'site',
				order: 'asc',
			}
		];
		const permissionsHeaders = [
			{ text: 'Assigned', value: 'checkbox', sortable: false },
			{ text: 'Description', value: 'description' },
		];
		const errorSnackbar = ref<any>({ visible: false, message: "" });
		const headers = [
			{
				title: "Role Name",
				align: "start",
				sortable: false,
				key: "name",
			},
			{
				title: "Description",
				align: "start",
				sortable: false,
				key: "description",
			},
			{ title: "Power", align: "start", sortable: false, key: "power" },
			{
				title: "Sort Order",
				align: "start",
				sortable: false,
				key: "sort_order",
			},
			{
				title: "Actions",
				align: "start",
				sortable: false,
				key: "actions",
			},
		];

		const permissions = ref<any[]>([]);
		const roles = ref<Role[]>([]);
		const rolePermissions = ref<RolePermission[]>([]);
		const cardVisible = ref(false);
		const activeTab = ref(0);
		const editedRole = reactive<Partial<Role>>({
			name: "",
			description: "",
			sort_order: 0,
			power: 0,
			permissions: [],
		});

		const fetchPermissions = async () => {
			try {
				const response = await api.post(
					"admin/roles/?action=getPermissions"
				);
				permissions.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchRoles = async () => {
			try {
				const response = await api.post("admin/roles/?action=getRoles");
				roles.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const fetchRolePermissions = async () => {
			try {
				const response = await api.post(
					"admin/roles/?action=getRolePermissions"
				);
				rolePermissions.value = response.data;
			} catch (error) {
				errorSnackbar.value.message = error.response.data.error;
				errorSnackbar.value.visible = true;
			}
		};

		const openCard = (proxyRole) => {
			const role = proxyRole ? proxyRole : null;
			if (role) {
				editedRole.id = role.id;
				editedRole.name = role.name;
				editedRole.description = role.description;
				editedRole.sort_order = role.sort_order;
				editedRole.power = role.power;

				const assignedPermissionIds = rolePermissions.value
					.filter((rp) => rp.role_id === role.id)
					.map((rp) => rp.permission_id);

				const assignedPermissions = permissions.value.filter((p) =>
					assignedPermissionIds.includes(p.id)
				);

				editedRole.permissions = assignedPermissions;
			} else {
				editedRole.id = undefined;
				editedRole.name = "";
				editedRole.description = "";
				editedRole.sort_order = 0;
				editedRole.power = 0;
				editedRole.permissions = [];
			}
			cardVisible.value = true;
		};

		const close = () => {
			cardVisible.value = false;
		};

		const save = async () => {
			if (editedRole.id) {
				try {
					await api.post(
						"admin/roles/?action=updateRole",
						editedRole
					);
					await fetchPermissions();
					await fetchRoles();
					await fetchRolePermissions();
				} catch (error) {
					errorSnackbar.value.message = error.response.data.error;
					errorSnackbar.value.visible = true;
				}
			} else {
				try {
					await api.post(
						"admin/roles/?action=createRole",
						editedRole
					);
					await fetchPermissions();
					await fetchRoles();
					await fetchRolePermissions();
				} catch (error) {
					errorSnackbar.value.message = error.response.data.error;
					errorSnackbar.value.visible = true;
				}
			}
			close();
		};

		const groupedPermissions = ref({});

		const adminPermissions = computed(() =>
			permissions.value.filter((permission) =>
				permission.name.startsWith("ADMIN")
			)
		);

		const generalPermissions = computed(() =>
			permissions.value.filter(
				(permission) => !permission.name.startsWith("ADMIN")
			)
		);

		const updateGroupedPermissions = () => {
			const sortPrefixOrder = [
				"VIEW_",
				"ADMIN_VIEW_",
				"READ_",
				"ADMIN_READ_",
				"ADMIN_WRITE_",
				"WRITE_",
				"CREATE_",
				"DELETE_",
			];

			const sortedPermissions = permissions.value.slice().sort((a, b) => {
				const aPrefix = sortPrefixOrder.findIndex((prefix) =>
					a.name.startsWith(prefix)
				);
				const bPrefix = sortPrefixOrder.findIndex((prefix) =>
					b.name.startsWith(prefix)
				);

				if (aPrefix !== bPrefix) {
					return aPrefix - bPrefix;
				}

				return a.name.localeCompare(b.name);
			});

			const newGroupedPermissions = sortedPermissions.reduce(
				(groups, permission) => {
					const site = permission.site;
					if (!groups[site]) {
						groups[site] = [];
					}
					groups[site].push(permission);
					return groups;
				},
				{}
			);

			groupedPermissions.value = newGroupedPermissions;
		};

		watch(permissions, updateGroupedPermissions, { immediate: true });

		onMounted(async () => {
			await fetchPermissions();
			await fetchRoles();
			await fetchRolePermissions();
		});

		return {
			groupBy,
			permissionsHeaders,
			headers,
			roles,
			permissions,
			rolePermissions,
			cardVisible,
			activeTab,
			editedRole,
			fetchPermissions,
			fetchRoles,
			openCard,
			close,
			save,
			groupedPermissions,
			adminPermissions,
			generalPermissions,
			errorSnackbar,
			cardTitle: computed(() =>
				editedRole.id ? "Edit Role" : "Add Role"
			),
		};
	},
});
</script>

<style scoped>
.checkbox-spacing {
	margin-bottom: -40px;
}
</style>