<template>
    <v-container>
      <v-data-table :headers="itemHeaders" :items="items" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Items verwalten</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn @click="openNewItemDialog" v-if="canEdit">Neues Item</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon size="small" class="me-2" @click="openEditItemDialog(item)" v-if="canEdit">mdi-pencil</v-icon>
          <v-icon size="small" class="me-2" @click="openDeleteItemDialog(item)" v-if="canDelete">mdi-delete</v-icon>
        </template>
      </v-data-table>
  
      <!-- New Item Dialog -->
      <v-dialog v-model="newItemDialog" max-width="60%">
        <v-card>
          <v-card-title>Neues Item</v-card-title>
          <v-card-text>
            <v-form ref="newItemForm">
              <v-text-field label="Name" v-model="newItem.item_name" required :rules="[requiredRule]"></v-text-field>
              <v-textarea label="Beschreibung" v-model="newItem.description"></v-textarea>
              <v-text-field
                label="Preis"
                v-model="newItem.price"
                type="number"
                min="0"
                required
                :rules="[requiredRule]"
              ></v-text-field>
              <v-text-field
                label="Menge"
                v-model="newItem.quantity"
                type="number"
                min="1"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newItemDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="addNewItem" :disabled="!isFormValid(newItem)">Hinzufügen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Edit Item Dialog -->
      <v-dialog v-model="editItemDialog" max-width="60%">
        <v-card>
          <v-card-title>Item bearbeiten</v-card-title>
          <v-card-text>
            <v-form ref="editItemForm">
              <v-text-field label="Name" v-model="editedItem.item_name" required :rules="[requiredRule]"></v-text-field>
              <v-textarea label="Beschreibung" v-model="editedItem.description"></v-textarea>
              <v-text-field
                label="Preis"
                v-model="editedItem.price"
                type="number"
                min="0"
                required
                :rules="[requiredRule]"
              ></v-text-field>
              <v-text-field
                label="Menge"
                v-model="editedItem.quantity"
                type="number"
                min="1"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editItemDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="updateItem">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Delete Item Dialog -->
      <v-dialog v-model="deleteItemDialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Item löschen</v-card-title>
          <v-card-text>
            Möchten Sie das Item "{{ selectedItem?.item_name }}" wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteItemDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Snackbar -->
      <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
        {{ snackbar.message }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, computed } from "vue";
  import api from "@/api";
  import { useRoute } from "vue-router";
  
  export default defineComponent({
    setup() {
      const itemHeaders = [
        { title: "Name", align: "start", sortable: false, key: "item_name" },
        { title: "Beschreibung", align: "start", sortable: false, key: "description" },
        { title: "Preis", align: "start", sortable: false, key: "price" },
        { title: "Menge", align: "start", sortable: false, key: "quantity" },
        { title: "Aktionen", key: "actions", sortable: false },
      ];
  
      const items = ref([]);
      const newItemDialog = ref(false);
      const editItemDialog = ref(false);
      const deleteItemDialog = ref(false);
      const selectedItem = ref(null);

      const route = useRoute();

      const canEdit = computed(() => route.meta.canEdit);
      const canDelete = computed(() => route.meta.canDelete);
  
      const newItem = ref({
        item_name: "",
        description: "",
        price: 0,
        quantity: 1,
      });
  
      const editedItem = ref({
        id: 0,
        item_name: "",
        description: "",
        price: 0,
        quantity: 1,
      });
  
      const snackbar = ref({ value: false, color: "", message: "" });
  
      const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";
  
      const fetchItems = async () => {
        try {
          const response = await api.get("invoiceitems/?action=getInvoiceItems");
          items.value = response.data;
        } catch (error) {
          snackbar.value = { value: true, color: "red", message: error.response.data.error };
        }
      };
  
      const addNewItem = async () => {
        try {
          await api.post("invoiceitems/?action=addInvoiceItem", newItem.value);
          await fetchItems();
          newItemDialog.value = false;
          newItem.value = { item_name: "", description: "", price: 0, quantity: 1 }; // Reset
        } catch (error) {
          snackbar.value = { value: true, color: "red", message: error.response.data.error };
        }
      };
  
      const openEditItemDialog = (item) => {
        editedItem.value = { ...item };
        editItemDialog.value = true;
      };
  
      const updateItem = async () => {
        try {
          await api.post("invoiceitems/?action=editInvoiceItem", editedItem.value);
          await fetchItems();
          editItemDialog.value = false;
        } catch (error) {
          snackbar.value = { value: true, color: "red", message: error.response.data.error };
        }
      };
  
      const openDeleteItemDialog = (item) => {
        selectedItem.value = item;
        deleteItemDialog.value = true;
      };
  
      const deleteItem = async (item) => {
        try {
          await api.post("invoiceitems/?action=deleteInvoiceItem", { id: item.id });
          await fetchItems();
          deleteItemDialog.value = false;
        } catch (error) {
          snackbar.value = { value: true, color: "red", message: error.response.data.error };
        }
      };
  
      function isFormValid(form) {
        return form.item_name !== "" && form.price > 0 && form.quantity > 0;
      }
  
      const openNewItemDialog = () => {
        newItem.value = { item_name: "", description: "", price: 0, quantity: 1 };
        newItemDialog.value = true;
      };
  
      onMounted(fetchItems);
  
      return {
        itemHeaders,
        items,
        newItemDialog,
        newItem,
        addNewItem,
        requiredRule,
        editItemDialog,
        editedItem,
        updateItem,
        deleteItemDialog,
        selectedItem,
        deleteItem,
        openEditItemDialog,
        openDeleteItemDialog,
        isFormValid,
        snackbar,
        openNewItemDialog,
        canEdit,
        canDelete
      };
    },
  });
  </script>
  