import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9362a66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-h6 mt-2" }
const _hoisted_2 = { class: "text-h7 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_tab_item = _resolveComponent("v-tab-item")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                (_ctx.canEdit)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openApplicationDialog()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Neuer Bewerber ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  "open-on-hover": "",
                  "close-on-content-click": false,
                  class: "ma-1"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps(props)), {
                      default: _withCtx(() => [
                        _createTextVNode(" Filter ")
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, { class: "custom-checkbox-list" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobTypes, (jobType) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: jobType.id,
                            class: "my-n4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_checkbox, {
                                    label: jobType.name,
                                    modelValue: _ctx.selectedJobTypes[jobType.id],
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.selectedJobTypes[jobType.id]) = $event)
                                  }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128)),
                        _createVNode(_component_v_divider, { class: "mt-n8" }),
                        _createVNode(_component_v_list_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Ausstehend",
                                  modelValue: _ctx.filterStatus.pending,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterStatus.pending) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, { class: "mt-n12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Abgelehnt",
                                  modelValue: _ctx.filterStatus.rejected,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterStatus.rejected) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_list_item, { class: "mt-n12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Angenommen",
                                  modelValue: _ctx.filterStatus.approved,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterStatus.approved) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.nameFilter,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.nameFilter) = $event)),
                  label: "Nach Name suchen",
                  "hide-details": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.sortOrder,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sortOrder) = $event)),
                  items: ['Zuletzt hinzugefügt', 'Name A-Z', 'Name Z-A', 'Datum aufsteigend', 'Datum absteigend'],
                  label: "Sortierreihenfolge"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredApplicants, (applicant) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                key: applicant.id,
                cols: "6",
                sm: "4",
                md: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    class: _normalizeClass([_ctx.cardClass(applicant.status), "d-flex flex-column align-center py-6"]),
                    dark: "",
                    onClick: ($event: any) => (_ctx.openEditDialog(applicant))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { size: "60" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(applicant.type == 2 ? "mdi-account":"mdi-fire-truck"), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_1, _toDisplayString(applicant.name), 1),
                      _createElementVNode("div", _hoisted_2, _toDisplayString(" Termin: " + _ctx.formatDate(applicant.jobinterviewDate) + " "  + applicant.jobinterviewTime), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.dialog) = $event)),
          persistent: "",
          fullscreen: "",
          class: "custom-dialog"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { title: "Neuer Bewerber" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, {
                      ref: "formRef",
                      modelValue: _ctx.valid,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.valid) = $event)),
                      "lazy-validation": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, null, {
                          default: _withCtx(() => [
                            (!_ctx.applicationData.newApplication)
                              ? (_openBlock(), _createBlock(_component_v_tabs, {
                                  key: 0,
                                  modelValue: _ctx.activeTab,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.activeTab) = $event)),
                                  class: "mb-8"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_tab, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Informationen")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_tab, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Fragen")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]))
                              : _createCommentVNode("", true),
                            (_ctx.activeTab === 0)
                              ? (_openBlock(), _createBlock(_component_v_tab_item, { key: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_select, {
                                              modelValue: _ctx.applicationData.type,
                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.applicationData.type) = $event)),
                                              items: _ctx.jobTypes,
                                              "item-title": "name",
                                              "item-value": "id",
                                              label: "Job Type",
                                              required: ""
                                            }, null, 8, ["modelValue", "items"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_select, {
                                              modelValue: _ctx.applicationData.status,
                                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.applicationData.status) = $event)),
                                              items: _ctx.jobStatusTypes,
                                              "item-title": "text",
                                              "item-value": "value",
                                              label: "Bewerbungsstatus",
                                              required: ""
                                            }, null, 8, ["modelValue", "items"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _ctx.applicationData.name,
                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.applicationData.name) = $event)),
                                              modelModifiers: { lazy: true },
                                              rules: [_ctx.requiredRule],
                                              label: "Name",
                                              required: ""
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _ctx.applicationData.email,
                                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.applicationData.email) = $event)),
                                              modelModifiers: { lazy: true },
                                              rules: [_ctx.requiredRule],
                                              label: "Venture Mail",
                                              required: ""
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _ctx.applicationData.phonenumber,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.applicationData.phonenumber) = $event)),
                                              label: "Telefonnummer",
                                              required: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _ctx.applicationData.birthdate,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.applicationData.birthdate) = $event)),
                                              modelModifiers: { lazy: true },
                                              rules: [_ctx.requiredRule],
                                              label: "Geburtstag",
                                              type: "date",
                                              required: ""
                                            }, null, 8, ["modelValue", "rules"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, {
                                                  cols: "12",
                                                  sm: "6"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      modelValue: _ctx.applicationData.jobinterviewDate,
                                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.applicationData.jobinterviewDate) = $event)),
                                                      modelModifiers: { lazy: true },
                                                      rules: [_ctx.requiredRule],
                                                      label: "Bewerbungsgespräch Termin (Datum)",
                                                      type: "date",
                                                      required: ""
                                                    }, null, 8, ["modelValue", "rules"])
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_col, {
                                                  cols: "12",
                                                  sm: "6"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_text_field, {
                                                      modelValue: _ctx.applicationData.jobinterviewTime,
                                                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.applicationData.jobinterviewTime) = $event)),
                                                      modelModifiers: { lazy: true },
                                                      rules: [_ctx.requiredRule],
                                                      label: "Bewerbungsgespräch Termin (Zeit)",
                                                      type: "time",
                                                      required: ""
                                                    }, null, 8, ["modelValue", "rules"])
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_checkbox, {
                                              modelValue: _ctx.applicationData.addToCalendar,
                                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.applicationData.addToCalendar) = $event)),
                                              label: "Zu Kalendar hinzufügen"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_textarea, {
                                              name: "input-7-1",
                                              variant: "filled",
                                              label: "Informationen",
                                              "auto-grow": "",
                                              modelValue: _ctx.applicationData.info,
                                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.applicationData.info) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.activeTab === 1)
                              ? (_openBlock(), _createBlock(_component_v_tab_item, { key: 2 }, {
                                  default: _withCtx(() => [
                                    (!_ctx.applicationData.newApplication)
                                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.applicationData.answers, (question) => {
                                          return (_openBlock(), _createBlock(_component_v_row, {
                                            key: question.question_id
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_col, { cols: "12" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_text_field, {
                                                    modelValue: question.answer,
                                                    "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
                                                    label: question.question,
                                                    "return-object": ""
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1 mr-8",
                      text: "true",
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.dialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1 mr-8",
                      text: "true",
                      disabled: !_ctx.isFormValid(),
                      onClick: _ctx.submitApplication
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}