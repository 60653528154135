import { createStore } from "vuex";
import jwtDecode from 'jwt-decode';
import api from "@/api";  // Import the API

export interface State {
    user: { 
        id: number; 
        username: string; 
        roles: string[]; 
        permissions: string[]; 
        mail_header: string; 
        mail_footer: string; 
        mail_header_neutral: string; 
        mail_footer_neutral: string; 
        signature: string; 
        documentView: string; 
        linked_employee: number; 
        message_groups: number[]; // Array to store group IDs the user belongs to
        unreadMessagesCount: number; // Number of unread messages
        todayEventsCount: number; // Number of unread messages
        last_login: string;
        name: string;
        authority: string;
    } | null;
    jwt: string | null;
    tokenExpiry: number | null;
}

export const isAuthenticated = (state: State) => {
    return !!state.user && !!state.jwt;
};

export function isTokenExpired(state: State): boolean {
    const tokenExpiry = state.tokenExpiry;
    if (!tokenExpiry) {
        return true;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= tokenExpiry;
}

export default createStore({
    state: {
        user: null,
        jwt: null,
        tokenExpiry: null,
        isLoadingUser: true,  // Add a loading state
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setJwt(state, jwt) {
            state.jwt = jwt;
            if (jwt) {
                const decodedToken: any = jwtDecode(jwt);
                state.tokenExpiry = decodedToken.exp;
            } else {
                state.tokenExpiry = null;
            }
        },
        clearUser(state) {
            state.user = null;
        },
        clearJwt(state) {
            state.jwt = null;
            state.tokenExpiry = null;
        },
        setHeaderImage(state, headerImage) {
            if (state.user) {
                state.user.mail_header = headerImage;
            }
        },
        setFooterImage(state, footerImage) {
            if (state.user) {
                state.user.mail_footer = footerImage;
            }
        },
        setNeutralHeaderImage(state, headerImage) {
            if (state.user) {
                state.user.mail_header_neutral = headerImage;
            }
        },
        setNeutralFooterImage(state, footerImage) {
            if (state.user) {
                state.user.mail_footer_neutral = footerImage;
            }
        },
        setSignature(state, signature) {
            if (state.user) {
                state.user.signature = signature;
            }
        },
        setPermissions(state, permissions) {
            if (state.user) {
                state.user.permissions = permissions;
            }
        },
        setDocumentView(state, view) {
            if (state.user) {
                state.user.documentView = view;
            }
        },
        setUnreadMessagesCount(state, count) {
            if (state.user) {
                state.user.unreadMessagesCount = count;
            }
        },
        setTodayEventsCount(state, count) {
            if (state.user) {
                state.user.todayEventsCount = count;
            }
        },
        setMessageGroups(state, groups) {
            if (state.user) {
                state.user.message_groups = groups;
            }
        },
        setIsLoadingUser(state, isLoading) {
            state.isLoadingUser = isLoading;
        },
    },
    actions: {
        authenticate({ commit }, { user, jwt }) {
            commit("setUser", user);
            commit("setJwt", jwt);
            commit("setPermissions", user.permissions);

            // Speichern Sie die Benutzerdaten und den JWT-Token im Local Storage
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('jwt', jwt);
        },
        async reloadUserData({ commit }) {
            commit('setIsLoadingUser', true); // Start loading
            try {
                const response = await api.get("user/?action=getUserOverview");
                const response2 = await api.post("account/?action=checkUser");
                const { permissions, banned } = response2.data;
                if (response.data) {
                    const user = response.data;
                    commit("setUser", user);
                    commit("setPermissions", permissions);
                    
                    localStorage.setItem('user', JSON.stringify(user));
                    commit('setIsLoadingUser', false); // Done loading
                }
            } catch (error) {
                console.error("Error reloading user data:", error);
                commit('setIsLoadingUser', false); // Done loading (even with error)
            } finally {
              commit('setIsLoadingUser', false); // Ensure loading is set to false in all cases.
            }
        },
        restoreAuthentication({ commit }) {
            const storedUser = localStorage.getItem('user');
            const storedJwt = localStorage.getItem('jwt');
            if (storedUser && storedJwt) {
                const user = JSON.parse(storedUser);
                commit("setUser", user);
                commit("setJwt", storedJwt);
            }
        },
        logout({ commit }) {
            commit("clearUser");
            commit("clearJwt");

            localStorage.removeItem('user');
            localStorage.removeItem('jwt');
        },
        updateJwt({ commit }, token) {
            commit("setJwt", token);
        },
        updateUnreadMessagesCount({ commit }, count) {
            commit("setUnreadMessagesCount", count);
        },
        updateTodayEventsCount({ commit }, count) {
            commit("setTodayEventsCount", count);
        },
        updateMessageGroups({ commit }, groups) {
            commit("setMessageGroups", groups);
        },
    },
});