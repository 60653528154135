<template>
  <v-container>
    <v-data-table :headers="typeHeaders" :items="companyTypes" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Company Types verwalten</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="openNewTypeDialog" v-if="canEdit">Neuer Typ</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="small" class="me-2" @click="openEditTypeDialog(item)" v-if="canEdit">mdi-pencil</v-icon>
        <v-icon size="small" class="me-2" @click="openDeleteTypeDialog(item)" v-if="canDelete">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- New Type Dialog -->
    <v-dialog v-model="newTypeDialog" max-width="60%">
      <v-card>
        <v-card-title>Neuer Typ</v-card-title>
        <v-card-text>
          <v-form ref="newTypeForm">
            <v-text-field label="Name" v-model="newType.name" required :rules="[requiredRule]"></v-text-field>
            <v-textarea label="Beschreibung" v-model="newType.description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newTypeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="addNewType" :disabled="!isFormValid(newType)">Hinzufügen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Type Dialog -->
    <v-dialog v-model="editTypeDialog" max-width="60%">
      <v-card>
        <v-card-title>Typ bearbeiten</v-card-title>
        <v-card-text>
          <v-form ref="editTypeForm">
            <v-text-field label="Name" v-model="editedType.name" required :rules="[requiredRule]"></v-text-field>
            <v-textarea label="Beschreibung" v-model="editedType.description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editTypeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="updateType">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Type Dialog -->
    <v-dialog v-model="deleteTypeDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">Typ löschen</v-card-title>
        <v-card-text>
          Möchten Sie den Typ "{{ selectedType?.name }}" wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteTypeDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteType(selectedType)">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>


<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { CompanyType } from '@/types/Company';
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const errorSnackbar = ref<any>({ visible: false, message: '' });
    const typeHeaders = [
      { title: 'Name', align: 'start', sortable: false, key: 'name' },
      { title: 'Beschreibung', align: 'start', sortable: false, key: 'description' },
      { title: 'Aktionen', key: 'actions', sortable: false }
    ];

    const companyTypes = ref<CompanyType[]>([]);
    const newTypeDialog = ref(false);
    const editTypeDialog = ref(false);
    const deleteTypeDialog = ref(false);
    const selectedType = ref<CompanyType | null>(null);

    const route = useRoute();

    const canEdit = computed(() => route.meta.canEdit);
    const canDelete = computed(() => route.meta.canDelete);

    const newType = ref({
      name: '',
      description: ''
    });

    const editedType = ref<CompanyType>({
      id: 0,
      name: '',
      description: ''
    });

    const snackbar = ref({
      value: false,
      color: '',
      message: ''
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchCompanyTypes = async () => {
      try {
        const response = await api.get('company/?action=getCompanyTypes');
        companyTypes.value = response.data.map((type: CompanyType) => ({
          ...type,
          description: type.description || ''
        }));
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const addNewType = async () => {
      try {
        await api.post('company/?action=addCompanyType', newType.value);
        await fetchCompanyTypes();
        newTypeDialog.value = false;
        newType.value = { name: '', description: '' }; // Reset
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openEditTypeDialog = (type: CompanyType) => {
      editedType.value = { ...type };
      editTypeDialog.value = true;
    };

    const updateType = async () => {
      try {
        await api.post('company/?action=editCompanyType', editedType.value);
        await fetchCompanyTypes();
        editTypeDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openDeleteTypeDialog = (type: CompanyType) => {
      selectedType.value = type;
      deleteTypeDialog.value = true;
    };

    const deleteType = async (type: CompanyType) => {
      try {
        await api.post('company/?action=deleteCompanyType', { id: type.id });
        await fetchCompanyTypes();
        deleteTypeDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    function isFormValid(form: { name: string; description: string }) {
      return form.name !== '';
    }

    const openNewTypeDialog = () => {
      newType.value = { name: '', description: '' };
      newTypeDialog.value = true;
    };

    onMounted(async () => {
      await fetchCompanyTypes();
    });

    return {
      typeHeaders,
      companyTypes,
      newTypeDialog,
      newType,
      addNewType,
      requiredRule,
      editTypeDialog,
      editedType,
      updateType,
      deleteTypeDialog,
      selectedType,
      deleteType,
      openEditTypeDialog,
      openDeleteTypeDialog,
      isFormValid,
      errorSnackbar,
      snackbar,
      openNewTypeDialog,
      canEdit,
      canDelete
    };
  }
});

</script>

<style scoped>
</style>
