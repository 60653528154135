<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container>
      <v-row>
        <v-col cols="auto">
            <v-btn @click="openApplicationDialog()" v-if="canEdit">
                Neuer Bewerber
            </v-btn>
        </v-col>

            <v-col cols="auto">
                <v-menu
                    open-on-hover
                    :close-on-content-click="false"
                    class="ma-1"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            v-bind="props"
                        >
                            Filter
                        </v-btn>
                    </template>

                    <v-list class="custom-checkbox-list">
                        <v-list-item v-for="jobType in jobTypes" :key="jobType.id" class="my-n4">
                        <v-list-item-title>
                            <v-checkbox :label="jobType.name" v-model="selectedJobTypes[jobType.id]"></v-checkbox>
                        </v-list-item-title>
                    </v-list-item>



                    
                    <v-divider class="mt-n8"></v-divider>

                        <v-list-item>
                            <v-list-item-title>
                                <v-checkbox label="Ausstehend" v-model="filterStatus.pending"></v-checkbox>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="mt-n12">
                            <v-list-item-title>
                                <v-checkbox label="Abgelehnt" v-model="filterStatus.rejected"></v-checkbox>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="mt-n12">
                            <v-list-item-title>
                                <v-checkbox label="Angenommen" v-model="filterStatus.approved"></v-checkbox>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                v-model="nameFilter"
                label="Nach Name suchen"
                hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
            <v-select
                v-model="sortOrder"
                :items="['Zuletzt hinzugefügt', 'Name A-Z', 'Name Z-A', 'Datum aufsteigend', 'Datum absteigend']"
                label="Sortierreihenfolge"
            ></v-select>
            </v-col>
        </v-row>

  
        <v-row>
            <v-col v-for="applicant in filteredApplicants" :key="applicant.id" cols="6" sm="4" md="2">
                <v-card :class="cardClass(applicant.status)" class="d-flex flex-column align-center py-6" dark @click="openEditDialog(applicant)">
                    <v-icon size="60">{{ applicant.type == 2 ? "mdi-account":"mdi-fire-truck" }}</v-icon>
                    <div class="text-h6 mt-2">{{ applicant.name }} </div>
                    <div class="text-h7 mt-2">{{ " Termin: " + formatDate(applicant.jobinterviewDate) + " "  + applicant.jobinterviewTime}}</div>
                </v-card>
            </v-col>
        </v-row>
  
        <v-dialog v-model="dialog" persistent fullscreen class="custom-dialog">
            <v-card title="Neuer Bewerber">
            <v-card-text>
                <v-form ref="formRef" v-model="valid" lazy-validation>
                    <v-container>
                        <v-tabs v-model="activeTab"  v-if="!applicationData.newApplication" class="mb-8">
                            <v-tab>Informationen</v-tab>
                            <v-tab>Fragen</v-tab>
                        </v-tabs>
                        <v-tab-item v-if="activeTab === 0">
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        v-model="applicationData.type"
                                        :items="jobTypes"
                                        item-title="name"
                                        item-value="id"
                                        label="Job Type"
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="applicationData.status"
                                        :items="jobStatusTypes"
                                        item-title="text"
                                        item-value="value"
                                        label="Bewerbungsstatus"
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model.lazy="applicationData.name"
                                    :rules="[requiredRule]"
                                    label="Name"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model.lazy="applicationData.email"
                                    :rules="[requiredRule]"
                                    label="Venture Mail"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="applicationData.phonenumber"
                                    label="Telefonnummer"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model.lazy="applicationData.birthdate"
                                    :rules="[requiredRule]"
                                    label="Geburtstag"
                                    type="date"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                            v-model.lazy="applicationData.jobinterviewDate"
                                            :rules="[requiredRule]"
                                            label="Bewerbungsgespräch Termin (Datum)"
                                            type="date"
                                            required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                            v-model.lazy="applicationData.jobinterviewTime"
                                            :rules="[requiredRule]"
                                            label="Bewerbungsgespräch Termin (Zeit)"
                                            type="time"
                                            required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox
                                    v-model="applicationData.addToCalendar"
                                    label="Zu Kalendar hinzufügen"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        name="input-7-1"
                                        variant="filled"
                                        label="Informationen"
                                        auto-grow
                                        v-model="applicationData.info"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item v-if="activeTab === 1">
                            <template v-if="!applicationData.newApplication" >
                                <v-row v-for="question in applicationData.answers" :key="question.question_id">
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="question.answer"
                                    :label="question.question"
                                    return-object
                                    ></v-text-field>
                                </v-col>
                                </v-row>
                            </template>
                        </v-tab-item>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1 mr-8" text="true" @click="dialog = false">Abbrechen</v-btn>
                <v-btn color="blue darken-1 mr-8" text="true" :disabled="!isFormValid()" @click="submitApplication">Speichern</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </v-container>
    </template>
  
  <script lang="ts">
    import { defineComponent, ref, computed, watchEffect } from 'vue';
    import { Applicant, Question, ApplicationData } from '@/types/Application';
    import { Application_Type } from '@/enums/Application';
    import { useStore } from 'vuex';
    import api from "@/api";
    import { JobTypes } from '@/types/Members';
    import { onMounted } from 'vue';
    import { useRoute } from "vue-router";

  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
      const formRef = ref<any | null>(null);
      const store = useStore();
      const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";
      const valid = ref(true)
      const isLoading = ref(false);
      const dialog = ref(false);
      const activeTab = ref(0);
      const nameFilter = ref("");
      const sortOrder = ref("Zuletzt hinzugefügt");
      const filterMenu = ref(false);
      const filterStatus = ref({
        pending: true,
        rejected: true,
        approved: true,
      });
      const applicationType = ref(0);
      const applicationData = ref<ApplicationData>({
            newApplication: false,
            id: 0,
            name: '',
            type: 1,
            email: '',
            phonenumber: '',
            birthdate: '',
            status: 'pending',
            info: '',
            jobinterviewDate: '',
            jobinterviewTime: '00:00',
            added: '',
            addToCalendar: false,
            answers: [],
      });

      const route = useRoute();

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);


        const selectedJobTypes = ref<Record<number, boolean>>({});
        const jobTypes = ref<JobTypes[]>([]);
        async function fetchJobTypes() {
            try {
                const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                jobTypes.value = response.data;

                // Setzen Sie alle Jobtypen als ausgewählt
                for (const jobType of jobTypes.value) {
                    selectedJobTypes.value[jobType.id] = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                jobTypes.value = [];
            }
        }


        onMounted(async() => {
            await fetchJobTypes();
        })

        const jobStatusTypes = [
            { text: "Ausstehend", value: "pending" },
            { text: "Angenommen", value: "approved" },
            { text: "Abgelehnt", value: "rejected" },
        ];
        const applicants = ref<Applicant[]>([]);
        const questions = ref<Question[]>([]);
    
        const filteredQuestions = computed(() => {
            return questions.value.filter(
            (question) => 
                (Number(question.type) === applicationType.value) || Number(question.type) === Application_Type.Both);
        });
  
        const filteredApplicants = computed(() => {
            let filtered = applicants.value.filter(
                (applicant) =>
                (selectedJobTypes.value[Number(applicant.type)] &&
                    (
                    (filterStatus.value.pending && applicant.status === 'pending') ||
                    (filterStatus.value.rejected && applicant.status === 'rejected') ||
                    (filterStatus.value.approved && applicant.status === 'approved')
                    ) &&
                    (nameFilter.value === "" || applicant.name.toLowerCase().includes(nameFilter.value.toLowerCase())))
            );

            if (sortOrder.value === "Zuletzt hinzugefügt") {
                filtered.sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime());
            } else if (sortOrder.value === "Name A-Z") {
                filtered.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortOrder.value === "Name Z-A") {
                filtered.sort((a, b) => b.name.localeCompare(a.name));
            } else if (sortOrder.value === "Datum aufsteigend") {
                filtered.sort((a, b) => new Date(a.jobinterviewDate).getTime() - new Date(b.jobinterviewDate).getTime());
            } else if (sortOrder.value === "Datum absteigend") {
                filtered.sort((a, b) => new Date(b.jobinterviewDate).getTime() - new Date(a.jobinterviewDate).getTime());
            }

            return filtered;
        });

  
      async function fetchApplicants() {
        try {
          const response = await api.get('application/?action=getApplicants');
          applicants.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
          applicants.value = [];
        }
      }
  
      async function fetchQuestions() {
        try {
          const response = await api.get('application/?action=getQuestions');
          questions.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
          questions.value = [];
        }
      }
  
      watchEffect(() => {
        fetchApplicants();
        fetchQuestions();
      });
  
      function openApplicationDialog() {
            applicationData.value = {
                newApplication: false,
                name: '',
                id: 0,
                type: 1,
                email: '',
                phonenumber: '',
                birthdate: '',
                status: 'pending',
                info: '',
                jobinterviewDate: '',
                jobinterviewTime: '',
                added: '',
                addToCalendar: false,
                answers: [],
            };
        applicationData.value.newApplication = true;
        dialog.value = true;
      }
  
      function isFormValid() {
        return (
          applicationData.value.name !== '' &&
          applicationData.value.email !== '' &&
          applicationData.value.birthdate !== '' &&
          applicationData.value.jobinterviewDate !== '' &&
          applicationData.value.jobinterviewTime !== ''
        );
      }
  
      function formatDate(date: any) {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
  
      function openEditDialog(applicant: Applicant) {
        applicationType.value = applicant.type;
        applicationData.value = {
            newApplication: applicant.newApplication,
            name: applicant.name,
            id: applicant.id,
            email: applicant.email,
            type: applicant.type,
            phonenumber: applicant.phonenumber,
            birthdate: applicant.birthdate,
            status: applicant.status,
            info: applicant.info,
            jobinterviewDate: applicant.jobinterviewDate,
            jobinterviewTime: applicant.jobinterviewTime,
            answers: applicant.answers,
            addToCalendar: Boolean(applicant.addToCalendar)
        } as Applicant;
        dialog.value = true;
      }
  
        async function submitApplication() {
            if (formRef.value?.validate()) {
                if (isLoading.value) return;
                isLoading.value = true;
                try {
                    const jwt = store.state.jwt;
                    const response = await api.post("application/?action=submitApplication", applicationData.value);
        
                    await fetchApplicants();
                    await fetchQuestions();
        
                    formRef.value.reset();
                    dialog.value = false;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                } finally {
                    isLoading.value = false;
                }
            }
        }
  
      function cardClass(status: string): string {
        if (status === "pending") {
          return "bg-orange-darken-4";
        } else if (status === "rejected") {
          return "bg-red-darken-4";
        } else if (status === "approved") {
          return "bg-green-darken-4";
        }
        return "";
      }


  
      return {
        formRef,
        requiredRule,
        store,
        valid,
        jobStatusTypes,
        jobTypes,
        isLoading,
        dialog,
        activeTab,
        nameFilter,
        sortOrder,
        filterMenu,
        filterStatus,
        applicationType,
        applicationData,
        applicants,
        questions,
        filteredQuestions,
        filteredApplicants,
        openApplicationDialog,
        isFormValid,
        formatDate,
        openEditDialog,
        submitApplication,
        cardClass,
        selectedJobTypes,
        errorSnackbar,
        canEdit,
        canDelete
      };
    },
  });
  </script>


<style scoped>
.custom-checkbox-list .v-list-item {
    margin-bottom: 8px; /* oder den gewünschten Abstand */
}

.custom-dialog{
    top: 64px; /* height of the top navigation bar */
    left: 255px; /* width of the left navigation bar */
    width: calc(100% - 255px); /* adjust the width of the dialog */
    height: calc(100% - 64px); /* adjust the height of the dialog */
}




</style>