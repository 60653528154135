import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_AuthorityAddPersonFile = _resolveComponent("AuthorityAddPersonFile")!
  const _component_AuthorityEditPersonFile = _resolveComponent("AuthorityEditPersonFile")!
  const _component_AuthorityViewPersonFile = _resolveComponent("AuthorityViewPersonFile")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (!_ctx.addPersonDialog && !_ctx.editPersonDialog && !_ctx.selectedPerson && _ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _ctx.openAddPersonDialog,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Neue Person")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.addPersonDialog && !_ctx.editPersonDialog && !_ctx.selectedPerson)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            headers: _ctx.personHeaders,
            items: _ctx.filteredPersons,
            class: "elevation-1 mt-12",
            search: _ctx.search,
            "items-per-page": 10
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Personenverwaltung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            [`item.name`]: _withCtx(({ item }) => [
              _createElementVNode("span", {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openViewPersonDialog(item)),
                style: {"cursor":"pointer"}
              }, _toDisplayString(item.name), 9, _hoisted_1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              (_ctx.canEdit)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openEditPersonDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-pencil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (_ctx.canDelete)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDeletePersonDialog(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["headers", "items", "search"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AuthorityAddPersonFile, {
        modelValue: _ctx.addPersonDialog,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addPersonDialog) = $event)),
        addPersonDialog: _ctx.addPersonDialog,
        onPersonAdded: _ctx.onPersonAdded
      }, null, 8, ["modelValue", "addPersonDialog", "onPersonAdded"]),
      _createVNode(_component_AuthorityEditPersonFile, {
        modelValue: _ctx.editPersonDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editPersonDialog) = $event)),
        editPersonDialog: _ctx.editPersonDialog,
        personToEdit: _ctx.editedPerson,
        onPersonUpdated: _ctx.onPersonUpdated,
        onClose: _ctx.closeEditFile
      }, null, 8, ["modelValue", "editPersonDialog", "personToEdit", "onPersonUpdated", "onClose"]),
      _createVNode(_component_AuthorityViewPersonFile, {
        modelValue: _ctx.viewPersonDialog,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.viewPersonDialog) = $event)),
        personToView: _ctx.selectedPerson,
        onClose: _ctx.closeViewFile
      }, null, 8, ["modelValue", "personToView", "onClose"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deletePersonDialog,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.deletePersonDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Person löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie die Person \"" + _toDisplayString(_ctx.selectedPerson?.firstname) + " " + _toDisplayString(_ctx.selectedPerson?.lastname) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deletePersonDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deletePerson(_ctx.selectedPerson)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}