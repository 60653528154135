<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <v-btn @click="openAddTemplateDialog" v-if="canEdit">Template hinzufügen</v-btn>

                <v-btn class="ml-5" @click="openAddCategorieDialog" v-if="canEdit">Kategorie hinzufügen</v-btn>

                <v-btn class="ml-5" @click="showSortingModal = true" v-if="canEdit">Kategorien sortieren</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    label="Suche"
                    v-model="searchInput"
                    class="search-input"
                    single-line
                    hide-details
                    clearable
                ></v-text-field>
            </v-col>
        </v-row>
  
        <template v-for="(category) in filteredCategories" :key="category.id">
            <v-row>
            <v-col cols="12" sm="6" md="4" lg="3">
                <template v-if="editingField.type !== 'categoryName' || editingField.id !== (category ? category.id : -1)">
                    <h2 @dblclick="startEditingField('categoryName', category ? category.id : -1)">
                        {{ category ? category.name : '' }}
                    </h2>
                </template>
                <template v-else>
                    <v-text-field
                        v-model="editedValue"
                        single-line
                        @keydown.enter="updateField(category ? category.id : -1)"
                        @keydown.esc="cancelEditingField"
                        @blur="cancelEditingField"
                        ref="editField"
                        class="mt-5 mr-5"
                    ></v-text-field>
                </template>
            </v-col>
            </v-row>
        
            <v-row>
                <draggable v-model="category.templates" :group="{ name: 'templates', pull: 'clone', put: false }" tag="transition-group" :animation="150" @end="saveTemplateSorting(category.templates)">
                    <template v-slot:item="{ element }">
                        <v-col :key="element.id" cols="12" sm="6" md="4" lg="3">
                            <v-card @click="openDialog(element)">
                                <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
                                    <div style="display: flex; justify-content: flex-end;">
                                        <v-btn class="mr-2 mt-2" density="comfortable" icon="mdi-pencil" @click.stop="openEditDialog(element)" v-if="canEdit"></v-btn>
                                    </div>
                                    <v-card-text class="d-flex justify-center align-center mt-n4">
                                        <v-icon size="60">{{ (element.icon).toLowerCase() }}</v-icon>
                                    </v-card-text>
                                    <v-card-title class="d-flex justify-center align-center">{{ element.name }}</v-card-title>
                                    <v-card-text class="d-flex justify-center align-center">{{ element.description }}</v-card-text>
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </draggable>
            </v-row>
            <v-divider class="border-opacity-50 mt-12 mb-12"></v-divider>
        </template>
  
        <v-dialog v-model="dialog" width="1000">
            <v-card>
                <v-card-title>{{ selectedTemplate?.name }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <template v-for="(field, index) in templateFields" :key="index">
                                    <v-text-field v-if="field.field_type === 'text'"
                                        :label="field.field_label"
                                        :hint="field.field_description"
                                        :required="field.is_required"
                                        v-model="fieldValues[field.field_name]"
                                    ></v-text-field>
                                    <v-textarea v-else-if="field.field_type === 'textarea'"
                                        :label="field.field_label"
                                        :hint="field.field_description"
                                        :required="field.is_required"
                                        v-model="fieldValues[field.field_name]"
                                        auto-grow
                                    >
                                    </v-textarea>
                                    <v-text-field v-else-if="field.field_type === 'date'"
                                        @input="(event) => updateDateFieldValue(field.field_name, event.target.value)"
                                        label="Startdatum"
                                        type="date"
                                        return-mask="dd.MM.yyyy"
                                    ></v-text-field>



                                    <v-select 
                                        v-else-if="field.field_type == 'select' && field.field_name.includes('salutation:')"
                                        :items="['Sehr geehrter Herr', 'Sehr geehrte Frau', 'Sehr geehrter Mr.', 'Sehr geehrte Ms.']"
                                        v-model="fieldValues[field.field_name]"
                                        >
                                    </v-select>
                                    <v-select 
                                        v-else-if="field.field_type == 'select' && field.field_name.includes('gender:')"
                                        :items="['Herr', 'Frau', ,'Mr.', 'Mrs.', 'Hubschrauber', 'Lokomotive']"
                                        v-model="fieldValues[field.field_name]"
                                        >
                                    </v-select>
                                    <v-text-field v-else
                                        :label="field.field_label"
                                        :hint="field.field_description"
                                        :required="field.is_required"
                                        v-model="fieldValues[field.field_name]"
                                    ></v-text-field>
                                </template>

                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field 
                                    label="Empfänger"
                                    hint="Empfänger"
                                    v-model="selectedTemplate.recipient"
                                ></v-text-field>
                                <v-text-field 
                                    label="Betreff"
                                    hint="Betreff"
                                    v-model="selectedTemplate.subject"
                                ></v-text-field>
                                <v-textarea
                                    :value="renderTemplate()"
                                    :rows="calculateRows()"
                                    no-resize
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text="true" @click="dialog = false">Schließen</v-btn>
                    <v-btn text="true" @click="copyToClipboard">Copy to Clipboard</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showSortingModal" max-width="600px">
            <v-card>
                <v-card-title>
                Kategorien sortieren
                </v-card-title>
                <v-card-text>
                    <draggable v-model="categories" :list="categories" tag="transition-group" :animation="150">
                        <template v-slot:item="{ element }">
                        <v-list-item :key="element.id" class="list-item mb-2 d-flex align-center" style="cursor: move;">
                            <v-avatar size="24" class="me-2">
                            <v-icon>mdi-drag-variant</v-icon>
                            </v-avatar>
                            <span>{{ element.name }}</span>
                        </v-list-item>
                        </template>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="showSortingModal = false">Abbrechen</v-btn>
                    <v-btn @click="saveCategorySorting">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addTemplateDialog" width="1000">
            <v-card>
            <v-card-title>Neue Vorlage hinzufügen</v-card-title>
            <v-card-text>
                <v-form ref="addTemplateForm">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Kategorie"
                            item-value="id"
                            item-title="name"
                            :items="categories"
                            v-model="newTemplate.category_id"
                            required
                        >

                        </v-select>
                        
                        <v-text-field label="Name" required v-model="newTemplate.name"></v-text-field>
                        <v-text-field label="Beschreibung" required v-model="newTemplate.description"></v-text-field>
                        <v-text-field
                            label="Icon"
                            required
                            readonly
                            v-model="selectedIcon.name"
                            @click="iconPickerDialog = true"
                        ></v-text-field>
                        <v-dialog v-model="iconPickerDialog" max-width="600">
                            <v-card>
                            <v-card-title>Icon Picker</v-card-title>
                            <v-card-text>
                                <v-row>
                                <v-col
                                    v-for="(iconItem, index) in icons"
                                    :key="index"
                                    cols="2"
                                    @click="selectIcon(iconItem)"
                                >
                                    <v-icon :mdi="iconItem.path">{{ iconItem.name }}</v-icon>
                                </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text="true" @click="iconPickerDialog = false"
                                >Schließen</v-btn
                                >
                            </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-text-field label="Empfänger" required v-model="newTemplate.recipient"></v-text-field>
                        <v-text-field label="Betreff" required v-model="newTemplate.subject"></v-text-field>
                        <v-textarea label="Vorlagentext" required v-model="newTemplate.text" class="mt-4"></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                    <!-- Rechts: Tabelle mit Namen und Beschreibung -->
                        <v-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Beschreibung</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(field) in allFields" :key="field.id">
                                    <td>{{ field.field_name }}</td>
                                    <td>{{ field.field_description }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-table>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="true" @click="addTemplateDialog = false">Abbrechen</v-btn>
                <v-btn text="true" @click="addNewTemplate">Hinzufügen</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addCategorieDialog" width="500">
            <v-card>
            <v-card-title>Neue Kategorie hinzufügen</v-card-title>
            <v-card-text>
                <v-form ref="addCategorieForm">
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field label="Name" required v-model="newCategorie.name"></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="true" @click="addCategorieDialog = false">Abbrechen</v-btn>
                <v-btn text="true" @click="addNewCategorie">Hinzufügen</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editTemplateDialog" width="1000">
            <v-card>
                <v-card-title>Template bearbeiten</v-card-title>
                <v-card-text>
                    <v-form ref="editTemplateForm">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                    label="Kategorie"
                                    item-value="id"
                                    item-title="name"
                                    :items="categories"
                                    v-model="editedTemplate.category_id"
                                    required
                                >

                                </v-select>
                                
                                <v-text-field label="Name" required v-model="editedTemplate.name"></v-text-field>
                                <v-text-field label="Beschreibung" required v-model="editedTemplate.description"></v-text-field>
                                <v-text-field
                                    label="Icon"
                                    required
                                    readonly
                                    v-model="selectedIcon.name"
                                    @click="iconPickerDialog = true"
                                ></v-text-field>
                                <v-dialog v-model="iconPickerDialog" max-width="600">
                                    <v-card>
                                    <v-card-title>Icon Picker</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                        <v-col
                                            v-for="(iconItem, index) in icons"
                                            :key="index"
                                            cols="2"
                                            @click="selectIcon(iconItem)"
                                        >
                                            <v-icon :mdi="iconItem.path">{{ iconItem.name }}</v-icon>
                                        </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text="true" @click="iconPickerDialog = false"
                                        >Schließen</v-btn
                                        >
                                    </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-text-field label="Empfänger" v-model="editedTemplate.recipient"></v-text-field>
                                <v-text-field label="Betreff" v-model="editedTemplate.subject"></v-text-field>
                                <v-textarea label="Vorlagentext" required v-model="editedTemplate.text" class="mt-4"></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                            <!-- Rechts: Tabelle mit Namen und Beschreibung -->
                                <v-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Beschreibung</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(field) in allFields" :key="field.id">
                                            <td>{{ field.field_name }}</td>
                                            <td>{{ field.field_description }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="true" @click="editTemplateDialog = false">Abbrechen</v-btn>
                <v-btn text="true" @click="editTemplate">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, Ref, watchEffect, computed } from "vue";
  import api from "@/api";
  import { Category, Template, TemplateField, Field, ExtractedField, FieldValue } from "@/types/Templates";
  import { useStore } from 'vuex';
  import draggable from 'vuedraggable'
  import { useRoute } from "vue-router";
  
  export default defineComponent({
    components: {
        draggable,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const categories = ref<Category[]>([]);
        const dialog = ref(false);
        const selectedTemplate = ref<Template | null>(null);
        const store = useStore();
        const fieldValues: Ref<{ [key: string]: FieldValue }> = ref({});
        const templateFields = ref<ExtractedField[]>([]);
        const selectedTemplateFields = ref<ExtractedField[]>([]);
        const searchInput = ref('');
        const showSortingModal = ref(false);
        const editedValue = ref('');
        const editingField = ref({ type: '', id: -1 });
        

        watchEffect(() => {
            const newFieldValues: Record<string, string | string[]> = {};

            templateFields.value.forEach((field) => {
                if(field.field_value != "") newFieldValues[field.field_name] = field.field_value;
            });
            console.log(newFieldValues)

            fieldValues.value = newFieldValues;
        });

        
        const route = useRoute();

        const canEdit = computed(() => route.meta.canEdit);
        const canDelete = computed(() => route.meta.canDelete);

        const editTemplateDialog = ref(false);
        const editedTemplate: Ref<any> = ref({
            id: null,
            category_id: null,
            name: "",
            description: "",
            icon: "",
            text: "",
            recipient: "",
            subject: "",
        });

        const openEditDialog = (template: Template) => {
            editedTemplate.value = { ...template };
            selectedIcon.value.name = editedTemplate.value.icon;
            editTemplateDialog.value = true;
        };

        const editTemplate = async () => {
            // Validierung (optional, je nach Bedarf)
            if (
                editedTemplate.value.name.trim() === '' ||
                editedTemplate.value.description.trim() === '' ||
                editedTemplate.value.text.trim() === ''
            ) {
                console.error('Alle Felder müssen ausgefüllt sein');
                return;
            }

            try {
                // API-Anfrage zum Aktualisieren des Templates
                await api.post('templates/?action=updateTemplate', {
                    id: editedTemplate.value.id,
                    category_id: editedTemplate.value.category_id,
                    name: editedTemplate.value.name,
                    description: editedTemplate.value.description,
                    icon: selectedIcon.value.name,
                    text: editedTemplate.value.text,
                    recipient: editedTemplate.value.recipient,
                    subject: editedTemplate.value.subject,
                });

                // Aktualisieren der lokalen Liste der Templates
                const categoryIndex = categories.value.findIndex(
                    (cat) => cat.id === editedTemplate.value.category_id
                );
                if (categoryIndex !== -1) {
                    const templateIndex = categories.value[categoryIndex].templates.findIndex(
                        (tmpl) => tmpl.id === editedTemplate.value.id
                    );
                    if (templateIndex !== -1) {
                        categories.value[categoryIndex].templates.splice(templateIndex, 1, editedTemplate.value);
                    }
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }

            // Schließen des Dialogs
            editTemplateDialog.value = false;
            fetchCategories();
        };



        const addTemplateDialog = ref(false);
        const addCategorieDialog = ref(false);
        const newTemplate = ref({
            category_id: 1,
            name: "",
            text: "{{header}}\nSehr geehrter Herr {{employee}}\n....\n\n{{footer}}",
            description: "",
            icon: "",
            recipient: "",
            subject: "",
        });

        const newCategorie = ref({
            name: "",
        });

        const filteredCategories = computed<Category[]>(() => {
            if (!searchInput.value.trim()) {
                return categories.value;
            }

            return categories.value
                .map((category) => ({
                ...category,
                templates: category.templates.filter((template) => {
                    const searchTerm = searchInput.value.toLowerCase();
                    const nameMatch = template.name.toLowerCase().includes(searchTerm);
                    const descriptionMatch = template.description
                    ? template.description.toLowerCase().includes(searchTerm)
                    : false;

                    return nameMatch || descriptionMatch;
                }),
            }))
            .filter((category) => category.templates.length > 0);
        });


        const onCategoryMoved = () => {
            for (let i = 0; i < categories.value.length; i++) {
                categories.value[i].sort_order = i + 1;
            }
        };

        const saveCategorySorting = async () => {
            try {
                console.log("Sending category data:", categories.value);
                const response = await api.post("templates/?action=saveCategorySorting", JSON.stringify(categories.value));

                if (response.data.success) {
                console.log("Category sorting saved successfully");
                } else {
                console.error("Failed to save category sorting:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }

            showSortingModal.value = false;
        };

        const saveTemplateSorting = async (items) => {
            try {
                const sortedItems = items.map((item, index) => ({ id: item.id, sort_order: index }));
                const response = await api.post("templates/?action=saveTemplateSorting", JSON.stringify(sortedItems));
                if (response.data.success) {
                    errorSnackbar.value.message = 'Sortierung gespeichert.';
                    errorSnackbar.value.visible  = true;	
                    errorSnackbar.value.color = "green";
                } else {
                    console.error("Failed to save template sorting:", response.data.error);
                }
            } catch (error) {
                if (error.response) {
                    errorSnackbar.value.message = error.response.data.error;
                } else {
                    errorSnackbar.value.message = "An unknown error occurred";
                }
                errorSnackbar.value.visible  = true;	
            }
        };




        function getFieldValue(uniqueFieldName: string, field_value: any): string {
            // Split the uniqueFieldName into the original fieldName and index
            const [fieldName, index] = uniqueFieldName.split(':');

            if (fieldName === 'header') {
                return `[img]${store.state.user.mail_header}[/img]`;
            } else if (fieldName === 'footer') {
                return `[img]${store.state.user.mail_footer}[/img]`;
            } else if (fieldName === 'header_neutral') {
                return `[img]${store.state.user.mail_header_neutral}[/img]`;
            } else if (fieldName === 'footer_neutral') {
                return `[img]${store.state.user.mail_footer_neutral}[/img]`;
            } else if (fieldName === 'signature') {
                return `[img]${store.state.user.signature}[/img]`;
            }else if (field_value.length > 0){
                return field_value;
            }
            return '';
        }



        function extractTemplateFields(templateText: string): ExtractedField[] {
            const variablePattern = /\{\{ *(\w+)((?: *, *\w+)*) *\}\}/g;
            const fields: ExtractedField[] = [];
            const fieldCounts: { [fieldName: string]: number } = {};

            let match;
            while ((match = variablePattern.exec(templateText)) !== null) {
                const fieldName = match[1];
                const properties = match[2].split(',').map(s => s.trim()).filter(s => s !== '');

                // Count the occurrences of the fieldName
                fieldCounts[fieldName] = (fieldCounts[fieldName] || 0) + 1;

                // Always generate a unique field name based on the count
                const uniqueFieldName = `${fieldName}:${fieldCounts[fieldName]}`;

                // Create a new field object
                const field = {
                    field_name: uniqueFieldName,
                    is_multiple: false,
                    is_required: false,
                    field_label: "",
                    field_type: "text", // Set default field_type to "text" (it will be updated from allFields later)
                    field_description: "",
                    field_value: "",
                };
                field.field_value = getFieldValue(uniqueFieldName, '');
                fields.push(field);

                // Update field properties based on the matched properties
                for (const property of properties) {
                    if (property === "multiple") {
                        field.is_multiple = true;
                    } else if (property === "required") {
                        field.is_required = true;
                    }
                }
            }

            // Update field_type from allFields
            for (const field of fields) {
                const originalFieldName = field.field_name.split(':')[0];
                const matchingField = allFields.value.find(f => f.field_name === originalFieldName);
                if (matchingField && matchingField.field_type) {
                    field.field_type = matchingField.field_type;
                }
                if(matchingField && matchingField.field_value){
                    field.field_value = matchingField.field_value;
                }
            }

            return fields;
        }





        function openDialog(template: Template) {
            selectedTemplate.value = template;

            const extractedFields = extractTemplateFields(template.text); // Pass the template ID
            const dbFields = allFields.value.filter(
                (field: Field) => field.template_id === template.id || field.template_id === null
            );
            templateFields.value = mergeExtractedAndDbFields(extractedFields, dbFields);
            
            fieldValues.value = {};
            for (const field of templateFields.value) {
                fieldValues.value[field.field_name] = field.field_value;
            }
            
            dialog.value = true;
        }




        function mergeExtractedAndDbFields(extractedFields: ExtractedField[], dbFields: Field[]): Field[] {
            const mergedFields: Field[] = [];
            for (const extractedField of extractedFields) {
                const dbField = dbFields.find((f) => f.field_name === extractedField.field_name);
                if (dbField) {
                mergedFields.push({
                    ...dbField,
                    is_multiple: extractedField.is_multiple,
                    is_required: extractedField.is_required,
                    field_type: extractedField.field_type,
                    field_value: getFieldValue(extractedField.field_name, extractedField.field_value), // Update field_value based on the unique field name
                });
                } else {
                mergedFields.push({
                    id: 0,
                    template_id: null,
                    field_name: extractedField.field_name,
                    field_label: extractedField.field_name, // Set field_label to field_name as a fallback
                    is_multiple: extractedField.is_multiple,
                    is_required: extractedField.is_required,
                    field_type: extractedField.field_type,
                    field_description: extractedField.field_description,
                    field_value: getFieldValue(extractedField.field_name, extractedField.field_value), // Update field_value based on the unique field name
                });
                }
            }
            return mergedFields;
        }




        async function addNewTemplate() {
            try {
                await api.post("templates/?action=addTemplate", newTemplate.value);
                fetchCategories(); // Refresh categories after adding the new template
                addTemplateDialog.value = false;
                resetFields();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function addNewCategorie() {
            try {
                await api.post("templates/?action=addCategorie", newCategorie.value);
                fetchCategories(); // Refresh categories after adding the new template
                addCategorieDialog.value = false;
                newCategorie.value.name = "";
                resetFields();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        function openAddTemplateDialog() {
            addTemplateDialog.value = true;
        }

        function openAddCategorieDialog() {
            addCategorieDialog.value = true;
        }

        async function fetchCategories() {
            try {
                const response = await api.get("templates/?action=getTemplates");
                categories.value = response.data;
                } catch (error){
                console.error("Error fetching categories:", error);
            }
        }

        const allFields = ref<Field[]>([]);
        async function fetchFields() {
            try {
                const response = await api.get("templates/?action=getFields");
                allFields.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        function submitForm() {
            // Process form data here, e.g., send it to the API
            dialog.value = false;
        }

        function getStringArray(value: FieldValue): string[] {
            if (Array.isArray(value)) {
                return value;
            }
            return [value];
        }



        function addFieldValue(fieldName: string) {
            if (!Array.isArray(fieldValues.value[fieldName])) {
                const fieldValueArray = getStringArray(fieldValues.value[fieldName]);
                fieldValueArray.push("");
                fieldValues.value[fieldName] = fieldValueArray;
            }
            (fieldValues.value[fieldName] as string[]).push("");
        }



        function renderTemplate() {
            if (!selectedTemplate.value) {
                return "";
            }

            let renderedTemplate = selectedTemplate.value.text;
            const fieldCounts = {};

            // Find all instances of fields in the template
            const variablePattern = /\{\{ *(.*?) *\}\}/g;
            const matches = renderedTemplate.match(variablePattern) || [];

            // Loop through each field and replace duplicates with numbered fields
            for (let i = 0; i < matches.length; i++) {
                const match = matches[i];
                const fieldName = match.substring(2, match.length - 2).trim();

                if (Object.prototype.hasOwnProperty.call(fieldCounts, fieldName)) {
                    // Increment field count
                    fieldCounts[fieldName]++;
                } else {
                    // Initialize field count
                    fieldCounts[fieldName] = 1;
                }

                // Replace the field with the numbered field
                const numberedFieldName = `${fieldName}:${fieldCounts[fieldName]}`;
                renderedTemplate = renderedTemplate.replace(new RegExp(`\\{\\{ *${fieldName} *\\}\\}`), `{{${numberedFieldName}}}`);

            }

            // Replace fields with their corresponding values
            for (const uniqueFieldName in fieldValues.value) {
                const fieldValue = fieldValues.value[uniqueFieldName];
                const renderedFieldValue = Array.isArray(fieldValue)
                    ? fieldValue.join(", ")
                    : fieldValue;

                // Replace all instances of the field, including numbered ones
                const variablePattern = new RegExp(`\\{\\{ *${uniqueFieldName} *\\}\\}`, "g");
                renderedTemplate = renderedTemplate.replace(variablePattern, renderedFieldValue);
            }

            return renderedTemplate;
        }

        function updateFieldValue(fieldName, value) {
            const originalField = templateFields.value.find(field => field.field_name === fieldName);
            if (originalField && originalField.field_type === 'date') {
                fieldValues.value[fieldName] = formatDate(value);
            } else {
                fieldValues.value[fieldName] = value;
            }
        }

        function updateDateFieldValue(fieldName, isoDateString) {
            fieldValues.value[fieldName] = formatDate(isoDateString);
        }


        function formatDate(isoDateString) {
            const date = new Date(isoDateString);
            console.log(date);
            if (isNaN(date.getTime())) {
                return isoDateString;
            }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }






        async function copyToClipboard() {
            try {
                const renderedText = renderTemplate(); // Stelle sicher, dass du die renderTemplate-Funktion entsprechend deiner Komponente anpasst
                await navigator.clipboard.writeText(renderedText);
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }


        //Icons für den Dropdown:
        const icons = [
            { name: 'mdi-home', path: 'mdi-Home' },
            { name: 'mdi-account', path: 'mdi-Account' },
            { name: 'mdi-email', path: 'mdi-Email' },
            { name: 'mdi-phone', path: 'mdi-Phone' },
            { name: 'mdi-map-marker', path: 'mdi-MapMarker' },
            { name: 'mdi-calendar', path: 'mdi-Calendar' },
            { name: 'mdi-lock', path: 'mdi-Lock' },
            { name: 'mdi-magnify', path: 'mdi-Magnify' },
            { name: 'mdi-plus', path: 'mdi-Plus' },
            { name: 'mdi-minus', path: 'mdi-Minus' },
            { name: 'mdi-check', path: 'mdi-Check' },
            { name: 'mdi-close', path: 'mdi-Close' },
            { name: 'mdi-cog', path: 'mdi-cog' },
            { name: 'mdi-information', path: 'mdi-Information' },
            { name: 'mdi-alert', path: 'mdi-Alert' },
            { name: 'mdi-help', path: 'mdi-Help' },
            { name: 'mdi-delete', path: 'mdi-Delete' },
            { name: 'mdi-pencil', path: 'mdi-Pencil' },
            { name: 'mdi-eye', path: 'mdi-Eye' },
            { name: 'mdi-eye-off', path: 'mdi-EyeOff' },
            { name: 'mdi-folder', path: 'mdi-Folder' },
            { name: 'mdi-file', path: 'mdi-File' },
            { name: 'mdi-camera', path: 'mdi-Camera' },
            { name: 'mdi-image', path: 'mdi-Image' },
            { name: 'mdi-play', path: 'mdi-Play' },
            { name: 'mdi-pause', path: 'mdi-Pause' },
            { name: 'mdi-skip-next', path: 'mdi-SkipNext' },
            { name: 'mdi-skip-previous', path: 'mdi-SkipPrevious' },
            { name: 'mdi-volume-high', path: 'mdi-VolumeHigh' },
            { name: 'mdi-volume-medium', path: 'mdi-VolumeMedium' },
            { name: 'mdi-volume-low', path: 'mdi-VolumeLow' },
            { name: 'mdi-volume-off', path: 'mdi-VolumeOff' },
            { name: 'mdi-download', path: 'mdi-Download' },
            { name: 'mdi-upload', path: 'mdi-Upload' },
            { name: 'mdi-account-cancel', path: 'mdi-account-cancel' },
            { name: 'mdi-account-check', path: 'mdi-account-check' },
            { name: 'mdi-account-minus', path: 'mdi-account-minus' },
            { name: 'mdi-account-plus', path: 'mdi-account-plus' },
            { name: 'mdi-account-cash', path: 'mdi-account-cash' },
            { name: 'mdi-alert-outline', path: 'mdi-alert-outline' },
            { name: 'mdi-shopping', path: 'mdi-shopping' },
            { name: 'mdi-receipt', path: 'mdi-receipt' },
            { name: 'mdi-file-sign', path: 'mdi-file-sign' },
            { name: 'mdi-clock-alert-outline', path: 'mdi-clock-alert-outline' },
            { name: 'mdi-account-tie', path: 'mdi-account-tie-woman' },
        ];


        const selectedIcon = ref({ name: '', path: '' });
        const iconPickerDialog = ref(false);

        const selectIcon = (iconItem:any) => {
            console.log(iconItem.path)
            selectedIcon.value = iconItem;
            newTemplate.value.icon = iconItem.path;
            iconPickerDialog.value = false;
        };

        const resetFields = () => {
            newTemplate.value = {
                category_id: 1,
                name: '',
                description: '',
                text: '',
                icon: '',
                recipient: '',
                subject: '',
            };
            selectedIcon.value = {
                name: '',
                path: '',
            };
        };





        const startEditingField = (fieldType: string, fieldId: number) => {
            editingField.value = { type: fieldType, id: fieldId };
            if (fieldType === 'categoryName' && categories.value) {
                const category = categories.value.find(cat => cat.id === fieldId);
                if (category) {
                    editedValue.value = category.name;
                }
            }
            // Füge hier weitere Bedingungen für andere Feldtypen hinzu
        };

        const cancelEditingField = () => {
            editingField.value = { type: '', id: -1 };
        };

        async function updateField(fieldId: number) {
            if (editedValue.value.trim() === '') return;

            if (editingField.value.type === 'categoryName' && categories.value) {
                try {
                    const category = categories.value.find(cat => cat.id === fieldId);
                    if (!category) {
                        return;
                    }

                    await api.post('templates/?action=updateCategoryName', {
                        id: fieldId,
                        name: editedValue.value,
                    });

                    category.name = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }

            // Füge hier weitere Bedingungen für andere Feldtypen hinzu

            cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
        }



        onMounted(() => {
            fetchCategories();
            fetchFields();
            const state = store.state;
        });

        function calculateRows() {
            const minRows = 10;
            const maxRows = 30;
            const text = renderTemplate();
            const numberOfLineBreaks = (text.match(/\n/g) || []).length;

            return Math.min(minRows + numberOfLineBreaks, maxRows);
        }




        return {
            categories,
            calculateRows,
            dialog,
            selectedTemplate,
            fieldValues,
            openDialog,
            submitForm,
            addFieldValue,
            renderTemplate,
            addTemplateDialog,
            newTemplate,
            openAddTemplateDialog,
            addNewTemplate,
            templateFields,
            copyToClipboard,
            allFields,
            icons,
            selectedIcon,
            iconPickerDialog,
            selectIcon,
            resetFields,
            filteredCategories,
            searchInput,
            openAddCategorieDialog, 
            addCategorieDialog,
            newCategorie,
            addNewCategorie,
            showSortingModal,
            onCategoryMoved,
            saveCategorySorting,
            editingField,
            editedValue,
            startEditingField,
            updateField,
            cancelEditingField,
            editTemplateDialog,
            editedTemplate,
            openEditDialog,
            editTemplate,
            errorSnackbar,
            updateFieldValue,
            updateDateFieldValue,
            saveTemplateSorting,
            canEdit,
            canDelete
        };
    },
});
</script>
